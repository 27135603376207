$(document).ready(function(){
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect	: 'none',
		nextEffect	: 'none',
		helpers	: {
			title	: {
				type: 'outside'
			},
			thumbs	: {
				width	: 50,
				height	: 50
			},
			overlay : {
			   locked     : false
			}
		}
	});
});