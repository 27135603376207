
$('body.module-JournalController').on('focusout', '.my-review input[name=name]', function(){
	var index = $(this).closest('.my-review').data('index');
	if ($(this).val().length == 0)
		$('#name-block-error-'+index).show();
	else
		$('#name-block-error-'+index).hide();
});

$('body.module-JournalController').on('focusout', '.my-review textarea[name=comments]', function(){
	var index = $(this).closest('.my-review').data('index');
	if ($(this).val().length == 0)
		$('#comment-block-error-'+index).show();
	else
		$('#comment-block-error-'+index).hide();
});

$('body.module-JournalController').on('click', '#add-review-1, #add-review-2', function(){

	var isError = false;
	var index = $(this).data('index');
	var review = $(this).closest('.my-review');

	if (review.find('input[name=name]').val().length == 0){
		$('#name-block-error-'+index).show();
		isError = true;
	}

	if (review.find('textarea[name=comments]').val().length == 0){
		$('#comment-block-error-'+index).show();
		isError = true;
	}

	if (isError)
		return false;

	$('#name-block-error-'+index).hide();
	$('#comment-block-error-'+index).hide();

	$.ajax({
		url: mc_journal_module_url,
		type: 'POST',
		data: {
			name: review.find('input[name=name]').val(),
			comments: review.find('textarea[name=comments]').val(),
			journal_record_id: mc_journal_record_id,
			temp_id: review.find('input[name=temp_id]').val()
		},
		dataType: 'json',
		success: function(data){
			$.get(mc_journal_reviews_module_url, function(data){
				$('#reviews-list').html(data);
				$('#add-review-result-'+index).show();
			});
		}
	});
	return false;
});

/*
	$('#reviews-list').on('click', '.rate-sort-item a', function(){
		var url = $(this).attr('href');
		var sort_method = $(this).closest('div').data('sort');
		var mainUrl = mc_product_reviews_sort_module_url + sort_method;

		if (typeof history.pushState != undefined) {
			history.pushState({ mode: 'product', url: mainUrl},null,encodeURI(decodeURI(mainUrl)));
		}

		$.get(url, function(data){
			$('#reviews-list').html(data);
		});
		return false;
	});
*/