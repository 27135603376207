$(document).ready(function(){
	
	responsive_init();

    fix_touch_hovers();
    click_touch_init();
    toggle_element_init();
    expand_it_init();
    tabs_init();
    goto_init();
    //sticky_sidebar_init();

    window.Blazy = new Blazy({
        success: function(ele){
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
        }
        , error: function(ele, msg){
            if(msg === 'missing'){
                console.log(ele, msg);
            }
            else if(msg === 'invalid'){
                console.log(ele, msg);
            }
        }
    });

    map_init();
	misc_init();

    $(document).on("click", ".js-filter-toggle", function (e) {
        e.preventDefault();
        $(".filter").toggleClass("active");
        if ($(this).attr("data-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-label"));
            $(this).attr("data-label", label);
        }
    });

    $(document).on("click", ".js-omenu-overlay-open", function (e) {
        e.preventDefault();
        var $o = $($(this).attr("href"));
        $o.addClass("active");
    });

    $(document).on("click", ".js-omenu-overlay-close", function (e) {
        e.preventDefault();
        $(this).closest(".omenu-overlay").removeClass("active");
    });

    $(document).on("click", ".js-msearch-toggle", function (e) {
        e.preventDefault();
        var $o = $($(this).attr("href"));
        $o.toggleClass("active");
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".msearch, .js-msearch-toggle").length) {
            $(".msearch").removeClass("active");
        }
    });

    $(document).on("click", ".mmenu__grid-item.touch-focused > a", function (e) {
        if (responsive_type() <= 767) {
            location.href = $(this).attr("href");
        }
    });

    $(document).on("click", ".js-dfillings-filter", function (e) {
        e.preventDefault();
        var type = $(this).data("type");
        $(this).closest("li").addClass("active").siblings().removeClass("active");
        if (type) {
            $(".dfilling").addClass("hidden");
            $(".dfilling").filter('[data-type-'+type+']').removeClass("hidden");
        }
        else {
            $(".dfilling").removeClass("hidden");
        }
        lazy_load();
    });

    $(document).on("click", ".js-bookmark", function (e) {
        e.preventDefault();
        bookmark(this);
    });
	
	$(document).on("click", ".js-show-all-records", function(e) {
		e.preventDefault();
		$(this).closest(".jarticles").find(".jarticle").show();
		$(this).closest("center").remove();
	});


    $("[itemprop]").each(function( ) {
        $(this).removeAttr("itemprop");
    });

});

$(window).on("load", function(){

	responsive_update();
	
	bind_widgets();
	$(document).ajaxStop(function() {
		bind_widgets();
	});
	
});

$(window).on("resize orientationchange", function(e){
	responsive_update();
    lazy_load();
});

$(window).on("scroll", function(e){
    $("body").toggleClass("scrolled", $(window).scrollTop() > 0);
    $("body").toggleClass("scrolled-screen", $(window).scrollTop() > $(window).height());
    lazy_load();
    header_scroll();
});

function bind_widgets()
{
    tel_replace_init();
    mask_init();
    lazy_load();
	img_to_bg();
    slider_init();
    zoom_init();
    fancybox_init();
    map_load();
    tooltip_init($(".js-tooltip"));
    date_picker();
    time_picker();
    selectbox_init();
    scroll_state_init();
    responsive_update();
    sticky_init();
}


var addCSSRule = function (sheet_id, rules) {
  $("#"+sheet_id).remove();
  $("<style type='text/css' id='"+sheet_id+"'>"+rules+"</style>").appendTo("head");
};

var removeCSSRule = function (sheet_id) {
  $("#"+sheet_id).remove();
};


function responsive_type()
{
	return 1*$(".respon-meter").css("z-index");
}

function responsive_init()
{
	responsive_update();

	var $allVideos = $("iframe");
	$allVideos.each(function() {
	    if (!$(this).parent().hasClass('videoWrapper')) {
            $(this)
                .data('aspectRatio', this.height / this.width)
                .removeAttr('height')
                .removeAttr('width');
        }
	});

	$(window).on("resize orientationchange", function() {
		$allVideos.each(function() {
			var $el = $(this);
			var newWidth = $el.parent().width();
            if (!$el.parent().hasClass('videoWrapper')) {
                $el
                    .width(newWidth)
                    .height(newWidth * $el.data('aspectRatio'));
            }
		});
	}).resize();


	$(".ordertable tbody tr").each(function(i){
		$(this).find("td").each(function(j){
			$(this).prepend("<div class='ordertable-label'>"+$(this).closest(".ordertable").find("thead th").eq(j).text()+": </div>")
		});
	});

}

function responsive_update()
{

    if ($("body").data("responsive_type") != responsive_type())
    {

        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (responsive_type() > breakpoints[i]) {
                    if ($(places[breakpoints[i]]).length)  {
                        $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                    }
                    break;
                }
            }
        });

        if (responsive_type() <= 767) {
            $('.filterbox').not(".in-checked").collapse();
        }

        $(".header-wrapper-replace").height($(".header-wrapper").height());
        header_scroll();

        $("body").data("responsive_type", responsive_type());
    }
}


function misc_init() {

    $(document).on('mouseenter', '.mmenu__ssubmenu-item', function(){
        $(this).addClass('hover').siblings().removeClass('hover');
    });

    /* нажатие кнопки "Показать еще" */
    $('html').on('click', 'a.show-more', function(e){
        var url = $(this).attr('href');

        $('#main-right-container ul.pagination:last').closest('div.row').remove();
        $('#main-right-container a.show-more').remove();

        if (mc_mode.length == 0 || mc_mode == 'list') {
            $('<div class="loading"><div class="loader"></div></div>').insertAfter($('#main-right-container ul.list:last'));
        } else {
            $('<div class="loading"><div class="loader"></div></div>').insertAfter($('#main-right-container div.plitka:last'));
        }

        var params = [{ 'key': 'url', 'value': url}];
        products_request_ajax(params, undefined, undefined, undefined, false, undefined, true);
        return false;
    });

    /* переход по страницам */
    $(document).on('click', 'ul.pagination a', function(e){
        e.preventDefault();
        var url = $(this).attr('href');
        var type = $(this).data('type');
        var page = $(this).data('page');
        if (url == undefined)
            return false;

        if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
        {
            var win = window.open(url, '_blank');
            return false;
        }

        switch(type){
            case 'category':
                var params = [{ 'key': 'url', 'value': url}];
                products_request_ajax(params);
                break;
            case 'material-category':
                document.location.href = url;
                //material_request_ajax(url);
                break;
            case 'brand':
                var params = [{ 'key': 'url', 'value': url}];
                brand_request_ajax(params);
                break;
            case 'collection':
                document.location.href = url;
                return false;
                break;
            case 'account_orders':
                return true;
            case 'account_reviews':
                return true;
            case 'reviews':

                $('html, body').animate({
                    scrollTop: $("#reviews-list").offset().top
                }, 0);

                var sort_method = $('#reviews-list div.rate-sort-item.active');

                var mainUrl = mc_product_reviews_paginate_module_url + page;
                if (sort_method.length>0)
                    mainUrl += "&sort=" + sort_method.data('sort');
                if (typeof history.pushState != undefined) {
                    history.pushState({ mode: 'product', url: mainUrl},null,encodeURI(decodeURI(mainUrl)));
                }

                $.get(url, function(data){
                    $('#reviews-list').html(data);

                    $('#user-rating2-1, #user-rating2-2').rating({
                        fx: 'full',
                        image: mc_path_frontend_template + '/img/stars/big.png',
                        loader: '<i class="fa fa-spinner fa-spin"></i>',
                        minimal: 1,
                        preselectMode: true,
                        click: function(data){
                            var index = $(this).closest('.my-review').data('index');
                            $('#rating-block-error-'+index).hide();
                            $('#reviews-list input[name=rating]').val(data);
                        }
                    });
                });
                break;
        }
        return false;
    });

    /* сортировка */
    $(document).on('click', '#sort li a', function(e){
        e.preventDefault();
        var url = $(this).attr('href');
        var type = $(this).attr('data-type');
        if (url == undefined)
            return false;

        switch(type){
            case 'category':
                var params = [{ 'key': 'url', 'value': url}];
                products_request_ajax(params, null, null, null, false);
                break;
            case 'material-category':
                document.location.href = url;
                //material_request_ajax(url);
                break;
            case 'brand':
                var params = [{ 'key': 'url', 'value': url}];
                brand_request_ajax(params);
                break;
            case 'account_orders':
                return true;
        }
        $(this).addClass("current").closest("li").siblings().find(".current").removeClass("current");
        return false;
    });

    /* изменение режима просмотра */
    $('#main-right-container').on('click', '#view a', function(){
        var url = $(this).attr('href');
        var type = $(this).attr('data-type');
        if (url == undefined)
            return false;

        switch(type){
            case 'category':
                var params = [{ 'key': 'url', 'value': url}];
                products_request_ajax(params);
                break;
            case 'material-category':
                document.location.href = href;
                //material_request_ajax(url);
                break;
            case 'brand':
                var params = [{ 'key': 'url', 'value': url}];
                brand_request_ajax(params);
                break;
            case 'account_orders':
                return true;
        }
        return false;
    });

    /* Нажатие кнопки сбросить фильтр */
    $('body').on('click', '#clear-filter', function(){
        var params = [];
        params.push({
            'key': 'url',
            'value': $('#filter').attr('data-url')
        });
        $('#filter select').each(function(){
            $(this).val(0);
        });
        $('#filter input:checked').each(function(){
            $(this).prop('checked', false);
        });
        $('#filter input[data-control=rangeSlider]').each(function(){
            $(this).rangeSlider("updateSilent",{
                from: $(this).attr('data-min'),
                to: $(this).attr('data-max')
            });
        });
        products_request_ajax(params, undefined, undefined, true);
        return false;
    });



    /* Нажатие кнопки применить фильтр */
    $('body').on('click', '#apply_products_filter', function(e, scroll_up){
        e.preventDefault();
		$('#filter-float-panel').hide();
        apply_products_filter();
    });
	
	$('body').on('click', '.apply_products_filter', function(e){
		e.preventDefault();
		$('#filter-float-panel').hide();
		$("body,html").animate({
			scrollTop: $('.bcontainer').offset().top - ((responsive_type() < 1259)?$(".header").outerHeight():0)
		}, 500);
	});

    function request_filter_products_count(x, y){
        var params = [];
        params.push({
            'key': 'url',
            'value': $('#filter').attr('data-url')
        },{
            'key': 'format',
            'value': 'products_count'
        });
        $('#filter select').each(function(){
			if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
				params.push({
					'key': $(this).attr('name'),
					'value': $(this).val()
				});
		});
		$('#filter input:checked').each(function(){
			if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
				params.push({
					'key': $(this).attr('name'),
					'value': $(this).val()
				});
		});
		$('#filter input[data-control=rangeSlider]').each(function(){
			if ($(this).val().length != 0 && $(this).val() != ($(this).attr('data-min')+';'+$(this).attr('data-max')))
				params.push({
					'key': $(this).attr('name'),
					'value': $(this).val()
				});
				
		});

        $('#filter-float-panel').css({'top': y, 'left': x});

        $('#filter').toggleClass('filter--selected', params.length && true);
		
        products_request_ajax(params, undefined, undefined, true, false, false);
        return false;
    }

    $('body').on('change', '#filter input, #filter select', function(){
        /*С запросом количества товаров, удовлетворяющих фильтру*/
		/*request_filter_products_count($('#filter').offset().left + $(this).position().left + $(this).width(), $(this).offset().top);*/
        /*Моментальное применение фильтра*/
        /*$('#apply_products_filter').trigger('click', false);*/
        $("body").data("filter-last-use-name", $(this).attr("name"));
        $("body").data("filter-last-use-value", $(this).attr("value"));
        console.log($("body").data("filter-last-use-name"), $("body").data("filter-last-use-value"));
        apply_products_filter($('#filter').offset().left + $(this).position().left + $(this).width(), $(this).offset().top);
    });

    function rangeSliderStart(){
        $('input[data-control=rangeSlider]').rangeSlider({
            onChange: function(obj){        // function-callback, is called on every change
                if (!obj.input.data("disable"))
                {
                    var i1 = $('#' + obj.input.attr('data-id') + "_min");
                    var i2 = $('#' + obj.input.attr('data-id') + "_max");

                    value_array = obj.input.val().split(";");
                    i1.val(value_array[0]);
                    i2.val(value_array[1]);
                }
            },
            onFinish: function(obj){
                /*var params = [];
                params.push({
                    'key': 'url',
                    'value': $('#filter').attr('data-url')
                },{
                    'key': 'format',
                    'value': 'products_count'
                });
                $('#filter select').each(function(){
                    if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
                        params.push({
                            'key': $(this).attr('name'),
                            'value': $(this).val()
                        });
                });
                $('#filter input:checked:visible').each(function(){
                    if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
                        params.push({
                            'key': $(this).attr('name'),
                            'value': $(this).val()
                        });
                });
                $('#filter input[data-control=rangeSlider]').each(function(){
                    if ($(this).val().length != 0 && $(this).val() != $(this).attr('data-min')+';'+$(this).attr('data-max'))
                        params.push({
                            'key': $(this).attr('name'),
                            'value': $(this).val()
                        });

                });
                products_request_ajax(params, undefined, undefined, true);*/

                /*$('#apply_products_filter').trigger('click', false);*/
                apply_products_filter();
				//request_filter_products_count();
            }
        });
    }
    rangeSliderStart();

    $(document).on('click', '.js-filter-tag-remove', function(e){
        e.preventDefault();
        var mode = $(this).closest('.js-filter-tag').data('mode');
        var name = $(this).closest('.js-filter-tag').data('name');
        var tag = $(this).closest('.js-filter-tag').data('tag');

        switch(mode){
            case "range":
                var object = $('#filter input[name='+name+']');
                object.rangeSlider("updateSilent",{
                    from: object.data('min'),
                    to: object.data('max')
                });
                break;
            case "checkbox":
                var object = $('#filter input[name='+name+'][value='+tag+']');
                object.prop('checked', false);
                break;
            case "radio":
                var object = $('#filter input[name='+name+'][value='+tag+']');
                object.prop('checked', false);
                object = $('#filter input[name='+name+'][value=0]');
                object.prop('checked', true);
                break;
            case "select":
                var object = $('#filter select[name='+name+']');
                object.val(0);
                break;
            case "logical":
                var object = $('#filter input[name='+name+']');
                object.prop('checked', false);
                break;
        }

        /*Запрос кол-ва товаров*/
        /*request_filter_products_count();*/
        /*$('#apply_products_filter').trigger('click', false);*/
        apply_products_filter();

        $(this).closest('span').remove();

        return false;
    });

    $('body').on('keyup', 'input.rangeSlider' , function(){
        var slider = $('#'+$(this).attr('data-object'));
        value_array = slider.val().split(";");
        var i1_value = parseInt(value_array[0]);
        var i2_value = parseInt(value_array[1]);
        var min_value = parseInt(slider.attr('data-min'));
        var max_value = parseInt(slider.attr('data-max'));
        var from_value = parseInt($('#'+slider.attr('data-id')+'_min').val());
        var to_value = parseInt($('#'+slider.attr('data-id')+'_max').val());

        var new_value = parseInt($(this).val());

        if ($(this).attr('data-control') == "from")
        {
            if (new_value > i2_value)
                new_value = i2_value;
            if (new_value < min_value || !new_value)
                new_value = min_value;
            if (i2_value != to_value && (new_value <= to_value) && (to_value <= max_value))
                i2_value = to_value;
            slider.rangeSlider("updateSilent",{
                from: new_value,
                to: i2_value
            });
        }
        if ($(this).attr('data-control') == "to")
        {
            if (new_value < i1_value)
                new_value = i1_value;
            if (new_value > max_value || !new_value)
                new_value = max_value;
            if (i1_value != from_value && (min_value <= from_value) && (from_value <= new_value))
                i1_value = from_value;
            slider.rangeSlider("updateSilent",{
                from: i1_value,
                to: new_value
            });
        }
    });

    $('body').on('click', '#make-order', function(e){ // , #cart

        if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
        {
            var win = window.open(mc_cart_module_url, '_blank');
            return false;
        }
        if ($('#cart').find('.cart-counter').length > 0)
            cart_request_ajax();
        return false;
    });

    $('#make-compare').click(function(){
        compare_request_ajax();
        return false;
    });

    /* развернуть вертикальное меню */
    $('div.vertical-menu').on('click', 'i.fa-plus', function(){

        var li = $(this).closest('li');
        var ol = $(this).closest('div.vertical-menu');

        /* Свернем другие категории */
        /*$('ol.vertical-menu > li:not(.collapsed)').each(function()/*/
        ol.find('> li:not(.collapsed)').each(function(){
            var li_find = $(this).find('li[data-id='+li.attr('data-id')+']');
            if (li_find.length == 0)
            {
                var icon_minus = $(this).find('i.fa-minus');
                if (icon_minus.length > 0)
                    icon_minus.trigger('click');
            }
        });

        var ol = li.find('ol:first');
        var icon_col = li.find('i.fa-minus:first');
        var icon_exp = li.find('i.fa-plus:first');
        var icon_spin = li.find('i.fa-spinner:first');

        icon_exp.hide();

        ol.show();
        icon_col.show();

        li.removeClass('collapsed');
    });

    /* свернуть вертикальное меню */
    $('div.vertical-menu').on('click', 'i.fa-minus', function(){
        var li = $(this).closest('li');
        var ol = li.find('ol:first');
        var icon_col = li.find('i.fa-minus:first');
        var icon_exp = li.find('i.fa-plus:first');

        ol.hide();
        li.addClass('collapsed');
        icon_col.hide();
        icon_exp.show();
    });


    $('#searchButton').click(function(){

        var curli = $('#refreshpart div.active');
        if (curli.length > 0)
            curli.click();
        else {
            document.location.href = mc_products_module_url + '?search_keyword=' + $('#searchField').val() + '&format=search';
        }
        return false;
    });

    /* нажатие Enter в поле поиска */
    $('#searchField').on('keydown', function(e){
        if (e.keyCode == 13)
        {
            $('#categories').hide();
            $('#categories-menu-columns li.active').removeClass('active');

            $('#searchButton').trigger('click');
            return false;
        }
    });

    /* поиск keyword */
    $('#searchField').toggleClass("filled", $('#searchField').val().length > 0).on('keyup', function(e){
        var keyword = "";

        $('#searchCancelButton').toggle($(this).val().length > 0);
        $(this).toggleClass("filled", $(this).val().length > 0);

        /* down or up */
        if (e.keyCode == 40 || e.keyCode == 38)
        {
            var curli = $('#refreshpart div.active');

            if (e.keyCode == 40)
            {
                var nextli = curli.next('div[data-id]');
                if (nextli.length == 0)
                {
                    var next_block = curli.closest('.search-block').next('.search-block');
                    if (next_block.length == 0)
                        nextli = $('#refreshpart div[data-id]:first');
                    else
                        nextli = next_block.find('div[data-id]:first');
                }

            }
            if (e.keyCode == 38)
            {
                var nextli = curli.prev('div[data-id]');
                if (nextli.length == 0)
                {
                    var prev_block = curli.closest('.search-block').prev('.search-block');
                    if (prev_block.length == 0)
                        nextli = $('#refreshpart div[data-id]:last');
                    else
                        nextli = prev_block.find('div[data-id]:last');
                }
            }

            curli.removeClass('active');
            nextli.addClass('active');
        }
        else
        {
            if ($(this).val().length > 0)
            {
                /*$('#refreshpart').show();*/
                $('#searchCancelButton').show();
                keyword = $(this).val();

                if ($('#refreshpart').scrollTop() > 0)
                    $('#refreshpart').scrollTop(0);
            }
            else
            {
                $('#refreshpart').hide();
            }
            search_request_ajax(keyword);
        }
    });

    $('#searchCancelButton').click(function(){
        $('#searchField').val('').removeClass("filled").focus();
        search_request_ajax();
        $('#refreshpart').hide();
        $(this).hide();
        return false;
    });



    $(document).on('click', '.expansible-block-toggler a', function(e){
        e.preventDefault();
        var $o = $(this).closest(".expansible-block");
        var text = $(this).attr("data-label");
        $(this).attr("data-label", $(this).text());
        $(this).text(text);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime();
            $o.attr("id", id);
        }
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ $o.find(".expansible-block-inner").outerHeight()+"px; }");
        $o.toggleClass("active");
    });

    $('#refreshpart').on('mouseenter', '.search-block-results div[data-id]', function(){
        $('#refreshpart .search-block-results div.active').removeClass('active');
        $(this).addClass('active');
    });

    $('#refreshpart').on("click", ".search-block-results div[data-id]", function(){
        var li = $(this);
        var object_id = li.attr('data-id');
        var object_type = li.attr('data-object');
        var object_href = li.attr('data-href');
        var object_name = li.find('.app-productname span').html();
        $('#searchField').val('');
        $('#searchField').blur();
        $('#refreshpart').hide();
        $('#searchCancelButton').hide();
        if (object_type == "product")
            //product_request_ajax(object_href);
            document.location.href = object_href;
        if (object_type == "category")
        {
            /*var params = [{ 'key': 'url', 'value': object_href}];
            products_request_ajax(params);*/
            document.location.href = object_href;
        }
        if (object_type == "brand")
        {
            var params = [{ 'key': 'url', 'value': object_href}];
            brand_request_ajax(params);
        }
        if (object_type == "collection")
            document.location.href = object_href;
        if (object_type == "href")
            document.location.href = object_href;
        return false;
    });

    $('#searchcategories').on('click', 'a.menu61', function(){
        document.location.href = $(this).attr('href');
        return false;
        /*var params = [{ 'key': 'url', 'value': $(this).attr('href')}];
        products_request_ajax(params);
        return false;*/
    });

    $('#help_msg1, #help_msg2').click(function(){
        $('#searchField').val($(this).html());
        $('#searchField').trigger('keyup');
        return false;
    });

    $('body').on('click', '#filter label[data-toggle=collapse]', function(){
        $(this).closest('div').find('i.fa-caret-right').first().toggle();
        $(this).closest('div').find('i.fa-caret-down').first().toggle();
    });

    $('body').on('click', '#filter i', function(){
        $(this).closest('div').find('label.labelcollapse').trigger('click');
    });

    $('html').click(function() {
        $('#refreshpart').hide();
    });

    $('#refreshpart').click(function(event){
        event.stopPropagation();
    });

    $('#main-left-container').on('click', '#pop-brands', function (){
        $(this).addClass('current');
        $('#all-brands').removeClass('current');
        $('#pop-brands-list').show();
        $('#all-brands-list').hide();
        $(this).closest(".left-brands").removeClass("wide");
        return false;
    });

    $('#main-left-container').on('click', '#all-brands', function (){
        $(this).addClass('current');
        $('#pop-brands').removeClass('current');
        $('#pop-brands-list').hide();
        $('#all-brands-list').show();
        $(this).closest(".left-brands").addClass("wide");
        return false;
    });

    $('#brands').on('click', '#pop-brands2', function (){
        $(this).addClass('current');
        $('#all-brands2').removeClass('current');
        $('#pop-brands-list2').show();
        $('#all-brands-list2').hide();
        $(this).closest(".left-brands").removeClass("wide");
        return false;
    });

    $('#brands').on('click', '#all-brands2', function (){
        $(this).addClass('current');
        $('#pop-brands2').removeClass('current');
        $('#pop-brands-list2').hide();
        $('#all-brands-list2').show();
        $(this).closest(".left-brands").addClass("wide");
        return false;
    });

    /* ФУНКЦИИ КОРЗИНЫ */
    $('#content').on('click' ,'li.listitem a.list-buy', function(){
        /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*/var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();
        /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
        /*if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
            var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
        else
            var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();*/
        var var_amount = $(this).attr('data-var-amount');
        var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
        $.get(href, function(data){
            if (data.success)
            {
                $('#cart .cart-counter').html(data.total_products).toggleClass("cart-counter--empty", !data.total_products);
                $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
                //$('#after-buy-form .modal-body').html(data.additional_result.added_form);
                $('#after-buy-form').modal();
            }
        });
        return false;
    });

    $('#main-right-container').on('click' ,'li.plitka-item a.plitka-buy', function(){
		var li = $(this).closest('li.plitka-item');
		var data_modal_img_src = li.attr('data-modal-img-src');
		var data_product_min_amount = parseFloat(li.attr('data-product-min-amount'));
		var data_product_tiers_amount = parseInt(li.attr('data-product-tiers-amount'));
		var data_product_name = li.attr('data-product-name');
		var data_price = li.attr('data-price');
		var data_variant_id = li.attr('data-variant-id');
		var data_is_favorite = parseInt(li.attr('data-is-favorite'));
		var data_add_to_favorite_href = li.attr('data-add-to-favorite');
		var data_remove_from_favorite_href = li.attr('data-remove-from-favorite');
		var data_min_amount = parseFloat(li.attr('data-min-amount'));
		var data_max_amount = parseFloat(li.attr('data-max-amount'));
		
		if (data_is_favorite == 1) {
			$('#tort-plitka-buy .add_to_favorite_modal').hide();
			$('#tort-plitka-buy .remove_from_favorite_modal').show();
		} else {
			$('#tort-plitka-buy .add_to_favorite_modal').show();
			$('#tort-plitka-buy .remove_from_favorite_modal').hide();
		}
		$('#tort-plitka-buy .add_to_favorite_modal').attr('href', data_add_to_favorite_href);
		$('#tort-plitka-buy .remove_from_favorite_modal').attr('href', data_remove_from_favorite_href);
		
		$('#tort-plitka-buy input[name=modal_variant_id]').val(data_variant_id);
		
		$('#product-modal-amount').val(data_product_min_amount);
		
		$('#tort-plitka-buy .mproduct .mproduct__left img').attr('src', data_modal_img_src);
		for(var i = 1 ; i <= data_product_tiers_amount - 1 ; i++) {
			$('#product-modal-tiers option[value='+i+']').prop('disabled', false);
			$('#product-modal-filling'+i+'-group').show();
		}
		for(var i = data_product_tiers_amount + 1 ; i <= 5; i++) {
			$('#product-modal-tiers option[value='+i+']').prop('disabled', true);
			$('#product-modal-filling'+i+'-group').hide();
		}
		$('#product-modal-tiers option[value='+data_product_tiers_amount+']').prop('selected', true);
		
		$('#tort-plitka-buy .js-tort-plitka-buy-title').html(data_product_name);
		$('#tort-plitka-buy .js-tort-plitka-buy-price').html(data_price);
		
		$('#product-modal-fillings-error').hide();
		
		var all_weight_amount = [];
		var weight_amount = [];
		
		all_weight_amount.push({value: "1.5",    persons: "7 чел",  showtext: "7 чел (1.5 кг)",  weight: "1.5 кг"});
		all_weight_amount.push({value: "2",    persons: "10 чел",  showtext: "10 чел (2 кг)",  weight: "2 кг"});
		all_weight_amount.push({value: "2.5",  persons: "13 чел",  showtext: "13 чел (2.5 кг)",  weight: "2.5 кг"});
		all_weight_amount.push({value: "3",    persons: "15 чел",  showtext: "15 чел (3 кг)",  weight: "3 кг"});
		all_weight_amount.push({value: "3.5",  persons: "18 чел",  showtext: "18 чел (3.5 кг)",  weight: "3.5 кг"});
		all_weight_amount.push({value: "4",    persons: "20 чел",  showtext: "20 чел (4 кг)",  weight: "4 кг"});
		all_weight_amount.push({value: "4.5",  persons: "23 чел",  showtext: "23 чел (4.5 кг)",  weight: "4.5 кг"});
		all_weight_amount.push({value: "5",    persons: "25 чел",  showtext: "25 чел (5 кг)",  weight: "5 кг"});
		all_weight_amount.push({value: "5.5",  persons: "28 чел",  showtext: "28 чел (5.5 кг)",  weight: "5.5 кг"});
		all_weight_amount.push({value: "6",    persons: "30 чел",  showtext: "30 чел (6 кг)",  weight: "6 кг"});
		all_weight_amount.push({value: "6.5",  persons: "33 чел",  showtext: "33 чел (6.5 кг)",  weight: "6.5 кг"});
		all_weight_amount.push({value: "7",    persons: "35 чел",  showtext: "35 чел (7 кг)",  weight: "7 кг"});
		all_weight_amount.push({value: "7.5",  persons: "38 чел",  showtext: "38 чел (7.5 кг)",  weight: "7.5 кг"});
		all_weight_amount.push({value: "8",    persons: "40 чел",  showtext: "40 чел (8 кг)",  weight: "8 кг"});
		all_weight_amount.push({value: "8.5",  persons: "43 чел",  showtext: "43 чел (8.5 кг)",  weight: "8.5 кг"});
		all_weight_amount.push({value: "9",    persons: "45 чел",  showtext: "45 чел (9 кг)",  weight: "9 кг"});
		all_weight_amount.push({value: "9.5",  persons: "48 чел",  showtext: "48 чел (9.5 кг)",  weight: "9.5 кг"});
		all_weight_amount.push({value: "10",   persons: "50 чел",  showtext: "50 чел (10 кг)",  weight: "10 кг"});
		all_weight_amount.push({value: "10.5", persons: "53 чел",  showtext: "53 чел (10.5 кг)",  weight: "10.5 кг"});
		all_weight_amount.push({value: "11",   persons: "55 чел",  showtext: "55 чел (11 кг)",  weight: "11 кг"});
		all_weight_amount.push({value: "11.5", persons: "58 чел",  showtext: "58 чел (11.5 кг)",  weight: "11.5 кг"});
		all_weight_amount.push({value: "12",   persons: "60 чел",  showtext: "60 чел (12 кг)",  weight: "12 кг"});
		all_weight_amount.push({value: "12.5", persons: "63 чел",  showtext: "63 чел (12.5 кг)",  weight: "12.5 кг"});
		all_weight_amount.push({value: "13",   persons: "65 чел",  showtext: "65 чел (13 кг)",  weight: "13 кг"});
		all_weight_amount.push({value: "13.5", persons: "68 чел",  showtext: "68 чел (13.5 кг)",  weight: "13.5 кг"});
		all_weight_amount.push({value: "14",   persons: "70 чел",  showtext: "70 чел (14 кг)",  weight: "14 кг"});
		all_weight_amount.push({value: "14.5", persons: "73 чел",  showtext: "73 чел (14.5 кг)",  weight: "14.5 кг"});
		all_weight_amount.push({value: "15",   persons: "75 чел",  showtext: "75 чел (15 кг)",  weight: "15 кг"});
		all_weight_amount.push({value: "15.5", persons: "78 чел",  showtext: "78 чел (15.5 кг)",  weight: "15.5 кг"});
		all_weight_amount.push({value: "16",   persons: "80 чел",  showtext: "80 чел (16 кг)",  weight: "16 кг"});
		all_weight_amount.push({value: "16.5", persons: "83 чел",  showtext: "83 чел (16.5 кг)",  weight: "16.5 кг"});
		all_weight_amount.push({value: "17",   persons: "85 чел",  showtext: "85 чел (17 кг)",  weight: "17 кг"});
		all_weight_amount.push({value: "17.5", persons: "88 чел",  showtext: "88 чел (17.5 кг)",  weight: "17.5 кг"});
		all_weight_amount.push({value: "18",   persons: "90 чел",  showtext: "90 чел (18 кг)",  weight: "18 кг"});
		all_weight_amount.push({value: "18.5", persons: "93 чел",  showtext: "93 чел (18.5 кг)",  weight: "18.5 кг"});
		all_weight_amount.push({value: "19",   persons: "95 чел",  showtext: "95 чел (19 кг)",  weight: "19 кг"});
		all_weight_amount.push({value: "19.5", persons: "98 чел",  showtext: "98 чел (19.5 кг)",  weight: "19.5 кг"});
		all_weight_amount.push({value: "20",   persons: "100 чел", showtext: "100 чел (20 кг)", weight: "20 кг"});
		
		all_weight_amount.forEach((element) => {
			if (data_min_amount <= element.value && element.value <= data_max_amount)
				weight_amount.push(element);
		});

		$('#product-modal-amount').inputpicker({
			data: weight_amount,
			fields:[
				{name: 'persons', text:'Кол-во гостей'},
				{name: 'weight', text:'Вес торта'}
			],
			headShow: true,
			fieldText : 'showtext',
			fieldValue: 'value',
			width: '100%',
		});
		
		$('#tort-plitka-buy').modal();
		
        /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*//*var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();*/
        /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
        /*if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
            var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
        else
            var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();*/
        /*var var_amount = $(this).attr('data-var-amount');
        var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + "&amount=1&var_amount=" + var_amount;
        $.get(href, function(data){
            if (data.success)
            {
                $('#cart .cart-counter').html(data.total_products).toggleClass("cart-counter--empty", !data.total_products);
                $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
                //$('#after-buy-form .modal-body').html(data.additional_result.added_form);
                $('#after-buy-form').modal();
            }
        });*/
        return false;
    });

    $('#main-right-container').on('click' ,'div.plitka-item a.plitka-buy', function(){
		var div = $(this).closest('div.plitka-item');
		var data_modal_img_src = div.attr('data-modal-img-src');
		var data_product_min_amount = parseFloat(div.attr('data-product-min-amount'));
		var data_product_tiers_amount = parseInt(div.attr('data-product-tiers-amount'));
		var data_product_name = div.attr('data-product-name');
		var data_price = div.attr('data-price');
		var data_variable_amount = div.attr('data-variable-amount');
		var data_flag3 = div.attr('data-flag3');
		var data_flag5 = div.attr('data-flag5');
		var data_field4 = div.attr('data-field4');
		var data_variant_id = div.attr('data-variant-id');
		var data_is_favorite = parseInt(div.attr('data-is-favorite'));
		var data_add_to_favorite_href = div.attr('data-add-to-favorite');
		var data_remove_from_favorite_href = div.attr('data-remove-from-favorite');
		var data_min_amount = parseFloat(div.attr('data-min-amount'));
		var data_max_amount = parseFloat(div.attr('data-max-amount'));
		
		$('#tort-plitka-buy').attr('data-price', data_price);
		$('#tort-plitka-buy').attr('data-flag3', data_flag3);
		$('#tort-plitka-buy').attr('data-flag5', data_flag5);
		$('#tort-plitka-buy').attr('data-field4', data_field4);
		$('#tort-plitka-buy').attr('data-variable-amount', data_variable_amount);
		
		if (data_is_favorite == 1) {
			$('#tort-plitka-buy .add_to_favorite_modal').hide();
			$('#tort-plitka-buy .remove_from_favorite_modal').show();
		} else {
			$('#tort-plitka-buy .add_to_favorite_modal').show();
			$('#tort-plitka-buy .remove_from_favorite_modal').hide();
		}
		$('#tort-plitka-buy .add_to_favorite_modal').attr('href', data_add_to_favorite_href);
		$('#tort-plitka-buy .remove_from_favorite_modal').attr('href', data_remove_from_favorite_href);
		
		$('#tort-plitka-buy input[name=modal_variant_id]').val(data_variant_id);
		
		$('#product-modal-amount').val(data_product_min_amount);
		
		$('#tort-plitka-buy .mproduct .mproduct__left img').attr('src', data_modal_img_src);
		for(var i = 1 ; i <= data_product_tiers_amount - 1 ; i++) {
			$('#product-modal-tiers option[value='+i+']').prop('disabled', false);
			$('#product-modal-filling'+i+'-group').show();
		}
		for(var i = data_product_tiers_amount + 1 ; i <= 5; i++) {
			$('#product-modal-tiers option[value='+i+']').prop('disabled', true);
			$('#product-modal-filling'+i+'-group').hide();
		}
		$('#product-modal-tiers option[value='+data_product_tiers_amount+']').prop('selected', true);
		
		$('#tort-plitka-buy .mproduct__title').html(data_product_name);
		$('#tort-plitka-buy .js-tort-plitka-buy-price').html(''/*data_price*/);
		
		$('#product-modal-fillings-error').hide();
		
		var all_weight_amount = [];
		var weight_amount = [];
		
		all_weight_amount.push({value: "1.5",    persons: "7 чел",  showtext: "7 чел (1.5 кг)",  weight: "1.5 кг"});
		all_weight_amount.push({value: "2",    persons: "10 чел",  showtext: "10 чел (2 кг)",  weight: "2 кг"});
		all_weight_amount.push({value: "2.5",  persons: "13 чел",  showtext: "13 чел (2.5 кг)",  weight: "2.5 кг"});
		all_weight_amount.push({value: "3",    persons: "15 чел",  showtext: "15 чел (3 кг)",  weight: "3 кг"});
		all_weight_amount.push({value: "3.5",  persons: "18 чел",  showtext: "18 чел (3.5 кг)",  weight: "3.5 кг"});
		all_weight_amount.push({value: "4",    persons: "20 чел",  showtext: "20 чел (4 кг)",  weight: "4 кг"});
		all_weight_amount.push({value: "4.5",  persons: "23 чел",  showtext: "23 чел (4.5 кг)",  weight: "4.5 кг"});
		all_weight_amount.push({value: "5",    persons: "25 чел",  showtext: "25 чел (5 кг)",  weight: "5 кг"});
		all_weight_amount.push({value: "5.5",  persons: "28 чел",  showtext: "28 чел (5.5 кг)",  weight: "5.5 кг"});
		all_weight_amount.push({value: "6",    persons: "30 чел",  showtext: "30 чел (6 кг)",  weight: "6 кг"});
		all_weight_amount.push({value: "6.5",  persons: "33 чел",  showtext: "33 чел (6.5 кг)",  weight: "6.5 кг"});
		all_weight_amount.push({value: "7",    persons: "35 чел",  showtext: "35 чел (7 кг)",  weight: "7 кг"});
		all_weight_amount.push({value: "7.5",  persons: "38 чел",  showtext: "38 чел (7.5 кг)",  weight: "7.5 кг"});
		all_weight_amount.push({value: "8",    persons: "40 чел",  showtext: "40 чел (8 кг)",  weight: "8 кг"});
		all_weight_amount.push({value: "8.5",  persons: "43 чел",  showtext: "43 чел (8.5 кг)",  weight: "8.5 кг"});
		all_weight_amount.push({value: "9",    persons: "45 чел",  showtext: "45 чел (9 кг)",  weight: "9 кг"});
		all_weight_amount.push({value: "9.5",  persons: "48 чел",  showtext: "48 чел (9.5 кг)",  weight: "9.5 кг"});
		all_weight_amount.push({value: "10",   persons: "50 чел",  showtext: "50 чел (10 кг)",  weight: "10 кг"});
		all_weight_amount.push({value: "10.5", persons: "53 чел",  showtext: "53 чел (10.5 кг)",  weight: "10.5 кг"});
		all_weight_amount.push({value: "11",   persons: "55 чел",  showtext: "55 чел (11 кг)",  weight: "11 кг"});
		all_weight_amount.push({value: "11.5", persons: "58 чел",  showtext: "58 чел (11.5 кг)",  weight: "11.5 кг"});
		all_weight_amount.push({value: "12",   persons: "60 чел",  showtext: "60 чел (12 кг)",  weight: "12 кг"});
		all_weight_amount.push({value: "12.5", persons: "63 чел",  showtext: "63 чел (12.5 кг)",  weight: "12.5 кг"});
		all_weight_amount.push({value: "13",   persons: "65 чел",  showtext: "65 чел (13 кг)",  weight: "13 кг"});
		all_weight_amount.push({value: "13.5", persons: "68 чел",  showtext: "68 чел (13.5 кг)",  weight: "13.5 кг"});
		all_weight_amount.push({value: "14",   persons: "70 чел",  showtext: "70 чел (14 кг)",  weight: "14 кг"});
		all_weight_amount.push({value: "14.5", persons: "73 чел",  showtext: "73 чел (14.5 кг)",  weight: "14.5 кг"});
		all_weight_amount.push({value: "15",   persons: "75 чел",  showtext: "75 чел (15 кг)",  weight: "15 кг"});
		all_weight_amount.push({value: "15.5", persons: "78 чел",  showtext: "78 чел (15.5 кг)",  weight: "15.5 кг"});
		all_weight_amount.push({value: "16",   persons: "80 чел",  showtext: "80 чел (16 кг)",  weight: "16 кг"});
		all_weight_amount.push({value: "16.5", persons: "83 чел",  showtext: "83 чел (16.5 кг)",  weight: "16.5 кг"});
		all_weight_amount.push({value: "17",   persons: "85 чел",  showtext: "85 чел (17 кг)",  weight: "17 кг"});
		all_weight_amount.push({value: "17.5", persons: "88 чел",  showtext: "88 чел (17.5 кг)",  weight: "17.5 кг"});
		all_weight_amount.push({value: "18",   persons: "90 чел",  showtext: "90 чел (18 кг)",  weight: "18 кг"});
		all_weight_amount.push({value: "18.5", persons: "93 чел",  showtext: "93 чел (18.5 кг)",  weight: "18.5 кг"});
		all_weight_amount.push({value: "19",   persons: "95 чел",  showtext: "95 чел (19 кг)",  weight: "19 кг"});
		all_weight_amount.push({value: "19.5", persons: "98 чел",  showtext: "98 чел (19.5 кг)",  weight: "19.5 кг"});
		all_weight_amount.push({value: "20",   persons: "100 чел", showtext: "100 чел (20 кг)", weight: "20 кг"});
		
		all_weight_amount.forEach((element) => {
			if (data_min_amount <= element.value && element.value <= data_max_amount)
				weight_amount.push(element);
		});
		
		$('#product-modal-amount').inputpicker({
			data: weight_amount,
			fields:[
				{name: 'persons', text:'Кол-во гостей'},
				{name: 'weight', text:'Вес торта'}
			],
			headShow: true,
			fieldText : 'showtext',
			fieldValue: 'value',
			width: '100%',
		});
		calculate_price_modal_1click();
		
		$('#product-modal-amount').change(function(){
			var val = parseFloat(this.value);
			var tiers_count = 0;
			$('#product-modal-tiers option').each(function(index, opt){
				if (parseInt(opt.getAttribute('data-weight')) <= parseInt(val)) {
					if (opt.hasAttribute('disabled')) { opt.removeAttribute('disabled'); }
					this.text = opt.getAttribute('data-normal-text');
					tiers_count++;
				} else {
					if (!opt.hasAttribute('disabled')) { opt.setAttribute('disabled', 'disabled'); }
					this.text = opt.getAttribute('data-warning-text');
				}
			});
			for(var i=tiers_count+1;i<=5;i++)
			{
				$('#product-modal-filling'+i+'-group').hide();
			}
			$('#product-modal-tiers').val(tiers_count);
			$('#product-modal-tiers').trigger('change');
			$('#product-modal-tiers').select2('destroy');
			$('#product-modal-tiers').removeClass("selectbox-inited");
			selectbox_init($('#product-modal-tiers'));
			calculate_price_modal_1click();
		});
		
		$('#tort-plitka-buy').modal();
        /*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*//*var variant_id = $(this).closest('div.plitka-item').find('select[name=variant_id] option:selected').val();*/
        /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
        /*if ($(this).closest('div.plitka-item').find('input[name=variant_id]:checked').length > 0)
            var variant_id = $(this).closest('div.plitka-item').find('input[name=variant_id]:checked').val();
        else
            var variant_id = $(this).closest('div.plitka-item').find('input[name=variant_id][checked]').val();*/
        /*var var_amount = $(this).attr('data-var-amount');
        var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
        $.get(href, function(data){
            if (data.success)
            {
                $('#cart .cart-counter').html(data.total_products).toggleClass("cart-counter--empty", !data.total_products);
                $('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
                //$('#after-buy-form .modal-body').html(data.additional_result.added_form);
                $('#after-buy-form').modal();
            }
        });*/
        return false;
    });
	
	function calculate_price_modal_1click() {
		$('.js-tort-plitka-buy-price').html('');
		
		var price = parseFloat($('#tort-plitka-buy').attr('data-price'));
		var flag3 = parseInt($('#tort-plitka-buy').attr('data-flag3'));
		var flag5 = parseInt($('#tort-plitka-buy').attr('data-flag5'));
		var field4 = parseInt($('#tort-plitka-buy').attr('data-field4'));
		
		var amount = 1;
		if ($('#product-modal-amount').length > 0) {
			var amount_input = $('#product-modal-amount');
			if (amount_input.length > 0)
				amount = amount_input.val();
		}
		
		var total_price = price;
		
		var is_variable_amount = 0;
		if ($('#tort-plitka-buy').attr('data-variable-amount') == "1") {
			is_variable_amount = 1;
		}
		
		var total_price_variant_for_multi = total_price;
		
		var total_price_formatted = String(total_price.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		
		if (flag3 && !flag5)
			total_price_formatted = "от " + total_price_formatted;
		
		if (is_variable_amount) {
			$('.js-tort-plitka-buy-price').html(total_price_formatted + ' ' + mc_main_currency_sign + '/кг');
		} else {
			$('.js-tort-plitka-buy-price').html(total_price_formatted + ' ' + mc_main_currency_sign + '/шт');
		}
		
		total_price = total_price_variant_for_multi * amount;
		
		if (flag5)
			total_price += parseFloat(field4);
		
		total_price_formatted = String(total_price.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		
		if (flag5)
			total_price_formatted = total_price_formatted + ' ' + mc_main_currency_sign + ' за ' + amount.toString() + ' кг с оформлением';
		else
			if (flag3)
				total_price_formatted = 'от ' + total_price_formatted + ' ' + mc_main_currency_sign + ' за ' + amount.toString() + ' кг без оформления';
		
		$('.js-tort-plitka-buy-price').html(total_price_formatted /*+ ' ' + mc_main_currency_sign*/ );
	}

    $('#main-right-container').on('click' , 'a.add_to_compare', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
                $('#make-compare').attr('href', data.compare_href);
                $('#make-compare').html(data.compare_href_label);
                if (data.compare_href_show)
                    $('#make-compare').closest('div').show();
                else
                    $('#make-compare').closest('div').hide();
                a.closest('div').hide().next('.buy-compare').show().find('a.compare_items').attr('href', data.compare_href).html('Сравнить ('+data.compare_items_count+')');
                a.closest('ul.list').find('a.compare_items').each(function(){
                    $(this).attr('href', data.compare_href);
                    $(this).html('Сравнить ('+data.compare_items_count+')');
                });
            }
        });
        return false;
    });


    $('#main-right-container').on('click', 'a.remove_from_compare', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
                $('#make-compare').attr('href', data.compare_href);
                $('#make-compare').html(data.compare_href_label);
                if (data.compare_href_show)
                    $('#make-compare').closest('div').show();
                else
                    $('#make-compare').closest('div').hide();
                a.closest('div').hide().prev('.buy-compare').show();
                a.closest('ul.list').find('a.compare_items').each(function(){
                    $(this).attr('href', data.compare_href);
                    $(this).html('Сравнить ('+data.compare_items_count+')');
                });
            }
        });
        return false;
    });
	
	$(document).on('click' , 'a.add_to_favorite', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
                $('#make-favorite').attr('href', data.favorite_href);
                $('#make-favorite .htip__sticker').html(data.favorite_items_count);
				$('#make-favorite .htip__sticker').show();
				/*$('a.add_to_favorite').hide();
				$('a.remove_from_favorite').show();*/
                a.closest(".js-fav-wrapper").addClass("fav-added");
            }
        });
        return false;
    });
	
	$(document).on('click', 'a.remove_from_favorite', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
                $('#make-favorite').attr('href', data.favorite_href);
                $('#make-favorite .htip__sticker').html(data.favorite_items_count);
                if (data.favorite_items_count)
                    $('#make-favorite .htip__sticker').show();
                else
                    $('#make-favorite .htip__sticker').hide();
				/*$('a.add_to_favorite').show();
				$('a.remove_from_favorite').hide();*/
                a.closest(".js-fav-wrapper").removeClass("fav-added");
            }
        });
        return false;
    });
	
	$(document).on('click' , 'a.add_to_favorite_tile', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
                $('#make-favorite').attr('href', data.favorite_href);
                $('#make-favorite .htip__sticker').html(data.favorite_items_count);
				$('#make-favorite .htip__sticker').show();
                //a.hide();
                a.closest(".js-fav-wrapper").addClass("fav-added");
                a.closest('div').find('.add_to_favorite_tile').hide();
                a.closest('div').find('.remove_from_favorite_tile').show();
            }
        });
        return false;
    });
	
	$(document).on('click', 'a.remove_from_favorite_tile', function(){
        var a = $(this);
        var href = a.attr('href');
        $.get(href, function(data){
            if (data.success)
            {
				if ($('#products-update-block').attr('data-module') == 'FavoriteController') {
					document.location.href = data.favorite_href;
				} else {
					$('#make-favorite').attr('href', data.favorite_href);
					$('#make-favorite .htip__sticker').html(data.favorite_items_count);
					if (data.favorite_items_count)
						$('#make-favorite .htip__sticker').show();
					else
						$('#make-favorite .htip__sticker').hide();
					//a.hide();
                    a.closest(".js-fav-wrapper").removeClass("fav-added");
					a.closest('div').find('.add_to_favorite_tile').show();
                    a.closest('div').find('.remove_from_favorite_tile').hide();
				}
            }
        });
        return false;
    });
	
	$('#show-all-subcategories-in-category').click(function(){
		$('#found-categories ul li').show();
		$(this).hide();
		return false;
	});

    $('#wrap').on('click', 'a.cart-buy1click', function(){
        $('#cart').removeClass('hover');

        $.get(mc_cart_oneclick_module_url, function(data){
            if (data.success){
                $('#buy1click .modal-body .cart-body').html(data.data);
                $('#make-order-one-click').removeClass('disabled');
                $('#buy1click-phone-required').hide();

                if (data.total_price >= mc_cart_order_min_price){
                    $('#not-enough-block').hide();
                    $('#make-order-one-click').show();
                    $('#buy1click .buy1click-user-info').show();
                }
                else{
                    $('#not-enough-block').show();
                    $('#make-order-one-click').hide();
                    $('#buy1click .buy1click-user-info').hide();
                }

                $('#buy1click').modal();
                $('#buy1click input[name=phone_number]').focus();
            }
        });

        return false;
    });

    $('#content').on('click', 'a.buy1', function(){
        //
        $('#fillings-error').hide();
        var is_error = false;
        $('.btn-select-filling:visible').each(function(i, elem) {
            if ($(this).attr('data-filling-id') == '0') {
                $('#fillings-error').show();
                is_error = true;
                return false;
            }
        });
        if (is_error) {
            return false;
        }
		
        var button = $(this);
        $('#buy1spinner').removeClass('hidden');
        button.attr('disabled', true);
        /*ЕСЛИ ВАРИАНТЫ СЕЛЕКТ*/
        var variant_id = $(this).closest('#content').find('select[name=variant_id] option:selected').val();
        /*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
        if ($(this).closest('#content').find('input[name^=variant_id]:checked').length > 0)
            var variant_id = $(this).closest('#content').find('input[name^=variant_id]:checked').val();
        else
            var variant_id = $(this).closest('#content').find('input[name^=variant_id][checked]').val();
        var modificators = [];
        var modificators_count = [];
		var fillings = [];
        var amount = 1;
		var var_amount = 1;
        //if ($('#amount').length > 0) {
		//	var amount_input = $('#amount');
		//	if (amount_input.length > 0)
		//		amount = amount_input.val();
		//}
		if ($('#amount').length > 0) {
			var amount_input = $('#amount');
			if (amount_input.length > 0)
				var_amount = amount_input.val();
		}
		if ($('#content input[name=amount]').length > 0) {
			var amount_input = $('#content input[name=amount]');
			if (amount_input.length > 0)
				amount = amount_input.val();
		}

        /* ПОЛУЧИМ ВЫБРАННЫЕ ДОП КОМПЛЕКТАЦИИ */
        var dop_products = [];
        $('#dop-products .doplistitem input[name^=dop_products]:checked').each(function(){
            dop_products.push($(this).closest('li.doplistitem').find('select[name=dop_variant_id] option:selected:first').val());
            //dop_products.push($(this).val());
        });

        $('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
            if (parseInt($(this).val()) > 0)
            {
                modificators.push($(this).val());
                var ic = $(this).closest('div').find('input[name^=modificators_count]');
                if (ic.length > 0)
                    modificators_count.push(ic.val());
                else
                    modificators_count.push(1);
            }
        });

        /*var var_amount = 1;
        var var_amount_input = $('#buy input[name=var_amount]');
        if (var_amount_input.length > 0)
            var_amount = var_amount_input.val();
        var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&modificators=' + modificators.join(',') + '&modificators_count=' + modificators_count.join(',') + '&amount=' + amount + '&var_amount=' + var_amount;*/
		
		var tiers_count = $('select[name=tiers] option:selected').val();
		for(var i=1 ; i <= tiers_count; i++) {
			fillings.push($('#filling'+i).attr('data-filling-id'));
		}

		var text_on_cake = $('input[name=text_on_cake]').val();
		var temp_id = $('input[name=temp_id]:first').val();

		var href = mc_cart_module_url + "?action=add_variant&variant_id="+variant_id+"&modificators="+modificators.join(',')+"&modificators_count="+modificators_count.join(',')+"&amount="+amount+"&var_amount="+var_amount+"&fillings="+fillings.join(',')+'&text_on_cake='+encodeURIComponent(text_on_cake)+'&temp_id='+temp_id;

        if (dop_products.length > 0)
            href += "&dop_products="+dop_products.join(',');
		
		//$.get(mc_cart_empty_cart_module_url, function(data){
        //    if (data.success){
				$.get(href, function(data){
					if (data.success)
					{
						$('#cart .cart-counter').html(data.total_products).toggleClass("cart-counter--empty", !data.total_products);
						if (data.additional_result != undefined && data.additional_result.message == "no_stock")
						{
							$('.buy-buy').hide();
							$('.buy1').hide();
							$('#no_stock').html('Вы можете купить товар в количестве не более ' + data.additional_result.max_stock + ' штук');
							$('#no_stock').show();
						}

						$.get(mc_cart_oneclick_module_url, function(data){
							if (data.success){
								$('#buy1click .modal-body').html(data.data);
								$('#buy1click input[name=variant_id]').val(variant_id);
								$('#make-order-one-click').removeClass('disabled');
								$('#buy1click-phone-required').hide();

								if (data.total_price >= mc_cart_order_min_price){
									$('#not-enough-block').hide();
									$('#make-order-one-click').show();
									$('#buy1click .buy1click-user-info').show();
								}
								else{
									$('#not-enough-block').show();
									$('#make-order-one-click').hide();
									$('#buy1click .buy1click-user-info').hide();
								}

								$('#buy1click').modal();
								$('#buy1click input[name=phone_number]').focus();
							}
							$('#buy1spinner').addClass('hidden');
							button.attr('disabled', false);
						});
					}
				});
		//	}
		//});

        return false;
    });

    $('#content').on('click', 'a.buy-one-click', function(){
        var button = $(this);
        button.find('.buy1spinner').removeClass('hidden');
        button.attr('disabled', true);

        /*ЕСЛИ ВАРИАНТЫ СЕЛЕКТ*//*var variant_id = $(this).closest('li').find('select[name=variant_id] option:selected').val();*/
        if ($(this).closest('li').find('input[name=variant_id]:checked').length > 0)
            var variant_id = $(this).closest('li').find('input[name=variant_id]:checked').val();
        else
            var variant_id = $(this).closest('li').find('input[name=variant_id][checked]').val();
        var var_amount = $(this).attr('data-var-amount');
        var href = mc_cart_module_url + '?action=add_variant&variant_id=' + variant_id + '&amount=1&var_amount=' + var_amount;
		
		//$.get(mc_cart_empty_cart_module_url, function(data){
        //    if (data.success){
				$.get(href, function(data){
					if (data.success){
						$('#cart .cart-counter').html(data.total_products).toggleClass("cart-counter--empty", !data.total_products);

						$.get(mc_cart_oneclick_module_url, function(data){
							if (data.success){
								$('#buy1click .modal-body .cart-body').html(data.data);
								$('#buy1click input[name=variant_id]').val(variant_id);
								$('#make-order-one-click').removeClass('disabled');
								$('#buy1click-phone-required').hide();

								if (data.total_price >= mc_cart_order_min_price){
									$('#not-enough-block').hide();
									$('#make-order-one-click').show();
									$('#buy1click .buy1click-user-info').show();
								}
								else{
									$('#not-enough-block').show();
									$('#make-order-one-click').hide();
									$('#buy1click .buy1click-user-info').hide();
								}

								$('#buy1click').modal();
								$('#buy1click input[name=phone_number]').focus();
							}
							button.find('.buy1spinner').addClass('hidden');
							button.attr('disabled', false);
						});
					}
				});
		//	}
		//});

        return false;
    });

    $('#make-callback, #make-callback2').click(function(){
        $('#contact-center input[name=phone_number]').val((mc_user_phone_code.length > 0 && mc_user_phone_w_mask.length > 0) ? '+7 (' + mc_user_phone_code + ')' + mc_user_phone_w_mask : '').mask("+7 (999) 999-99-99");
        $('#contact-center input[name=user_name]').val(mc_user_name);
        $('#contact-center input[name=call_time]').val('');
        $('#contact-center textarea[name=message]').val('');

        $('#callback-part').show();
        $('#send-callback').show();
        $('#callback-success-part').hide();
        $('#callback-error-part').hide();
        $('#callback-close').hide();
        $('#contact-center-phone-required').hide();

        $('#contact-center').modal();
        if ($('#contact-center input[name=phone_number]').val() == "")
            $('#contact-center input[name=phone_number]').focus();
        else
        if ($('#contact-center input[name=user_name]').val() == "")
            $('#contact-center input[name=user_name]').focus();
        else
            $('#contact-center input[name=call_time]').focus();
        return false;
    });

    $('#main-right-container').on('click', 'a.review-complain', function(){
        $('#claim-part').show();
        $('#make-claim').show();
        $('#claim-success-part').hide();
        $('#claim-error-part').hide();
        $('#claim-close').hide();

        $('#complain-form .modal-body textarea').val('').prop('disabled', true);
        $('#complain-form .modal-body input[type=radio]:first').prop('checked', true);
        $('#complain-form input[name=claim_review_id]').val($(this).closest('.review-body').data('id'));
        $('#complain-form').modal();
        return false;
    });

    $('#main-right-container').on('click', '.block-class .group-products-filter .group-products-filter-element', function(){
        var type = $(this).data('type-id');
        $(this).closest('.group-products-filter').find('.group-products-filter-element').removeClass('active');
        $(this).addClass('active');

        var $owl = $(this).closest(".block-class").find(".owl-tiles");
        $all = $(this).closest(".block-class").find(".plitka-item").parent();
        if (!$owl.data("items-all")) $owl.data("items-all", $all);
        else $all = $owl.data("items-all");
        if (type) $filtered = $all.find(".plitka-item[data-type-id='"+type+"']").parent();
        else $filtered = $all;
        $filtered_clone = $filtered.clone(true);

        $all.each(function(i){
            $owl.trigger('del.owl.carousel', 0);
        });
        $($filtered_clone.get().reverse()).each(function(i){
            $owl.trigger('add.owl.carousel', [$(this), 0]);
        });
        $owl.trigger('update.owl.carousel');
        $owl.trigger('refresh.owl.carousel');
        return false;
    });
	
	$('#free-taste-modal').click(function(){
		$('#free-taste input[name=name]').val('');
		$('#free-taste input[name=phone]').val('');
		$('#free-taste input[name=time]').val('');
		$('#free-taste input[name=date]').val('');
		$('#free-taste input[name=address]').val('');
		$('#callback-part-free-taste').show();
		$('#callback-success-part-free-taste').hide();
		$('#callback-error-part-free-taste').hide();
		$('#free-taste input[name=name]').focus();
		$('#send-callback-free-taste').show();
	});

    $(document).on('hidden.bs.modal', '.modal', function () {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
    });
}



function sticky_init($o) {
    if (typeof $o === "undefined" || !$o) $o = $(".js-sticky");
    $o.not('.js-sticky-inited').each(function () {
        var stickyEl = new Sticksy(this, {
            topSpacing: 20
        });
        if ($(this).data('sticky-empty-dummy')) {
            var $dummy = $(this).prev('.sticksy-dummy-node');
            $dummy.height($(this).height());
            $dummy.empty();
        }
        stickyEl.onStateChanged = function (state) {
            if (state === 'fixed') stickyEl.nodeRef.classList.add('sticky-fixed');
            else stickyEl.nodeRef.classList.remove('sticky-fixed');
            if (state === 'stuck') stickyEl.nodeRef.classList.add('sticky-stuck');
            else stickyEl.nodeRef.classList.remove('sticky-stuck');
        }
        $(this).addClass("js-sticky-inited");
    });
}

/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    window['expand_it_window_width'] = -1;//$(window).width();
    $(window).on("resize", function () {
        if($(this).width() != window['expand_it_window_width']){
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        if (!$(this).data('no-prevent-default')) {
            e.preventDefault();
        }
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;
        if ($(this).data('expand-only-open') && e.type !== "expand-it") {
            if (!$o.hasClass('active')) {
                expand_it_trigger($(this), $o, e.type === "expand-it");
            }
        }
        else {
            expand_it_trigger($(this), $o, e.type === "expand-it");
        }
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        var $o = $(e.target);
        if ($o.hasClass('expand-it-container')) {
            $(e.target).trigger("expandAfter", [e]);
            if ($o.hasClass("before-transition")) {
                expand_it_height_check($o);
            }
            expand_it_init_prepare($o.closest('.expand-it-container'), true);
            $(e.target).removeClass("before-transition");
        }
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i))
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }

    $(document).on("expand-it-open", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });
}

function expand_it_height_check($o) {
    if ($o.hasClass("expand-it-container") && !$o.hasClass("expand-it-container-overflow-hidden"))
    {
        var height_default = 0;
        var current_height = parseInt($o.css("max-height"), 10);
        if ($o.find(".expand-it-container-height-default").length)
        {
            height_default = $o.find(".expand-it-container-height-default").height();
        }
        $o.toggleClass("overflow-visible", current_height > height_default);
    }
    if ($o.hasClass("expand-it-container") && $o.hasClass("expand-it-container-max-height-auto"))
    {
        var id = $o.attr("id");
        setTimeout(function(){
            removeCSSRule("rule-"+id);
        }, 300);
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    if ($o.hasClass('expand-it-container-ignore')) {
        return;
    }
    expand_it_init_prepare($o, true);
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this).find(".expand-it-container").first();
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    var $this_other = $(".expand-it.active[href='#"+$this.attr("id")+"']");
                    if ($this_other.length) {
                        $this_other.trigger("expand-it");
                    }
                    else {
                        var $this_other = $(this).find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                        if ($this_other.length) {
                            expand_it_trigger($this_other, $this, true);
                        }
                    }
                    if ($this_other.length) {
                        $this_other.removeClass("active");
                    }
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    var $sticky = $(".js-goto-offset-expand");
                    var offset = 0;
                    if ($sticky.length) {
                        $sticky.each(function(){
                            offset = Math.max(offset, $sticky.outerHeight() + parseInt($sticky.css('top'), 10));
                        });
                    }
                    if ($wrapper.offset().top < $(window).scrollTop() + offset) {
                        goto_object($wrapper, 400, -1*offset - parseInt($wrapper.css('margin-top'), 10));
                    }
                }, 400);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to"))
        {
            goto_object($o, 500, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-'+((is_active)?'open':'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        var $o_i = $o.find(".expand-it-inner");
        if ($o_i.length) {
            //height = $o_i.outerHeight(true);
            height = $o_i[0].clientHeight;
        }
        else {
            height = 99999;
        }
        rules += " #"+id+".active { max-height: "+ height+"px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}

/* развернуть категорию */
$(document).on('click', '#categories i.fa-plus', function(){

	var li = $(this).closest('li');

	/* Свернем другие категории */
	$('#categories .js-categories-root > li:not(.collapsed) '+mc_nested_level).each(function(){
		var li_find = $(this).find('li[data-id='+li.attr('data-id')+']');
		if (li_find.length == 0)
		{
			var icon_minus = $(this).find('i.fa-minus');
			if (icon_minus.length > 0)
				icon_minus.trigger('click');
		}
	});

	var ol = li.find('ol:first');
	var icon_col = li.find('i.fa-minus:first');
	var icon_exp = li.find('i.fa-plus:first');
	var icon_spin = li.find('i.fa-spinner:first');

	icon_exp.hide();

	/* Подгрузим на ajax'e дерево */
	if (ol.length == 0)
	{
		icon_spin.show();
		print_lazy_result_subcategories(li, li.attr('data-id'), icon_col, icon_spin);
	}
	else
	{
		//ol.show();
		ol.slideDown(400);
		icon_col.show();
	}

	li.removeClass('collapsed');
});

/* свернуть категорию */
$(document).on('click', '#categories i.fa-minus', function(){
	var li = $(this).closest('li');
	var ol = li.find('ol:first');
	var icon_col = li.find('i.fa-minus:first');
	var icon_exp = li.find('i.fa-plus:first');

	//ol.hide();
	ol.slideUp(400);
	li.addClass('collapsed');
	icon_col.hide();
	icon_exp.show();
});

/* Функция для загрузки ветки дерева */
/* element - куда выводить, parent_id - какую категорию вывести */
function print_lazy_result_subcategories(element, parent_id, icon_col, icon_spin, path, select_category_id){
	if (element == undefined)
	{
		if (icon_spin != undefined)
			icon_spin.hide();
		if (icon_col != undefined)
			icon_col.show();
		return false;
	}

	if (parent_id == undefined)
		parent_id = 0;
	var result = [];
	var href = mc_main_module_url+'?lazy_load=1&parent_id='+parent_id;

	//console.log(element.find('ol:first').length);
	if (element.find('ol:first').length > 0)
	{
		if (icon_spin != undefined)
			icon_spin.hide();
		if (icon_col != undefined)
			icon_col.show();

		if (path != undefined && path.length > 0)
		{
			id = path[0];
			var _li = $('#categories li[data-id='+id+']');
			var _icon_col = _li.find('i.fa-minus:first');
			var _icon_exp = _li.find('i.fa-plus:first');
			var _icon_spin = _li.find('i.fa-spinner:first');

			var ol = _li.find('ol:first');

			_icon_exp.hide();
			_icon_spin.show();
			_li.removeClass('collapsed');
			ol.slideDown(400);
			//ol.show();

			path.shift();
			//console.log(path, id, 'ol.length = ', ol.length, _li, id, _icon_col, _icon_spin, path, select_category_id!=undefined?select_category_id:undefined);

			print_lazy_result_subcategories(_li, id, _icon_col, _icon_spin, path, select_category_id!=undefined?select_category_id:undefined);
		}
		else {
			if (select_category_id != undefined)
			{
				$('#categories li[data-id='+select_category_id+'] a:first').addClass('active');
				$('#categories li[data-id='+select_category_id+'] i.fa-plus:first').trigger('click');
			}
		}
	}
	else {
		$.get(href, function(data){
			if (data == undefined || data.length == 0)
				return false;
			var ol = $('<ol class="tcmenu"></ol>');
			for(var i=0 ; i < data.length ; i++){
				var li = $('<li class="tcmenu__item collapsed'+(data[i].folder?' havechild':'')+'" data-id="'+data[i].id+'" data-level="'+(parseInt(element.attr('data-level'))+1)+'"></li>');
				var li_inner = $('<div class="tcmenu__item-inner"></div>');
				li_inner.append('<a href="'+mc_products_module_url+data[i].url+'" class="tcmenu__link menu'+data[i].id+' menu-level'+(parseInt(element.attr('data-level'))+1)+'">'+data[i].title+'</a>'); /*  <span class="tcmenu__count">'+data[i].products_count+'</span>  */
				if (data[i].folder){
					var li_icon = $('<span class="tcmenu__icon"><svg class="icon"><use xlink:href="#icon-chevron-down"></use></svg></span>');
					li_icon.append('<i class="fa fa-minus" style="display: none;"></i>');
					li_icon.append('<i class="fa fa-plus" style="display: block;"></i>');
					li_icon.append('<i class="fa fa-spin fa fa-spinner" style="display: none;"></i>');
					li_inner.append(li_icon);
				}
				li.append(li_inner);
				ol.append(li);
			}
			ol.hide();
			element.append(ol);
			ol.slideDown(400);
			if (icon_spin != undefined)
				icon_spin.hide();
			if (icon_col != undefined)
				icon_col.show();

			if (path != undefined && path.length > 0)
			{
				id = path[0];
				var _li = $('#categories li[data-id='+id+']');
				var _icon_col = _li.find('i.fa-minus:first');
				var _icon_exp = _li.find('i.fa-plus:first');
				var _icon_spin = _li.find('i.fa-spinner:first');

				_icon_exp.hide();
				_icon_spin.show();
				_li.removeClass('collapsed');

				path.shift();
				print_lazy_result_subcategories(_li, id, _icon_col, _icon_spin, path, select_category_id!=undefined?select_category_id:undefined);
			}
			else {
				if (select_category_id != undefined)
				{
					$('#categories li[data-id='+select_category_id+'] a:first').addClass('active');
					$('#categories li[data-id='+select_category_id+'] i.fa-plus').trigger('click');
				}
			}
		});
	}
}