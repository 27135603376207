$(document).ready(function () {
	
	$('#weight').inputpicker({
		data: [
			{value: "2",    persons: "10 чел",  showtext: "10 чел (2 кг)",  weight: "2 кг"},
			{value: "2.5",  persons: "13 чел",  showtext: "13 чел (2.5 кг)",  weight: "2.5 кг"},
			{value: "3",    persons: "15 чел",  showtext: "15 чел (3 кг)",  weight: "3 кг"},
			{value: "3.5",  persons: "18 чел",  showtext: "18 чел (3.5 кг)",  weight: "3.5 кг"},
			{value: "4",    persons: "20 чел",  showtext: "20 чел (4 кг)",  weight: "4 кг"},
			{value: "4.5",  persons: "23 чел",  showtext: "23 чел (4.5 кг)",  weight: "4.5 кг"},
			{value: "5",    persons: "25 чел",  showtext: "25 чел (5 кг)",  weight: "5 кг"},
			{value: "5.5",  persons: "28 чел",  showtext: "28 чел (5.5 кг)",  weight: "5.5 кг"},
			{value: "6",    persons: "30 чел",  showtext: "30 чел (6 кг)",  weight: "6 кг"},
			{value: "6.5",  persons: "33 чел",  showtext: "33 чел (6.5 кг)",  weight: "6.5 кг"},
			{value: "7",    persons: "35 чел",  showtext: "35 чел (7 кг)",  weight: "7 кг"},
			{value: "7.5",  persons: "38 чел",  showtext: "38 чел (7.5 кг)",  weight: "7.5 кг"},
			{value: "8",    persons: "40 чел",  showtext: "40 чел (8 кг)",  weight: "8 кг"},
			{value: "8.5",  persons: "43 чел",  showtext: "43 чел (8.5 кг)",  weight: "8.5 кг"},
			{value: "9",    persons: "45 чел",  showtext: "45 чел (9 кг)",  weight: "9 кг"},
			{value: "9.5",  persons: "48 чел",  showtext: "48 чел (9.5 кг)",  weight: "9.5 кг"},
			{value: "10",   persons: "50 чел",  showtext: "50 чел (10 кг)",  weight: "10 кг"},
			{value: "10.5", persons: "53 чел",  showtext: "53 чел (10.5 кг)",  weight: "10.5 кг"},
			{value: "11",   persons: "55 чел",  showtext: "55 чел (11 кг)",  weight: "11 кг"},
			{value: "11.5", persons: "58 чел",  showtext: "58 чел (11.5 кг)",  weight: "11.5 кг"},
			{value: "12",   persons: "60 чел",  showtext: "60 чел (12 кг)",  weight: "12 кг"},
			{value: "12.5", persons: "63 чел",  showtext: "63 чел (12.5 кг)",  weight: "12.5 кг"},
			{value: "13",   persons: "65 чел",  showtext: "65 чел (13 кг)",  weight: "13 кг"},
			{value: "13.5", persons: "68 чел",  showtext: "68 чел (13.5 кг)",  weight: "13.5 кг"},
			{value: "14",   persons: "70 чел",  showtext: "70 чел (14 кг)",  weight: "14 кг"},
			{value: "14.5", persons: "73 чел",  showtext: "73 чел (14.5 кг)",  weight: "14.5 кг"},
			{value: "15",   persons: "75 чел",  showtext: "75 чел (15 кг)",  weight: "15 кг"},
			{value: "15.5", persons: "78 чел",  showtext: "78 чел (15.5 кг)",  weight: "15.5 кг"},
			{value: "16",   persons: "80 чел",  showtext: "80 чел (16 кг)",  weight: "16 кг"},
			{value: "16.5", persons: "83 чел",  showtext: "83 чел (16.5 кг)",  weight: "16.5 кг"},
			{value: "17",   persons: "85 чел",  showtext: "85 чел (17 кг)",  weight: "17 кг"},
			{value: "17.5", persons: "88 чел",  showtext: "88 чел (17.5 кг)",  weight: "17.5 кг"},
			{value: "18",   persons: "90 чел",  showtext: "90 чел (18 кг)",  weight: "18 кг"},
			{value: "18.5", persons: "93 чел",  showtext: "93 чел (18.5 кг)",  weight: "18.5 кг"},
			{value: "19",   persons: "95 чел",  showtext: "95 чел (19 кг)",  weight: "19 кг"},
			{value: "19.5", persons: "98 чел",  showtext: "98 чел (19.5 кг)",  weight: "19.5 кг"},
			{value: "20",   persons: "100 чел", showtext: "100 чел (20 кг)", weight: "20 кг"},
		],
		fields:[
			{name: 'persons', text:'Кол-во гостей'},
			{name: 'weight', text:'Вес торта'}
		],
		headShow: true,
		fieldText : 'showtext',
		fieldValue: 'value',
		width: '240px',
	});
	
	$('a[data-toggle="tooltip"]').tooltip({
		container: 'body'
	});
	calculate_main_calc();
});

$('#weight').change(function(){
	var val = parseFloat(this.value);
	var tiers_count = 0;
	$('select[name=main_tiers] option').each(function(index, opt){
		if (parseInt(opt.getAttribute('data-weight')) <= parseInt(val)) {
			if (opt.hasAttribute('disabled')) { opt.removeAttribute('disabled'); }
			this.text = opt.getAttribute('data-normal-text');
			tiers_count++;
		} else {
			if (!opt.hasAttribute('disabled')) { opt.setAttribute('disabled', 'disabled'); }
			this.text = opt.getAttribute('data-warning-text');
		}
	});
	for(var i=tiers_count+1;i<=5;i++)
	{
		$('#calc-filling'+i+'-group').hide();
	}
	$('select[name=main_tiers]').val(tiers_count);
	$('select[name=main_tiers]').trigger('change');
	$('select[name=main_tiers]').select2('destroy');
	$('select[name=main_tiers]').removeClass("selectbox-inited");
	selectbox_init($('select[name=main_tiers]'));
	calculate_main_calc();
});

$('div.calc select[name=main_tiers]').change(function(){
	var tiers_count = parseInt(this.value);
	for(var i=1; i<=tiers_count; i++) {
		$('div.calc #calc-filling'+i+'-group').show().closest('.filling-group-closest').addClass('filling-group-visible').removeClass('filling-group-hidden');
	}
	for(var i=tiers_count+1;i<=5;i++)
		$('div.calc #calc-filling'+i+'-group').hide().closest('.filling-group-closest').removeClass('filling-group-visible').addClass('filling-group-hidden');
	calculate_main_calc();
});

/*
$('div.calc select[name=count]').change(function(){
	var tiers_count = parseInt(this.value);
	for(var i=1; i<=tiers_count; i++) {
		$('div.calc #filling'+i+'-group').show();
	}
	for(var i=tiers_count+1;i<=5;i++) {
		$('div.calc #filling'+i+'-group').hide();
	}
	calculate_main_calc();
});
*/

$('.js-main-filling-select').click(function(e){
	e.preventDefault();
	var id = $(this).attr('data-id');
	var tier_num = $('#filling-selector-main input[name=tier_num]').val();
	$('#filling-selector-main input[name=filling_id]').val(id);
	$('div.calc #calc-filling' + tier_num).attr('data-filling-id', id);
	$('div.calc #calc-filling' + tier_num + '-group .filling-image-preview').html('<img src="'+$(this).attr('data-small-image')+'"/>');
	$('div.calc #calc-filling' + tier_num + '-group .filling-name-selected').html($(this).attr('data-name'));
	$('div.calc #calc-filling' + tier_num + '-group').addClass("active");
	$('#filling-selector-main').modal('hide');
	$('div.calc #calc-filling'+tier_num).tooltipster('content', $('#filling'+tier_num).data('title-alt'));
	calculate_main_calc();
});

$('div.calc .js-filling-add').click(function(e){
	e.preventDefault();
	var $group = $(this).closest('.filling-group');
	$('#filling-selector-main input[name=tier_num]').val($group.find(".btn-select-filling").attr('data-tier-num'));
	$('#filling-selector-main input[name=filling_id]').val($group.find(".btn-select-filling").attr('data-filling-id'));
	$('#filling-selector-main').modal();
});

$('div.calc .js-filling-remove').click(function(e){
	e.preventDefault();
	var $group = $(this).closest('.filling-group');
	$group.find('div.filling-image-preview').html('');
	$group.find('div.filling-name-selected').html('');
	$group.find('button.btn-select-filling').attr('data-filling-id', '0');
	$group.removeClass("active");
	calculate_main_calc();
});

$('div.calc select[name=oform]').change(function(){
	var option = $(this).find('option:selected');
	if (option.length > 0 && option.attr('data-show-flowers') == "1") {
		$('#flowers-group').show();
	} else {
		$('#flowers-group').hide();
	}
	if (option.length > 0 && option.attr('data-show-figures') == "1") {
		$('#figures-group').show();
	} else {
		$('#figures-group').hide();
	}
	calculate_main_calc();
})

$('div.calc select[name=pokrytie], div.calc select[name=form], div.calc select[name=weight], div.calc select[name=flowers], div.calc select[name=figures]').change(function(){
	calculate_main_calc();
});

function calculate_main_calc(){
	var weight = parseFloat($('div.calc #weight').val());//parseFloat($('div.calc select[name=weight] option:selected').val());
	var pokrytie_price = parseFloat($('div.calc select[name=pokrytie] option:selected').attr('data-price'));
	var form_price = parseFloat($('div.calc select[name=form] option:selected').attr('data-price'));
	var tiers_count = parseInt($('div.calc select[name=main_tiers] option:selected').val());
	if (isNaN(tiers_count)) {
		tiers_count = 1;
	}
	
	//Calc price per kg
	var price_per_kg = pokrytie_price + form_price;
	var total_price_per_kg = price_per_kg * weight;
	
	var oform_option = $('div.calc select[name=oform] option:selected');
	if (oform_option.length > 0 && oform_option.attr('data-multi-kg') == "1") {
		total_price_per_kg += parseFloat(oform_option.attr('data-price')) * weight;
	}
	if (oform_option.length > 0 && oform_option.attr('data-multi-tier') == "1") {
		total_price_per_kg += parseFloat(oform_option.attr('data-price')) * tiers_count;
	}
	
	if (oform_option.length > 0 && oform_option.attr('data-show-flowers') == "1") {
		var flowers_count = parseInt($('div.calc select[name=flowers] option:selected').val());
		if (isNaN(flowers_count)) {
			flowers_count = 0;
		}
		total_price_per_kg += parseFloat(oform_option.attr('data-price')) * flowers_count;
	}
	
	if (oform_option.length > 0 && oform_option.attr('data-show-figures') == "1") {
		var figures_count = parseInt($('div.calc select[name=figures] option:selected').val());
		if (isNaN(figures_count)) {
			figures_count = 0;
		}
		total_price_per_kg += parseFloat(oform_option.attr('data-price')) * figures_count;
	}
	
	if (isNaN(total_price_per_kg)) {
		$('div.calc div.calc__price-value').html('0 руб.');
	} else {
		$('div.calc div.calc__price-value').html(Math.round(total_price_per_kg, 0) + ' руб.');
	}
}