$('.compare-delete').click(function(){
	var href = $(this).attr('href');
	$.get(href, function(data){
		if (data.success){
			location.href = data.compare_href;
		}
	});
	return false;
});

$('#show-different').click(function(){
	$('#table-parameters tr.all-row').hide();
	$('#table-parameters tr.different-row').show();
	$('#show-all').removeClass('active');
	$('#show-different').addClass('active');
	return false;
});

$('#show-all').click(function(){
	$('#table-parameters tr.different-row').hide();
	$('#table-parameters tr.all-row').show();
	$('#show-all').addClass('active');
	$('#show-different').removeClass('active');
	return false;
});