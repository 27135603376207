/* Проверка переменной является ли она массивом */
function is_array(object){
	if (Object.prototype.toString.call(object) === '[object Array]')
		return true;
	return false;
}

// Проверка, что элемент находится в видимой области экрана
function isInViewport(elem) {
  let elemTop = elem.offset().top;
  let elemBottom = elemTop + elem.height();
  let viewportTop = window.scrollY;
  let viewportBottom = viewportTop + window.innerHeight;
  return elemBottom > viewportTop && elemTop < viewportBottom;
};