$(document).ready(function () {
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect: 'none',
		nextEffect: 'none',
		helpers: {
			title: {
				type: 'outside'
			},
			thumbs: {
				width: 50,
				height: 50
			},
			overlay: {
				locked: false
			}
		}
	});
});

/* MAIN CALC UPLOAD IMAGES */

var imgListCalc = $('#img-list-calc');
var fileInputCalc = $('#uploadImageField-calc');

fileInputCalc.damnUploader({
	url: mc_calc_upload_images_module_url,
	fieldName:  'uploaded-images-calc',
	object_id: $('input[name=temp_id]:first').val(),
	limit: 3,
	onSelect: function(file) {
		$('.upload-anim-calc').removeClass('hidden');
		var addedItem = addFileToQueueCalc(file);
		fileInputCalc.damnUploader('startUploadItem', addedItem.queueId);
		return false;
	},
	onAllComplete: function(){
		$('.upload-anim-calc').addClass('hidden');

		fileInputCalc.damnUploader('cancelAll');
		imgListCalc.html('');

		$.get(mc_calc_get_temp_images_module_url + '&temp_id=' + $('input[name=temp_id]:first').val(), function(data) {
			if (data.success) {
				$('div.product-calc-add-images div.object-images').html(data.data);

				if ($('.list1-calc li').length >= 3) {
					$('.btn-add-calc-image').hide();
				} else {
					$('.btn-add-calc-image').show();
				}
			}
		});
		return false;
	},
});

function updateProgressCallback(bar, value) {
	bar.css('width', value+'%');
}

function addFileToQueueCalc(file) {

	// Создаем элемент li и помещаем в него название, миниатюру и progress bar
	var li = $('<li/>').appendTo(imgListCalc);
	var title = $('<div/>').text(file.name+' ').appendTo(li);
	var cancelButton = $('<a/>').attr({
		href: '#cancel',
		title: 'отменить'
	}).text('X').appendTo(title);

	// Если браузер поддерживает выбор файлов (иначе передается специальный параметр fake,
	// обозначающий, что переданный параметр на самом деле лишь имитация настоящего File)
	if(!file.fake)
	{
		// Отсеиваем не картинки
		var imageType = /image.*/;
		if (!file.type.match(imageType)) {
			return true;
		}

		// Добавляем картинку и прогрессбар в текущий элемент списка
		var img = $('<img/>').appendTo(li);

		var pBar = $('<div class="progress progress-striped active"></div>').appendTo(li);
		var ppBar = $('<div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>').appendTo(pBar);

		// Создаем объект FileReader и по завершении чтения файла, отображаем миниатюру и обновляем
		// инфу обо всех файлах (только в браузерах, поддерживающих FileReader)
		if($.support.fileReading) {
			var reader = new FileReader();
			reader.onload = (function(aImg) {
				return function(e) {
					aImg.attr('src', e.target.result);
					aImg.attr('width', 150);
				};
			})(img);
			reader.readAsDataURL(file);
		}
	}

	// Создаем объект загрузки
	var uploadItemCallback = {
		file: file,
		queueId: 0,
		onProgress: function(percents) {
			updateProgressCallback(ppBar, percents);
		},
		onComplete: function(successfully, data, errorCode) {
			pBar.removeClass('active');
		},
	};

	// помещаем его в очередь
	var queueId = fileInputCalc.damnUploader('addItem', uploadItemCallback);
	uploadItemCallback.queueId = queueId;

	// обработчик нажатия ссылки "отмена"
	cancelButton.click(function() {
		fileInputCalc.damnUploader('cancel', queueId);
		li.remove();
		return false;
	});

	return uploadItemCallback;
}

$('div.object-images').on("click", "a.delete_image", function(){
	var item = $(this).closest('li');
	var href = $(this).attr('href');
	$.get(href, function(data) {
		if (data)
		{
			item.fadeOut('fast').remove();
			if ($('.list1-calc li').length >= 3) {
				$('.btn-add-calc-image').hide();
			} else {
				$('.btn-add-calc-image').show();
			}
		}
	});
	return false;
});

/* MAIN CALC UPLOAD IMAGES (THE END) */