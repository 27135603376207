$(document).ready(function () {
	$('ul.list').on('change', 'li.listitem select[name=variant_id]', function () {
		var li = $(this).closest('li');
		var option = $(this).find('option:selected');
		li.find('span.in-stock').hide();
		li.find('span.out-of-stock').hide();
		li.find('span.to-order').hide();

		var stock = parseInt(option.attr('data-stock'));
		if (stock > 0) {
			li.find('span.in-stock').show();
			li.find('a.list-buy').show();
			li.find('a.buy-one-click').show();
		}
		if (stock == 0) {
			li.find('span.out-of-stock').show();
			li.find('a.list-buy').hide();
			li.find('a.buy-one-click').hide();
		}
		if (stock < 0) {
			li.find('span.to-order').show();
			li.find('a.list-buy').show();
			li.find('a.buy-one-click').show();
		}
		var price = parseFloat(option.attr('data-price'));
		var price_old = parseFloat(option.attr('data-price-old'));
		if (price > 0)
			li.find('div.list-price').show().html(option.attr('data-price-convert') + ' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.list-price').hide();
		if (price_old > 0)
			li.find('div.list-old-price').show().html(option.attr('data-price-old-convert') + ' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.list-old-price').hide();
		if (price < mc_cart_order_min_price || stock == 0)
			li.find('a.buy-one-click').hide();
		else
			li.find('a.buy-one-click').show();
	});
	$('ul.plitka').on('change', 'li.plitka-item select[name=variant_id]', function () {
		var li = $(this).closest('li');
		var option = $(this).find('option:selected');
		li.find('div.plitka-status span').each(function () {
			$(this).hide();
		});
		var stock = parseInt(option.attr('data-stock'));
		if (stock > 0) {
			li.find('span.in-stock').show();
			li.find('a.plitka-buy').show();
		}
		if (stock == 0) {
			li.find('span.out-of-stock').show();
			li.find('a.plitka-buy').hide();
		}
		if (stock < 0) {
			li.find('span.to-order').show();
			li.find('a.plitka-buy').show();
		}
		var price = parseFloat(option.attr('data-price'));
		var price_old = parseFloat(option.attr('data-price-old'));
		if (price > 0)
			li.find('div.plitka-price').show().html(option.attr('data-price-convert') + ' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.plitka-price').hide();
		if (price_old > 0)
			li.find('div.plitka-old-price').show().html(option.attr('data-price-old-convert') + ' <span>' + mc_main_currency_sign + '</span>');
		else
			li.find('div.plitka-old-price').hide();
	});
});