$(document).ready(function(){
	$("#phone").mask("+7 (999) 999-99-99");
});

$('#person input.required').focusout(function(){
	var type = $(this).attr('data-type');
	if (type.length == 0)
		type = "text";
	var show_error = false;
	switch(type){
		case "text":
			$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.form-group').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.form-group').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "phone":
			$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
				if ($(this).mask().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.form-group').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.form-group').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "phone_simple":
			$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
				if ($(this).mask().length == 0)
					show_error = true;
			});
			if (show_error)
				$(this).closest('div.form-group').find('.fa-check').hide();
			else
				$(this).closest('div.form-group').find('.fa-check').show();
			break;
		case "number":
			$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					show_error = true;
			});
			
			if (show_error)
			{
				$(this).closest('div.form-group').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.form-group').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "email":
			$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
				if (!isValidEmailAddress($(this).val()))
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.form-group').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.form-group').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
	}
});

$('#register_form').submit(function(){
	var result = true;

	$('#person input.required').each(function(){
		var type = $(this).attr('data-type');
		if (type.length == 0)
			type = "text";
		var show_error = false;
		switch(type){
			case "text":
				$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0)
						show_error = true;
				});
				if (show_error)
				{
					$(this).closest('div.form-group').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					$(this).focus();
					result = false;
					return false;
				}
				break;
			case "phone":
				$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
					if ($(this).mask().length == 0)
						show_error = true;
				});
				if (show_error)
				{
					$(this).closest('div.form-group').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					$(this).focus();
					result = false;
					return false;
				}
				break;
			case "phone_simple":
				$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
					if ($(this).mask().length == 0)
						show_error = true;
				});
				if (show_error)
				{
					$('#register-phone-required').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					$(this).focus();
					result = false;
					return false;
				}
				break;
			case "number":
				$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
						show_error = true;
				});
				
				if (show_error)
				{
					$(this).closest('div.form-group').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					$(this).focus();
					result = false;
					return false;
				}
				break;
			case "email":
				$(this).closest('div.form-group').find('input.required, textarea.required').each(function(){
					if (!isValidEmailAddress($(this).val()))
						show_error = true;
				});
				if (show_error)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					$(this).focus();
					result = false;
					return false;
				}
				break;
		}
	});

	return result;
});

function isValidEmailAddress(emailAddress) {
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	return pattern.test(emailAddress);
}