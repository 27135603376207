if (mc_module_module == 'ProductController') {
	$(document).ready(function(){

		$(document).on("click", ".js-product-goto-tab", function (e) {
			var $selector = $(this).attr("href");
			var $o = $($selector);
			if ($o.length) {
				e.preventDefault();
				goto_object($(".product__tabs"));
				$(".js-tabs a[href='"+$selector+"']").trigger("click");
			}
		});
		
		var variant_name = "";
		var variant_price = "";
		if ($('input[name=variant_id]:checked').length > 0)
			var current_variant = $('input[name=variant_id]:checked');
		else
			var current_variant = $('input[name=variant_id][checked]');
		if (current_variant.length > 0) {
			variant_name = current_variant.attr('data-variant-name');
			variant_price = current_variant.attr('data-price');
		}
		dataLayer.push({
			'ecommerce': {
				'detail': {
					'products': [
						{
							'id' : mc_product_id,
							'name' : mc_product_name,
							'price' : variant_price,
							'category' : mc_category_name,
							'variant' : variant_name
						}
					]
				}
			}
		});
		
		autoPlayYouTubeModal();
	});
}

//FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
function autoPlayYouTubeModal() {
  var trigger = $("body").find('[data-toggle="modal"]');
  trigger.click(function () {
	  var theModal = $(this).data("target"),
		  videoSRC = $(this).attr("data-theVideo"),
		  videoSRCauto = videoSRC + "?autoplay=1";
	  $(theModal + ' iframe').attr('src', videoSRCauto);
	  $(theModal + ' button.close').click(function () {
		  $(theModal + ' iframe').attr('src', videoSRC);
	  });
  });
}

$('#videoModal').on('shown.bs.modal', function(e) {
	$(window).trigger('resize');
});

$('#videoModal').on('hidden.bs.modal', function(e) {
	$('#videoModal iframe').attr('src', '');
});

if (typeof mc_product_id !== "undefined") {

	if (!mc_block_delivery_price_exists) {
		function update_block_delivery_price(){
			var total = parseFloat($('#content div.buy-price').attr('data-value'));
			var delivery_price = 0;
			if (total < 5000)
				delivery_price = mc_delivery_moscow_price;
			if (5000 <= total && total < 100000)
				delivery_price = mc_delivery_moscow_price2;
			if (delivery_price > 0)
				$('#show-modal-delivery-price-modal-form').html(delivery_price + ' ' + mc_main_currency_sign);
			else
				$('#show-modal-delivery-price-modal-form').html('Бесплатно');
		}
	}

	function ProductGallery() {
		var self = this,
			gallery, container, container_width, container_width_tmp, count, scroll_count, visible_count, visible_count_tmp, is_running = false,
			el_width = 67,
			el_width_real, is_centered, padding = 36;
		this.init = function() {
			gallery = $(".img_gallery_small-carousel");
			if (!gallery.length)
			{
				gallery = $(".img_gallery_small");
				var obBigGalleryDetail = $(".img_gallery");
				$("> li", gallery).on("mouseenter click", function() {
					var a = $(this);
					var index = a.closest("li").index();
					$("li.selected", gallery).removeClass("selected");
					$("li.selected", obBigGalleryDetail).removeClass("selected");
					a.closest("li").addClass("selected");
					$("li:eq(" + index + ")", obBigGalleryDetail).addClass("selected");
				});
				return;
			}
			is_centered = $(".img_part_small_centered").size() > 0;
			container = gallery.parent();
			container_width = container.width();
			if (!is_centered) {
				container_width = 201 + padding;
			}
			count = $(">li", gallery).size();
			visible_count = Math.floor((container_width - padding) / el_width);
			scroll_count = 3;
			this.run();
			this.showImg();
		}
		this.run = function() {
			if (count > visible_count) {
				gallery.jcarousel({
					scroll: scroll_count,
					visible: visible_count,
					animationStepCallback: function() {
						//imgLazyLoad(gallery);
					}
				});
				el_width_real = $(">li", gallery).width();
				gallery.css({
					maxWidth: 'none'
				});
				is_running = true;
			}
		}
		this.recalc = function() {
			container_width_tmp = container.width();
			if (!is_centered) {
				container_width_tmp = 201 + padding;
			}
			if (Math.abs(container_width_tmp - container_width) > 5) {
				container_width = container_width_tmp;
				visible_count_tmp = Math.floor((container_width_tmp - padding) / el_width);
				if (visible_count_tmp != visible_count) {
					visible_count = visible_count_tmp;
					this.run();
				}
			}
		}
		this.showImg = function() {
			var obBigGalleryDetail = $(".img_gallery");
			$("> li a.image", gallery).click(function() {
				var a = $(this);
				var index = a.closest("li").index();
				$("li.selected", gallery).removeClass("selected");
				$("li.selected", obBigGalleryDetail).removeClass("selected");
				a.closest("li").addClass("selected");
				$("li:eq(" + index + ")", obBigGalleryDetail).addClass("selected");
				//imgLazyLoad(obBigGalleryDetail);
				$(window).resize();
				if (is_running) {
					setTimeout(function() {
						var sdvig = Math.abs(Math.round(parseInt(gallery.css("left")) / el_width_real));
						var view_index = index - sdvig;
						if (view_index == 0 && sdvig > 0) {
							gallery.jcarousel('scroll', index);
						} else if (view_index == visible_count - 1) {
							gallery.jcarousel('scroll', sdvig + 2);
						}
					}, 100);
				}
				return false;
			});
		}
		$(window).resize(function() {
			self.recalc();
		});
		return this.init();
	}

	if (mc_user_id.length > 0) {
		$('#favorite, .js-add-to-favorites').click(function(){
			$.ajax({
				url: mc_config_root_url + "/ajax/get_data.php",
				type: "POST",
				data: {
					object: 'products',
					mode: 'product-togglefavorite',
					pid : mc_product_id,
					uid : mc_user_id,
					session_id: mc_session_id
				},
				success: function(data){
					if (data.success)
					{
						var i = $('#favorite i');
						var s = $('#favorite span');
						i.removeClass('fa-heart').removeClass('fa-heart-o');
						if (data.data){
							i.addClass('fa-heart');
							s.html('Удалить из избранного');
						}
						else{
							i.addClass('fa-heart-o');
							s.html('Добавить в избранное');
						}
						if (data.count > 0)
						{
							$('#favorites-informer').closest('.favorite-products').show();
							$('#favorites-informer').html('Избранное ('+data.count+')');
						}
						else
							$('#favorites-informer').closest('.favorite-products').hide();
					}
				}
			});
			return false;
		});
	}

	$('#show-modal-delivery-price-modal-form').click(function(){
		$('#delivery-price-modal-form').modal();
		return false;
	});

	$('#show-modal-payments-methods-modal-form').click(function(){
		$('#payments-methods-modal-form').modal();
		return false;
	});

	$('#buy button[data-type=plus]').click(function(){
		var input = $('#buy input[name=amount]');
		var max_stock = input.data('stock');
		if (input.length > 0)
		{
			var value = parseInt(input.val()) + 1;
			if (value > max_stock)
				value = max_stock;
			input.val(value);
			calculate_price();
		}
	});

	$('#buy button[data-type=minus]').click(function(){
		var input = $('#buy input[name=amount]');
		if (input.length > 0)
		{
			var min_amount = parseInt(input.attr('data-min-amount'));
			var value = parseInt(input.val()) - 1;
			if (value <= min_amount)
				value = min_amount;
			input.val(value);
			calculate_price();
		}
	});

	$('#buy input[name=amount]').bind('change keyup input click', function(){
		if (this.value.match(/[^0-9]/g)) {
			this.value = this.value.replace(/[^0-9]/g, '');
		}
		var min_amount = parseInt(this.attr('data-min-amount'));
		if (this.value <= min_amount)
			this.value = min_amount;
		calculate_price();
	});

	$('.product-modifier button[data-type=minus]').click(function(){
		var i = $(this).closest('.input-group').find('input');
		if (i.val() > i.data('min'))
			i.val(parseInt(i.val()) - 1);
		calculate_price();
	});

	$('.product-modifier button[data-type=plus]').click(function(){
		var i = $(this).closest('.input-group').find('input');
		if (i.val() < i.data('max'))
			i.val(parseInt(i.val()) + 1);
		calculate_price();
	});

	$('.product-modifier input[name^=modificators_count]').bind('change keyup input click', function(){
		if (this.value.match(/[^0-9]/g)) {
			this.value = this.value.replace(/[^0-9]/g, '');
		}
		var min = parseInt($(this).data('min'));
		var max = parseInt($(this).data('max'));
		if (this.value < min)
			this.value = min;
		if (this.value > max)
			this.value = max;
		calculate_price();
	});

	$('#dop-products .doplistitem input[name^=dop_products], #relatedvariants-products .doplistitem input[name^=dop_products]').change(function(){
		$(this).closest('li').toggleClass('active');
		calculate_price();
		return false;
	});

	$('#dop-products select[name=dop_variant_id]').change(function(){
		var $selectedVariant = $(this).find('option:selected');
		var dop_price = parseFloat($selectedVariant.data('price'));
		var oldPrice = parseFloat($selectedVariant.data('price-old'));
		console.log(oldPrice);

		var $el = $(this).closest('.doplistitem');

		$el.find(".doplistitem-price").html(moneyFormat(dop_price) + '&nbsp' + mc_main_currency_sign);

		if (oldPrice)
			$el.find(".list-old-price").html(moneyFormat(oldPrice) + '&nbsp' + mc_main_currency_sign);

		calculate_price();
		return false;
	});

	function calculate_price(){
		$('#buy div.buy-price').hide();
		/*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ*//*var price = parseFloat($('select[name=variant_id] option:selected').attr('data-price'));*/
		/*ЕСЛИ ВАРИАНТЫ - РАДИОБАТТОНЫ*/
		if ($('input[name=variant_id]:checked').length > 0)
			var price = parseFloat($('input[name=variant_id]:checked').attr('data-price'));
		else
			var price = parseFloat($('input[name=variant_id][checked]').attr('data-price'));
		var amount = 1;
		if ($('#amount').length > 0) {
			var amount_input = $('#amount');
			if (amount_input.length > 0)
				amount = amount_input.val();
		}
		/*if ($('#buy input[name=amount]').length > 0) {
			var amount_input = $('#buy input[name=amount]');
			if (amount_input.length > 0)
				amount = amount_input.val();
		}
		if ($('#buy select[name=amount] option:selected').length > 0) {
			var amount_select = $('#buy select[name=amount] option:selected');
			if (amount_select.length > 0)
				amount = amount_select.val();
		}*/
		var total_price = price;
		
		var is_variable_amount = 0;
		if ($('#buy div.buy-price').attr('data-variable-amount') == "1") {
			is_variable_amount = 1;
		}
		
		var flag3 = 0;
		if ($('#buy div.buy-price').attr('data-flag3') == "1") {
			flag3 = 1;
		}
		
		var flag5 = 0;
		if ($('#buy div.buy-price').attr('data-flag5') == "1") {
			flag5 = 1;
		}
		
		var field4 = 0;
		if (flag5) {
			field4 = $('#buy div.buy-price').attr('data-field4');
		}

		var complect_count = amount;

		$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
			var obj = $(this);
			var obj_type = obj.data('type');
			var obj_value = parseFloat(obj.data('value'));
			var obj_multi_apply = obj.data('multi-apply');
			if (obj_multi_apply == 0 && amount > 1)	//skip processing modificator
				return true;
			var obj_multi_buy = obj.data('multi-buy');
			var obj_multi_buy_value = 1;
			if (obj_multi_buy == 1)
				obj_multi_buy_value = parseInt($('input[name=modificators_count_'+obj.val()+']').val());

			if (obj_type == 'plus_fix_sum')
				total_price += obj_value * obj_multi_buy_value;
			if (obj_type == 'minus_fix_sum')
				total_price -= obj_value * obj_multi_buy_value;
			if (obj_type == 'plus_percent')
				total_price += price * obj_value * obj_multi_buy_value / 100;
			if (obj_type == 'minus_percent')
				total_price -= price * obj_value * obj_multi_buy_value / 100;
		});

		var total_price_variant_for_multi = total_price;

		$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
			var obj = $(this);
			var obj_type = obj.data('type');
			var obj_value = parseFloat(obj.data('value'));
			var obj_multi_apply = obj.data('multi-apply');
			if (!(obj_multi_apply == 0 && amount > 1))	//skip processing modificator
				return true;
			var obj_multi_buy = obj.data('multi-buy');
			var obj_multi_buy_value = 1;
			if (obj_multi_buy == 1)
				obj_multi_buy_value = parseInt($('input[name=modificators_count_'+obj.val()+']').val());

			if (obj_type == 'plus_fix_sum')
				total_price += obj_value * obj_multi_buy_value;
			if (obj_type == 'minus_fix_sum')
				total_price -= obj_value * obj_multi_buy_value;
			if (obj_type == 'plus_percent')
				total_price += price * obj_value * obj_multi_buy_value / 100;
			if (obj_type == 'minus_percent')
				total_price -= price * obj_value * obj_multi_buy_value / 100;
		});

		var additional_sum = total_price - total_price_variant_for_multi;

		/* ПРИПЛЮСУЕМ СТОИМОСТЬ ДОП КОМПЛЕКЦТАЦИЙ */
		$('#dop-products .doplistitem input[name^=dop_products]:checked, #relatedvariants-products .doplistitem input[name^=dop_products]:checked').each(function(){
			var dop_price = parseFloat($(this).closest('li').find('select[name=dop_variant_id] option:selected').attr('data-price'));
			total_price += dop_price;
			complect_count++;
		});

		$('#complect-count').html(complect_count);

		total_price_formatted = String(total_price.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		
		if (flag3 && !flag5)
			total_price_formatted = "от " + total_price_formatted;
		
		if (is_variable_amount) {
			$('#buy div.buy-price').html(total_price_formatted + ' ' + mc_main_currency_sign + '/кг');
		} else {
			if (typeof $('#buy input[name=amount]').attr('data-min-amount') != 'undefined')
				$('#buy div.buy-price').html(total_price_formatted + ' ' + mc_main_currency_sign + '/шт, мин. заказ ' + parseInt($('#buy input[name=amount]').attr('data-min-amount')) + ' шт');
			else
				$('#buy div.buy-price').html(total_price_formatted + ' ' + mc_main_currency_sign + '/шт');
			$('#buy div.buy-price').show();
		}
		$('#content div.buy-price-copy').html(total_price_formatted + ' ' + mc_main_currency_sign);
		$('#buy div.buy-price').attr('data-value', total_price.toFixed());

		if (!mc_block_delivery_price_exists) {
		update_block_delivery_price();
		}

		total_price = total_price_variant_for_multi * amount + additional_sum;
		if (flag5 && parseFloat(field4) > 0)
			total_price += parseFloat(field4);
		total_price_formatted = String(total_price.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		
		if (flag5)
			total_price_formatted = total_price_formatted + ' ' + mc_main_currency_sign + ' за ' + amount.toString() + ' кг с оформлением';
		else
			if (flag3)
				total_price_formatted = 'от ' + total_price_formatted + ' ' + mc_main_currency_sign + ' за ' + amount.toString() + ' кг без оформления';
		
		$('#buy div.buy-price-with-amount').html(total_price_formatted /*+ ' ' + mc_main_currency_sign*/ );
		if (is_variable_amount)
			$('#buy div.buy-price').hide();
	}

	$('.product-modifier input, .product-modifier select').change(function(){
		var checked = $(this).prop('checked');
		var d = $(this).closest('div');
		if (checked){
			d.find('input[name^=modificators_count]').prop('disabled', false);
			d.find('button[data-type=minus]').prop('disabled', false);
			d.find('button[data-type=plus]').prop('disabled', false);
		}
		else{
			d.find('input[name^=modificators_count]').prop('disabled', true);
			d.find('button[data-type=minus]').prop('disabled', true);
			d.find('button[data-type=plus]').prop('disabled', true);
		}

		calculate_price();
	});
	
	var all_weight_amount = [];
	var weight_amount = [];
	
	all_weight_amount.push({value: "1.5",    persons: "7 чел",  showtext: "7 чел (1.5 кг)",  weight: "1.5 кг"});
	all_weight_amount.push({value: "2",    persons: "10 чел",  showtext: "10 чел (2 кг)",  weight: "2 кг"});
	all_weight_amount.push({value: "2.5",  persons: "13 чел",  showtext: "13 чел (2.5 кг)",  weight: "2.5 кг"});
	all_weight_amount.push({value: "3",    persons: "15 чел",  showtext: "15 чел (3 кг)",  weight: "3 кг"});
	all_weight_amount.push({value: "3.5",  persons: "18 чел",  showtext: "18 чел (3.5 кг)",  weight: "3.5 кг"});
	all_weight_amount.push({value: "4",    persons: "20 чел",  showtext: "20 чел (4 кг)",  weight: "4 кг"});
	all_weight_amount.push({value: "4.5",  persons: "23 чел",  showtext: "23 чел (4.5 кг)",  weight: "4.5 кг"});
	all_weight_amount.push({value: "5",    persons: "25 чел",  showtext: "25 чел (5 кг)",  weight: "5 кг"});
	all_weight_amount.push({value: "5.5",  persons: "28 чел",  showtext: "28 чел (5.5 кг)",  weight: "5.5 кг"});
	all_weight_amount.push({value: "6",    persons: "30 чел",  showtext: "30 чел (6 кг)",  weight: "6 кг"});
	all_weight_amount.push({value: "6.5",  persons: "33 чел",  showtext: "33 чел (6.5 кг)",  weight: "6.5 кг"});
	all_weight_amount.push({value: "7",    persons: "35 чел",  showtext: "35 чел (7 кг)",  weight: "7 кг"});
	all_weight_amount.push({value: "7.5",  persons: "38 чел",  showtext: "38 чел (7.5 кг)",  weight: "7.5 кг"});
	all_weight_amount.push({value: "8",    persons: "40 чел",  showtext: "40 чел (8 кг)",  weight: "8 кг"});
	all_weight_amount.push({value: "8.5",  persons: "43 чел",  showtext: "43 чел (8.5 кг)",  weight: "8.5 кг"});
	all_weight_amount.push({value: "9",    persons: "45 чел",  showtext: "45 чел (9 кг)",  weight: "9 кг"});
	all_weight_amount.push({value: "9.5",  persons: "48 чел",  showtext: "48 чел (9.5 кг)",  weight: "9.5 кг"});
	all_weight_amount.push({value: "10",   persons: "50 чел",  showtext: "50 чел (10 кг)",  weight: "10 кг"});
	all_weight_amount.push({value: "10.5", persons: "53 чел",  showtext: "53 чел (10.5 кг)",  weight: "10.5 кг"});
	all_weight_amount.push({value: "11",   persons: "55 чел",  showtext: "55 чел (11 кг)",  weight: "11 кг"});
	all_weight_amount.push({value: "11.5", persons: "58 чел",  showtext: "58 чел (11.5 кг)",  weight: "11.5 кг"});
	all_weight_amount.push({value: "12",   persons: "60 чел",  showtext: "60 чел (12 кг)",  weight: "12 кг"});
	all_weight_amount.push({value: "12.5", persons: "63 чел",  showtext: "63 чел (12.5 кг)",  weight: "12.5 кг"});
	all_weight_amount.push({value: "13",   persons: "65 чел",  showtext: "65 чел (13 кг)",  weight: "13 кг"});
	all_weight_amount.push({value: "13.5", persons: "68 чел",  showtext: "68 чел (13.5 кг)",  weight: "13.5 кг"});
	all_weight_amount.push({value: "14",   persons: "70 чел",  showtext: "70 чел (14 кг)",  weight: "14 кг"});
	all_weight_amount.push({value: "14.5", persons: "73 чел",  showtext: "73 чел (14.5 кг)",  weight: "14.5 кг"});
	all_weight_amount.push({value: "15",   persons: "75 чел",  showtext: "75 чел (15 кг)",  weight: "15 кг"});
	all_weight_amount.push({value: "15.5", persons: "78 чел",  showtext: "78 чел (15.5 кг)",  weight: "15.5 кг"});
	all_weight_amount.push({value: "16",   persons: "80 чел",  showtext: "80 чел (16 кг)",  weight: "16 кг"});
	all_weight_amount.push({value: "16.5", persons: "83 чел",  showtext: "83 чел (16.5 кг)",  weight: "16.5 кг"});
	all_weight_amount.push({value: "17",   persons: "85 чел",  showtext: "85 чел (17 кг)",  weight: "17 кг"});
	all_weight_amount.push({value: "17.5", persons: "88 чел",  showtext: "88 чел (17.5 кг)",  weight: "17.5 кг"});
	all_weight_amount.push({value: "18",   persons: "90 чел",  showtext: "90 чел (18 кг)",  weight: "18 кг"});
	all_weight_amount.push({value: "18.5", persons: "93 чел",  showtext: "93 чел (18.5 кг)",  weight: "18.5 кг"});
	all_weight_amount.push({value: "19",   persons: "95 чел",  showtext: "95 чел (19 кг)",  weight: "19 кг"});
	all_weight_amount.push({value: "19.5", persons: "98 чел",  showtext: "98 чел (19.5 кг)",  weight: "19.5 кг"});
	all_weight_amount.push({value: "20",   persons: "100 чел", showtext: "100 чел (20 кг)", weight: "20 кг"});
	
	all_weight_amount.forEach((element) => {
		if (mc_product_min_amount <= element.value && element.value <= mc_product_max_amount)
			weight_amount.push(element);
	});

    $('#amount').inputpicker({
        data: weight_amount,
        fields:[
            {name: 'persons', text:'Кол-во гостей'},
            {name: 'weight', text:'Вес торта'}
        ],
        headShow: true,
        fieldText : 'showtext',
        fieldValue: 'value'
    });

	$(document).ready(function(){

		//var productGallery = new ProductGallery;
		
		$('[data-toggle="popover"]').popover();

		calculate_price();

		if (!mc_block_delivery_price_exists) {
			update_block_delivery_price();
		}

		$(".fancybox").fancybox({
			autoResize: true,
			autoCenter: true,
			prevEffect	: 'none',
			nextEffect	: 'none',
			helpers	: {
				title	: {
					type: 'outside'
				},
				thumbs	: {
					width	: 50,
					height	: 50
				},
				overlay : {
				   locked     : false
				}
			}
		});

		/*ЕСЛИ ВАРИАНТЫ - РАДИОБАТТОНЫ */$('input[name=variant_id]').change(function(){
		/*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ *//*$('select[name=variant_id]').change(function()/*/
			$('#no_stock').hide();

			$('#variants div.radio.active').removeClass('active');
			$(this).closest('div.radio').addClass('active');

			/*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ *//*var option = $(this).find('option:selected');*/
			/*ЕСЛИ ВАРИАНТЫ - РАДИОБАТТОНЫ */
			if ($('input[name=variant_id]:checked').length > 0)
				var option = $('input[name=variant_id]:checked');
			else
				var option = $('input[name=variant_id][checked]');
			$('#sku span').html(option.data('sku'));
			if (option.attr('data-sku').length > 0)
				$('#sku').show();
			else
				$('#sku').hide();
			$('div.buy-status span').each(function(){
				$(this).hide();
			});
			var stock = parseInt(option.data('stock'));
			if (stock > 0){
				$('div.buy-status span.in-stock').show();
				$('#buy a.buy-buy').show();
				$('#buy a.buy1').show();
			}
			if (stock == 0){
				$('div.buy-status span.out-of-stock').show();
				$('#buy a.buy-buy').hide();
				$('#buy a.buy1').hide();
			}
			if (stock < 0){
				$('div.buy-status span.to-order').show();
				$('#buy a.buy-buy').show();
				$('#buy a.buy1').show();
			}
			var price = parseFloat(option.data('price'));
			var price_old = parseFloat(option.data('price-old'));
			/*if (price > 0)
				$('div.buy-price').show().html(option.data('price-convert')+' <span class="currency">' + mc_main_currency_sign + '</span>');
			else
				$('div.buy-price').hide();*/
			
			var is_variable_amount = 0;
			if ($('#buy div.buy-price').attr('data-variable-amount') == "1") {
				is_variable_amount = 1;
			}
			if (is_variable_amount)
				$('#buy div.buy-price').hide();
			if (price_old > 0)
				$('div.buy-oldprice').show().html(option.data('price-old-convert')+' <span class="currency">' + mc_main_currency_sign + '</span>');
			else
				$('div.buy-oldprice').hide();

			/*var amount_input = $('#buy input[name=amount]');
			if (amount_input.length > 0){
				var new_stock = stock;
				if (new_stock <= 0)
					new_stock = 999;
				amount_input.attr('data-stock', new_stock);
				if (parseInt(amount_input.val()) > new_stock)
					amount_input.val(new_stock);
			}*/
			
			var amount_input = $('#amount');
			if (amount_input.length > 0) {
				var new_stock = stock;
				if (new_stock <= 0)
					new_stock = 999;
				amount_input.attr('data-stock', new_stock);
				if (parseInt(amount_input.val()) > new_stock)
					amount_input.val(new_stock);
			}

			$('#relatedvariants-products li.doplistitem').addClass('hidden');
			$('#relatedvariants-products li.doplistitem input[name^=dop_products]:checked').prop('checked', false);
			$('#relatedvariants-products li[data-for-variant-id=' + $(this).val() + ']').removeClass('hidden');
			$('#relatedvariants-products-count').html($('#relatedvariants-products li[data-for-variant-id=' + $(this).val() + ']').length);

			calculate_price();
		});

		/*ЕСЛИ ВАРИАНТЫ - СЕЛЕКТ *//*$('select[name=variant_id]').trigger('change');*/
		/*ЕСЛИ ВАРИАНТЫ - РАДИОБАТТОНЫ */
		if ($('input[name=variant_id]:checked').length > 0)
			$('input[name=variant_id]:checked').trigger('change');
		else
			$('input[name=variant_id][checked]').trigger('change');

		calculate_price();

		$('#content').on('change', 'ul.list li.listitem select[name=variant_id]', function(){
			var li = $(this).closest('li');
			var option = $(this).find('option:selected');
			//li.find('span.in-stock').hide();
			//li.find('span.out-of-stock').hide();
			//li.find('span.to-order').hide();
			$('div.buy-status span.in-stock').hide();
			$('div.buy-status span.out-of-stock').hide();
			$('div.buy-status span.to-order').hide();

			var stock = parseInt(option.attr('data-stock'));
			if (stock > 0)
			{
				$('div.buy-status span.in-stock').show();
				//li.find('span.in-stock').show();
				li.find('a.list-buy').show();
				li.find('a.buy-one-click').show();
			}
			if (stock == 0)
			{
				$('div.buy-status span.out-of-stock').show();
				//li.find('span.out-of-stock').show();
				li.find('a.list-buy').hide();
				li.find('a.buy-one-click').hide();
			}
			if (stock < 0)
			{
				$('div.buy-status span.to-order').show();
				//li.find('span.to-order').show();
				li.find('a.list-buy').show();
				li.find('a.buy-one-click').show();
			}
			var price = parseFloat(option.attr('data-price'));
			var price_old = parseFloat(option.attr('data-price-old'));
			if (price > 0)
				li.find('div.list-price').show().html(option.attr('data-price-convert')+' <span>' + mc_main_currency_sign + '</span>');
			else
				li.find('div.list-price').hide();
			if (price_old > 0)
				li.find('div.list-old-price').show().html(option.attr('data-price-old-convert')+' <span>' + mc_main_currency_sign + '</span>');
			else
				li.find('div.list-old-price').hide();
			if (price < mc_cart_order_min_price || stock == 0)
				li.find('a.buy-one-click').hide();
			else
				li.find('a.buy-one-click').show();
		});

		$('#content').on('change', 'ul.plitka li.plitka-item select[name=variant_id]', function(){
			var li = $(this).closest('li');
			var option = $(this).find('option:selected');
			li.find('div.plitka-status span').each(function(){
				$(this).hide();
			});
			var stock = parseInt(option.attr('data-stock'));
			if (stock > 0)
			{
				$('div.buy-status span.in-stock').show();
				//li.find('span.in-stock').show();
				li.find('a.plitka-buy').show();
			}
			if (stock == 0)
			{
				$('div.buy-status span.out-of-stock').show();
				//li.find('span.out-of-stock').show();
				li.find('a.plitka-buy').hide();
			}
			if (stock < 0)
			{
				$('div.buy-status span.to-order').show();
				//li.find('span.to-order').show();
				li.find('a.plitka-buy').show();
			}
			var price = parseFloat(option.attr('data-price'));
			var price_old = parseFloat(option.attr('data-price-old'));
			if (price > 0)
				li.find('div.plitka-price').show().html(option.attr('data-price-convert')+' <span>' + mc_main_currency_sign + '</span>');
			else
				li.find('div.plitka-price').hide();
			if (price_old > 0)
				li.find('div.plitka-old-price').show().html(option.attr('data-price-old-convert')+' <span>' + mc_main_currency_sign + '</span>');
			else
				li.find('div.plitka-old-price').hide();
		});

		if (mc_count_related_products > 0 || mc_count_analogs_products > 0 || mc_count_collection_products > 0) {
			$.get(mc_related_products_module_url, function(data){
				$('#related').html(data);
				$('#related_spinner').hide();
				$('a[data-toggle="tooltip"]').tooltip({
					container: 'body'
				});
			});
		}

		$.get(mc_external_modules_products_module_url, function(data){
			$('#external-modules').html(data);
		});

		if (mc_settings_reviews_enabled) {
			$.get(mc_reviews_product_module_url, function(data){
				$('#reviews-list').html(data);

				//if ($().rating) {
                    $('#user-rating2-1, #user-rating2-2').rating({
                        fx: 'full',
                        image: mc_path_frontend_template + '/img/stars/big.png',
                        loader: '<i class="fa fa-spinner fa-spin"></i>',
                        minimal: 1,
                        preselectMode: false,
                        click: function(data){
                            var index = $(this).closest('.my-review').data('index');
                            $('#rating-block-error-'+index).hide();
                            $('#reviews-list input[name=rating]').val(data);
                        }
                    });
				//}

				if ($('body script[src$="elements/reviews_product.js"]').length == 0) {
					var s = document.createElement("script");
					s.type = "text/javascript";
					s.src = mc_path_frontend_template + "/js/elements/reviews_product.js";
					$("body").append(s);
				}
			});
		}

		$.get(mc_pages_material_module_url, function(data){
			if (data.success)
				$('#delivery').html(data.description);
		});


        if ($().rating) {
            $('#main-rating').rating({
                fx: 'full',
                image: mc_path_frontend_template + '/img/stars/stars.png',
                loader: '<i class="fa fa-spinner fa-spin"></i>',
                minimal: 1,
                url: mc_product_module_url,
                callback: function (data) {
                    $('#user-rating2-2').rating('update', {
                        val: this._data.score
                    });
                    $('#reviews-list input[name=rating]').val(this._data.score);
                }
            });

            $('#user-rating').rating({
                fx: 'full',
                image: mc_path_frontend_template + '/img/stars/stars.png',
                loader: '<i class="fa fa-spinner fa-spin"></i>',
                minimal: 1,
                url: mc_product_module_url,
                preselectMode: true
            });
        }

		$('.vygodaprod .discount_form input[name=discount_info_phone]').mask("+7 (999) 999-99-99");
	});

	$('a.buy-buy, a.buy-buy2').click(function(){
		// $('#fillings-error').hide();
		var is_error = false;
		// $('.btn-select-filling:visible').each(function(i, elem) {
		// 	if ($(this).attr('data-filling-id') == '0') {
		// 		$('#fillings-error').show();
		// 		is_error = true;
		// 		return false;
		// 	}
		// });
		if (is_error) {
			return false;
		}

		/*ЕСЛИ ВАРИАНТЫ СЕЛЕКТ*//*var variant_id = $(this).closest('#content').find('select[name=variant_id] option:selected').val();*/
		/*ЕСЛИ ВАРИАНТЫ РАДИОБАТТОНЫ*/
		if ($(this).closest('#content').find('input[name=variant_id]:checked').length > 0)
			var variant_id = $(this).closest('#content').find('input[name=variant_id]:checked').val();
		else
			var variant_id = $(this).closest('#content').find('input[name=variant_id][checked]').val();
		var modificators = [];
		var modificators_count = [];
		var fillings = [];
		var amount = 1;
		var var_amount = 1;
		
		if ($('#amount').length > 0) {
			var amount_input = $('#amount');
			if (amount_input.length > 0)
				var_amount = amount_input.val();
		}
		if ($('#content input[name=amount]').length > 0) {
			var amount_input = $('#content input[name=amount]');
			if (amount_input.length > 0)
				amount = amount_input.val();
		}
		
		var variant_name = "";
		var variant_price = "";
		if ($('input[name=variant_id]:checked').length > 0)
			var current_variant = $('input[name=variant_id]:checked');
		else
			var current_variant = $('input[name=variant_id][checked]');
		if (current_variant.length > 0) {
			variant_name = current_variant.attr('data-variant-name');
			variant_price = current_variant.attr('data-price');
		}

		/*var amount_input = $('#content input[name=amount]');
		if (amount_input.length > 0)
			amount = amount_input.val();
		var amount_select = $('#content select[name=amount] option:selected');
		if (amount_select.length > 0)
			amount = amount_select.val();*/

		/* ПОЛУЧИМ ВЫБРАННЫЕ ДОП КОМПЛЕКТАЦИИ */
		var dop_products = [];
		$('#dop-products .doplistitem input[name^=dop_products]:checked, #relatedvariants-products .doplistitem input[name^=dop_products]:checked').each(function(){
			dop_products.push($(this).closest('li.doplistitem').find('select[name=dop_variant_id] option:selected:first').val());
			//dop_products.push($(this).val());
		});

		$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
			if (parseInt($(this).val()) > 0)
			{
				modificators.push($(this).val());
				var ic = $(this).closest('div').find('input[name^=modificators_count]');
				if (ic.length > 0)
					modificators_count.push(ic.val());
				else
					modificators_count.push(1);
			}
		});

		var tiers_count = $('select[name=tiers] option:selected').val();
		for(var i=1 ; i <= tiers_count; i++) {
			fillings.push($('#filling'+i).attr('data-filling-id'));
		}

		var text_on_cake = $('input[name=text_on_cake]').length > 0 ? $('input[name=text_on_cake]').val() : '';
		var temp_id = $('input[name=temp_id]:first').val();

		var href = mc_cart_module_url + "?action=add_variant&variant_id="+variant_id+"&modificators="+modificators.join(',')+"&modificators_count="+modificators_count.join(',')+"&amount="+amount+"&var_amount="+var_amount+"&fillings="+fillings.join(',')+'&text_on_cake='+encodeURIComponent(text_on_cake)+'&temp_id='+temp_id;

		if (dop_products.length > 0)
			href += "&dop_products="+dop_products.join(',');

		//$.get(mc_cart_empty_cart_module_url, function(data){
        //    if (data.success){
				$.get(href, function(data){
					if (data.success)
					{
						dataLayer.push({
							'ecommerce': {
								'add': {
									'products': [
										{
											'id' : mc_product_id,
											'name' : mc_product_name,
											'price' : variant_price,
											'category' : mc_category_name,
											'variant' : variant_name,
											'quantity': amount
										}
									]
								}
							}
						});
						
						$('#cart .cart-counter').html(data.total_products);
						if (data.additional_result != undefined && data.additional_result.message == "no_stock")
						{
							/*$('.buy-buy').hide();
							$('.buy1').hide();*/
							$('#no_stock').html('Вы можете купить товар в количестве не более ' + data.additional_result.max_stock + ' штук');
							$('#no_stock').show();
						}
						else
							if (data.additional_result != undefined && data.additional_result.added_form !=undefined)
							{
								$('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
								$('#after-buy-form .modal-body').html(data.additional_result.added_form);
								$('#after-buy-form').modal();
							}
					}
				});
		//	}
		//});
		return false;
	});

	if (mc_settings_reviews_enabled) {
		$('#reviews-list').on('focusout', '.my-review input[name=name]', function(){
			var index = $(this).closest('.my-review').data('index');
			if ($(this).val().length == 0)
				$('#name-block-error-'+index).show();
			else
				$('#name-block-error-'+index).hide();
		});

		$('#reviews-list').on('focusout', '.my-review textarea[name=comments]', function(){
			var index = $(this).closest('.my-review').data('index');
			if ($(this).val().length == 0)
				$('#comment-block-error-'+index).show();
			else
				$('#comment-block-error-'+index).hide();
		});

		$('#reviews-list').on('click', '#add-review-1, #add-review-2', function(){

			var isError = false;
			var index = $(this).data('index');
			var review = $(this).closest('.my-review');

			if (review.find('input[name=rating]').val().length == 0){
				$('#rating-block-error-'+index).show();
				isError = true;
			}

			if (review.find('input[name=name]').val().length == 0){
				$('#name-block-error-'+index).show();
				isError = true;
			}

			if (review.find('textarea[name=comments]').val().length == 0){
				$('#comment-block-error-'+index).show();
				isError = true;
			}

			if (isError)
				return false;

			$('#rating-block-error-'+index).hide();
			$('#name-block-error-'+index).hide();
			$('#comment-block-error-'+index).hide();

			$.ajax({
				url: mc_product_module_url,
				type: 'POST',
				data: {
					name: review.find('input[name=name]').val(),
					short: review.find('input[name=short]').val(),
					pluses: review.find('textarea[name=pluses]').val(),
					minuses: review.find('textarea[name=minuses]').val(),
					comments: review.find('textarea[name=comments]').val(),
					recommended: review.find('input[name=recommended]').is(':checked') ? 1 : 0,
					rating: review.find('input[name=rating]').val(),
					product_id: mc_product_id,
					temp_id: review.find('input[name=temp_id]').val()
				},
				dataType: 'json',
				success: function(data){
					$.get(mc_product_reviews_module_url, function(data){
						$('#reviews-list').html(data);
						$('#add-review-result-'+index).show();

						$('#user-rating2-1, #user-rating2-2').rating({
							fx: 'full',
							image: mc_path_frontend_template + '/img/stars/big.png',
							loader: '<i class="fa fa-spinner fa-spin"></i>',
							minimal: 1,
							preselectMode: true,
							click: function(data){
								$('#reviews-list input[name=rating]').val(data);
							}
						});
					});
				}
			});
			return false;
		});

		$('#reviews-list').on('click', '.usergrade-yes', function(){
			var r = $(this).closest('div.review-body');
			$.ajax({
				url: mc_product_module_url,
				type: 'POST',
				data: {
					review_id: r.data('id'),
					helpful: 1,
					nothelpful: 0
				},
				dataType: 'json',
				success: function(data){
					if (data.success){
						r.find('.usergrade-yes').next('span:first').html(data.helpful);
						r.find('.usergrade-no').next('span:first').html(data.nothelpful);
					}
					else
						if (data.message == 'exists')
							alert('Вы уже отметили полезность этого отзыва!');
				}
			});
			return false;
		});

		$('#reviews-list').on('click', '.usergrade-no', function(){
			var r = $(this).closest('div.review-body');
			$.ajax({
				url: mc_product_module_url,
				type: 'POST',
				data: {
					review_id: r.data('id'),
					helpful: 0,
					nothelpful: 1
				},
				dataType: 'json',
				success: function(data){
					if (data.success){
						r.find('.usergrade-yes').next('span:first').html(data.helpful);
						r.find('.usergrade-no').next('span:first').html(data.nothelpful);
					}
					else
						if (data.message == 'exists')
							alert('Вы уже отметили полезность этого отзыва!');
				}
			});
			return false;
		});

		$('#reviews-list').on('click', '.rate-sort-item a', function(){
			var url = $(this).attr('href');
			var sort_method = $(this).closest('div').data('sort');
			var mainUrl = mc_product_reviews_sort_module_url + sort_method;

			if (typeof history.pushState != undefined) {
				history.pushState({ mode: 'product', url: mainUrl},null,encodeURI(decodeURI(mainUrl)));
			}

			$.get(url, function(data){
				$('#reviews-list').html(data);

				$('#user-rating2-1, #user-rating2-2').rating({
					fx: 'full',
					image: mc_path_frontend_template + '/img/stars/big.png',
					loader: '<i class="fa fa-spinner fa-spin"></i>',
					minimal: 1,
					preselectMode: true,
					click: function(data){
						var index = $(this).closest('.my-review').data('index');
						$('#rating-block-error-'+index).hide();
						$('#reviews-list input[name=rating]').val(data);
					}
				});
			});
			return false;
		});
	}

	if (mc_settings_reviews_enabled) {
		$('div.products-tabs ul.nav-tabs a').click(function(){
			var url = mc_product_full_url + '?tab=' + $(this).data('url');
			if (typeof history.pushState != undefined) {
				history.pushState({ mode: 'product', url: url},null,encodeURI(decodeURI(url)));
			}
		});
	}

	$('#write-review').click(function(){
		$('.products-tabs ul.nav-tabs li.active').removeClass('active');
		$('.products-tabs ul.nav-tabs a[data-url=reviews]').closest('li').addClass('active');

		$('.products-tabs .tab-content div.tab-pane.active').removeClass('active');
		$('#reviews').addClass('active');

		$('html, body').animate({
			scrollTop: $("#add-review-bottom").offset().top
		}, 0);

		return false;
	});

	$('a[aria-controls=descriptiongoods]').on('shown.bs.tab', function(e){
		window.dispatchEvent(new Event('resize'));
	});

	$(".vygodaprod .discount_info").hover(function(){
		$(".vygodaprod .discount_info .discount_info_tip").show();
	},function(){
		$(".vygodaprod .discount_info .discount_info_tip").hide();
	});

	$(".vygodaprod .discount_info .dicount_info_button").click(function(){
		$(".vygodaprod .discount_form").show();
	});

	$(".vygodaprod .discount_form .discount_form_close").click(function(){
		$(".vygodaprod .discount_form").hide();
	});

	$(".vygodaprod .discount_form .discount_form_button").click(function(){
		if ($('.vygodaprod .discount_form input[name=discount_info_phone]').mask().length == 0)
		{
			$('.vygodaprod .discount_form input[name=discount_info_phone]').focus();
			return false;
		}
		var phone_field = encodeURIComponent($('.vygodaprod .discount_form input[name=discount_info_phone]').val());
		var user_name = encodeURIComponent($('.vygodaprod .discount_form input[name=discount_info_name]').val());
		var message_field = encodeURIComponent($('.vygodaprod .discount_form input[name=discount_info_price]').val());
		var variant_id_field = encodeURIComponent($('.vygodaprod .discount_form input[name=discount_info_product]').val());

		var url = mc_callback_module_url + "&phone_number=" + phone_field + "&message=" + message_field + "&user_name=" + user_name + "&variant_id=" + variant_id_field;
		$.ajax({
			type: 'GET',
			url: url,
			success: function(data) {
				$(".vygodaprod .discount_form").hide();
				$('#callback-success-part-product-discount').show();
			}
		});
		return false;
	});

	/*var spacesToAdd = 10;
	var biggestLength = 0;
	$("select[name=amount] option").each(function(){
	var len = $(this).text().length;
		if(len > biggestLength){
			biggestLength = len;
		}
	});
	var padLength = biggestLength + spacesToAdd;
	$("select[name=amount] option").each(function(){
		var parts = $(this).text().split('|');
		var strLength = parts[0].length;
		for(var x=0; x<(padLength-strLength); x++){
			parts[0] = parts[0]+' ';
		}
		$(this).text(parts[0].replace(/\+/g, '\u00a0')+' / '+parts[1]).text;
	});*/

	$('#amount').change(function(){
		var val = parseFloat(this.value);
		var tiers_count = 0;
		$('select[name=tiers] option').each(function(index, opt){
			if (parseInt(opt.getAttribute('data-weight')) <= parseInt(val)) {
				if (opt.hasAttribute('disabled')) { opt.removeAttribute('disabled'); }
				this.text = opt.getAttribute('data-normal-text');
				tiers_count++;
			} else {
				if (!opt.hasAttribute('disabled')) { opt.setAttribute('disabled', 'disabled'); }
				this.text = opt.getAttribute('data-warning-text');
			}
		});
		// for(var i=1; i<=tiers_count; i++) {
		// 	$('#filling'+i+'-group').show();
		// }
		for(var i=tiers_count+1;i<=5;i++)
		{
            $('#filling'+i+'-group').hide();
		}
		$('select[name=tiers]').val(tiers_count);
        $('select[name=tiers]').trigger('change');
        $('select[name=tiers]').select2('destroy');
        $('select[name=tiers]').removeClass("selectbox-inited");
        selectbox_init($('select[name=tiers]'));
		calculate_price();
	});

	$('select[name=tiers]').change(function(){
		var tiers_count = parseInt(this.value);
		for(var i=1; i<=tiers_count; i++) {
			$('#filling'+i+'-group').show();
		}
		for(var i=tiers_count+1;i<=5;i++)
			$('#filling'+i+'-group').hide();
	});

	$('.js-filling-select').click(function(e){
        e.preventDefault();
		var id = $(this).attr('data-id');
		var tier_num = $('#filling-selector input[name=tier_num]').val();
		$('#filling-selector input[name=filling_id]').val(id);
		$('#filling' + tier_num).attr('data-filling-id', id);
        $('#filling' + tier_num + '-group .filling-image-preview').html('<img src="'+$(this).attr('data-small-image')+'"/>');
        $('#filling' + tier_num + '-group .filling-name-selected').html($(this).attr('data-name'));
        $('#filling' + tier_num + '-group').addClass("active");
		$('#filling-selector').modal('hide');
        $('#filling'+tier_num).tooltipster('content', $('#filling'+tier_num).data('title-alt'));
	});

	$('.js-filling-add').click(function(e){
		e.preventDefault();
        var $group = $(this).closest('.filling-group');
		$('#filling-selector input[name=tier_num]').val($group.find(".btn-select-filling").attr('data-tier-num'));
		$('#filling-selector input[name=filling_id]').val($group.find(".btn-select-filling").attr('data-filling-id'));
		$('#filling-selector').modal();
	});

	$('.js-filling-remove').click(function(e){
        e.preventDefault();
		var $group = $(this).closest('.filling-group');
        $group.find('div.filling-image-preview').html('');
        $group.find('div.filling-name-selected').html('');
        $group.find('button.btn-select-filling').attr('data-filling-id', '0');
        $group.removeClass("active");
	});

	var imgListCustom = $('#img-list-custom');
	var fileInputCustom = $('#uploadImageField-custom');

	fileInputCustom.damnUploader({
		url: mc_product_custom_upload_images_module_url,
		fieldName:  'uploaded-images-custom',
		object_id: $('input[name=temp_id]:first').val(),
		limit: 3,
		onSelect: function(file) {
			$('.upload-anim-custom').removeClass('hidden');
			var addedItem = addFileToQueueCustom(file);
			fileInputCustom.damnUploader('startUploadItem', addedItem.queueId);
			return false;
		},
		onAllComplete: function(){
			$('.upload-anim-custom').addClass('hidden');

			fileInputCustom.damnUploader('cancelAll');
			imgListCustom.html('');

			$.get(mc_product_custom_get_temp_images_module_url + '&temp_id=' + $('input[name=temp_id]:first').val(), function(data) {
				if (data.success) {
					$('div.product-custom-add-images div.object-images').html(data.data);

					if ($('.list1-custom li').length >= 3) {
						$('.btn-add-custom-image').hide();
					} else {
						$('.btn-add-custom-image').show();
					}
				}
			});
			return false;
		},
	});

	function updateProgress(bar, value) {
		bar.css('width', value+'%');
	}

	function addFileToQueueCustom(file) {

		// Создаем элемент li и помещаем в него название, миниатюру и progress bar
		var li = $('<li/>').appendTo(imgListCustom);
		var title = $('<div/>').text(file.name+' ').appendTo(li);
		var cancelButton = $('<a/>').attr({
			href: '#cancel',
			title: 'отменить'
		}).text('X').appendTo(title);

		// Если браузер поддерживает выбор файлов (иначе передается специальный параметр fake,
		// обозначающий, что переданный параметр на самом деле лишь имитация настоящего File)
		if(!file.fake)
		{
			// Отсеиваем не картинки
			var imageType = /image.*/;
			if (!file.type.match(imageType)) {
				return true;
			}

			// Добавляем картинку и прогрессбар в текущий элемент списка
			var img = $('<img/>').appendTo(li);

			var pBar = $('<div class="progress progress-striped active"></div>').appendTo(li);
			var ppBar = $('<div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>').appendTo(pBar);

			// Создаем объект FileReader и по завершении чтения файла, отображаем миниатюру и обновляем
			// инфу обо всех файлах (только в браузерах, поддерживающих FileReader)
			if($.support.fileReading) {
				var reader = new FileReader();
				reader.onload = (function(aImg) {
					return function(e) {
						aImg.attr('src', e.target.result);
						aImg.attr('width', 150);
					};
				})(img);
				reader.readAsDataURL(file);
			}
		}

		// Создаем объект загрузки
		var uploadItem = {
			file: file,
			queueId: 0,
			onProgress: function(percents) {
				updateProgress(ppBar, percents);
			},
			onComplete: function(successfully, data, errorCode) {
				pBar.removeClass('active');
			},
		};

		// помещаем его в очередь
		var queueId = fileInputCustom.damnUploader('addItem', uploadItem);
		uploadItem.queueId = queueId;

		// обработчик нажатия ссылки "отмена"
		cancelButton.click(function() {
			fileInputCustom.damnUploader('cancel', queueId);
			li.remove();
			return false;
		});

		return uploadItem;
	}

	$('div.object-images').on("click", "a.delete_image", function(){
		var item = $(this).closest('li');
		var href = $(this).attr('href');
		$.get(href, function(data) {
			if (data)
			{
				item.fadeOut('fast').remove();
				if ($('.list1-custom li').length >= 3) {
					$('.btn-add-custom-image').hide();
				} else {
					$('.btn-add-custom-image').show();
				}
			}
		});
		return false;
	});

}