function header_scroll() {
    if (!window['header_scroll_inited_glob']) {
        // отключить на других страницах
        if (!$('.module-ProductsController').length) {
            window['header_scroll_disabled'] = true;
        }

        $(window).on('resize', function(){

        });

        window['header_scroll_inited_glob'] = true;
    }
    if (window['header_scroll_disabled']) {
        return;
    }
    if (!window['body_scroll_prev']) {
        window['body_scroll_prev'] = 0;
    }
    var st = $(window).scrollTop();
    var $hw = $(".header-wrapper");
    var $h = $(".header");
    var hw_offset = $hw.offset().top;
    var h_height = $h[0].offsetHeight;
    var hw_height = $hw.height();
    var below_top = $(window).scrollTop() > hw_offset;
    var below = $(window).scrollTop() > hw_height + hw_offset;
    var has_scrolled = $h.hasClass("hw-scrolled");
    if (!has_scrolled) {
        $h.addClass('no-transition');
        $h[0].offsetHeight;
    }
    $h.toggleClass("hw-scrolled", below);
    if (!has_scrolled) {
        $h[0].offsetHeight;
        $h.removeClass('no-transition');
        $h[0].offsetHeight;
    }
    var scrolled_up = /*below_top && */!!($(window).scrollTop() && window['body_scroll_prev'] > $(window).scrollTop());
    $h.toggleClass("hw-scrolled-up", scrolled_up);
    window['body_scroll_prev'] = $(window).scrollTop();
    var $fc = $('.floatbar-subcategories');
	if ($fc.length == 0) {
		$fc = $('h1:first');
	}
    var $fci = $(".floatbar-inner-subcategories");
    var fc_is_fixed = $(window).scrollTop() > $fc.offset().top - ((scrolled_up || $(window).width() <= 1259)?h_height:0);
    $fc.toggleClass('is-fixed', fc_is_fixed);
    if (!fc_is_fixed) {
        $fc.addClass('no-transition');
        $fc[0].offsetHeight;
    }
    $fc.toggleClass('is-hw-scrolled-up', scrolled_up);
    if (!fc_is_fixed) {
        $fc[0].offsetHeight;
        $fc.removeClass('no-transition');
        $fc[0].offsetHeight;
    }
}
//
//
//
// function sticky_sidebar_init() {
//
//     $('.floatbar').each(function(){
//         var tsp = $(this).data('top-spacing');
//         this.addEventListener('affixed.container-bottom.stickySidebar', function (e) {
//             $(this).addClass('is-bottom');
//         }, false);
//         var removeClass = function (e) {
//             $(this).removeClass('is-bottom');
//         };
//         this.addEventListener('affixed.static.stickySidebar', removeClass, false);
//         this.addEventListener('affixed.top.stickySidebar', removeClass, false);
//         this.addEventListener('affixed.bottom.stickySidebar', removeClass, false);
//         $(this).stickySidebar({
//             topSpacing: (tsp === parseInt(tsp, 10))?tsp:function(sidebar){
//                 var $fbi = $(sidebar).find('.floatbar-inner');
//                 if ($fbi.length) {
//                     $tsp = $($fbi.data('top-spacing'));
//                     var offset = 0;
//                     if ($tsp.length) {
//                         $tsp.each(function(){
//                             if ($(this).css('position') == 'fixed') {
//                                 offset += this.offsetHeight;
//                             }
//                         });
//                     }
//                     return offset;
//                 }
//                 return 0;
//             },
//             bottomSpacing: 0,
//             containerSelector: '.floatbar-track',
//             innerWrapperSelector: '.floatbar-inner'
//         });
//
//     });
// }



/*

Tabs

 */

function tabs_init() {
    $(document).on("click", ".js-tabs a[href^='#']", function (e, eparams) {
        if ($(this).hasClass("js-tabs-ignore")) {
            return true;
        }
        e.preventDefault();
        $o = $($(this).attr("href"));
        var $tabs = $(this).closest(".js-tabs");
        if ($o.length) {
            if ($(this).closest(".js-tabs-toggle").length) {
                $o.toggleClass("active");
                if ($o.hasClass("active")) $(this).parent().addClass("active");
                else $(this).parent().removeClass("active");
            }
            else {
                $o.addClass("active");
                if ($o.hasClass("active")) $(this).parent().addClass("active");
            }

            $(this).parent().siblings().removeClass("active");
            $o.siblings(".tab-pane").removeClass("active");
            if ($o.hasClass("active")) $o.closest(".tab-content-change-height").css("height", 0).css("height", $o.outerHeight());
            else $o.closest(".tab-content-change-height").css("height", 0);
            if ($(this).closest(".js-tabs").hasClass("js-tabs-hash-change")) {
                if (typeof eparams === "undefined" || typeof eparams.nohash === "undefined" || !eparams.nohash) {
                    location_hash_update($(this).attr("href"), location.href.split("#")[0]);
                }
            }
            $o.filter(".active").find(":input:first").trigger("check-form");
            $o.siblings(".tab-pane").find(":input.error").each(function () {
                var $form = $(this).closest("form");
                var validator = $form.data("validator");
                if (validator) $(this).valid();
            });
            $(window).trigger("scroll.fixed-hscroll");

            $ostt = $(this).closest(".js-tabs-onclick-scroll-to-tabs");
            if ($ostt.length) {
                $("html, body").stop(true, true).animate({scrollTop: $ostt.offset().top - $(".header").outerHeight() + $ostt.data("tabs-scroll-offset")}, 500);
            }
            tabs_update_pointer($(this).closest(".js-tabs"));
        }
    });

    tabs_scroll_init();
    $(document).ajaxStop(function () {
        tabs_scroll_init();
    });

    if (location.hash) {
        if ($(location.hash).filter(".tab-pane").length) {
            $(".js-tabs a[href='" + location.hash + "']").trigger("click");
        }
        else if ($(".tab-pane").find(location.hash).length) {
            $(".js-tabs a[href='#" + $(".tab-pane").find(location.hash).closest(".tab-pane").attr("id") + "']").trigger("click", {nohash: true});
        }
    }

    tabs_update_pointer($(".tabs"));
    $(".js-tabs-fixed-center a").on("click-tabs-fixed-center", function (e) {
        tabs_update_pointer($(this).closest(".tabs"));
    });
    $(window).on("resize orientationchange", function () {
        tabs_update_pointer($(".tabs"));
        $(".js-tabs-fixed-center-scroll").trigger("scroll.emulate");
    });
}

function tabs_scroll_init() {
    var $o = $('.js-tabs-fixed-center-scroll');

    $o.on("scroll scroll.emulate", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        if ($(this).scrollLeft() <= 0) {
            $tabs.addClass("scroll-in-start");
        }
        else {
            $tabs.removeClass("scroll-in-start");
        }
        if ($(this).scrollLeft() + $(this).width() >= $(this)[0].scrollWidth - 1) {
            $tabs.addClass("scroll-in-end");
        }
        else {
            $tabs.removeClass("scroll-in-end");
        }
    }).trigger("scroll.emulate");

    // $o.each(function(){
    //     var _this = this;
    //     var sb = new ScrollBooster({
    //         viewport: this,
    //         mode: 'x',
    //         onUpdate: function(data) {
    //             _this.scrollLeft = data.position.x;
    //         },
    //         onClick: function(data, event) {
    //             console.log(event.target, event, data);
    //             if (Math.abs(data.dragOffsetPosition.x) > 5) {
    //                 event.stopImmediatePropagation();
    //                 //event.preventDefault();
    //                 //_this.scrollLeft = data.position.x;
    //             }
    //         }
    //     });
    // });

    $(".js-tabs-fixed-center a").on("click click-tabs-fixed-center", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $(this).closest(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $(this).parent()[0].offsetLeft - $tabs.width() / 2 + $(this).outerWidth() / 2}, 500);
    });

    $(".js-tabs-scroll-left").on("click", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() - $tabs.width()*0.8}, 500);
    });

    $(".js-tabs-scroll-right").on("click", function (e) {
        var $tabs = $(this).closest(".js-tabs-fixed-center");
        var $ul = $tabs.find(".js-tabs-fixed-center-scroll");
        $ul.stop(true, true).animate({scrollLeft: $ul.scrollLeft() + $tabs.width()*0.8}, 500);
    });

    $(".js-tabs-fixed-center li.active a").trigger("click-tabs-fixed-center");
}

function tabs_update_pointer($tabs) {
    $tabs.each(function () {
        var $pointer = $(this).find(".js-tabs-pointer");
        var $scroll = $pointer.closest(".tabs__scroll");
        var scroll_offset = 0;
        if ($pointer.length) {
            if ($scroll.length) {
                scroll_offset = $scroll[0].scrollLeft;
            }
            $pointer.css({
                left: $(this).find("li.active").offset().left - $(this).offset().left + scroll_offset,
                width: $(this).find("li.active").outerWidth(),
            }).addClass("active");
        }
    });
}


/*
* Scroll State Classes
* */

function scroll_state_init() {

    if (!$("body").hasClass("scroll-state-inited")) {

        $(window).on("resize", function (e) {
            $(".js-scroll-state-inited").trigger("scroll-state");
        });

        $("body").addClass("scroll-state-inited")
    }

    $(".js-scroll-state").not(".js-scroll-state-inited").on("scroll scroll-state", function(e){
        var $container = $(this);
        if ($(this).data("scroll-state-container")) {
            if ($(this).data("scroll-state-container-closest")) {
                $container = $(this).closest($(this).data("scroll-state-container"));
            }
            else $container = $($(this).data("scroll-state-container"));
        }
        // Vertical
        $container.toggleClass("scroll-state-start", $(this).scrollTop() <= 0);
        $container.toggleClass("scroll-state-end", $(this).scrollTop() + $(this).height() >= $(this)[0].scrollHeight); //  - 1
        // Horizontal
        $container.toggleClass("hscroll-state-start", $(this).scrollLeft() <= 0);
        $container.toggleClass("hscroll-state-end", $(this).scrollLeft() + $(this).width() >= $(this)[0].scrollWidth); // - 1
    }).trigger("scroll-state").addClass("js-scroll-state-inited");
}


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited-globally")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited-globally");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        if ($img.length && $img[0].currentSrc) {
            $imgtobg.css("background-image", "url('" + $img[0].currentSrc + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();



/*

Lazy Load Image

 */

function lazy_load($o)
{
    if (typeof $o === "undefined") $o = $("[data-lazy-src]");
    $o.not(".lazy-load-inited").each(function(){
        if ($(this).parent().is(':visible')) {
            var offset_top = $(this).offset().top;
            if (!offset_top) {
                offset_top = $(this).parent().offset().top;
            }
            if (offset_top - $(window).height() < $(window).scrollTop() + $(window).height()/3)
            {
                lazy_load_change($(this));
            }
        }
    });
}

function lazy_load_change($img)
{
    $img.addClass("lazy-load-inited");
    $img.attr("src", $img.attr("data-lazy-src"));
    $img.one("load", function() {
        var $this = $(this);
        img_to_bg($this.parent(".img-to-bg"));
    });
}



/*

Slider

 */


function slider_init($s)
{
    slider_swiper_init($s);
}

function slider_swiper_init($s)
{
    if (typeof Swiper !== "undefined")
    {
        if (typeof $s === "undefined") $s = $(".swiper-slider").filter(":not(.slider-events-added)");
        $s.each(function(){
            var $this = $(this);
            var swiper = new Swiper($this.find('.swiper-container')[0], {
                init: false,
                direction: (typeof $this.data("slider-direction") !== "undefined")?$this.data("slider-direction"):'horizontal',
                loop: (typeof $this.data("slider-loop") !== "undefined")?$this.data("slider-loop"):false,
                loopAdditionalSlides: 10,
                autoplay: (typeof $this.data("slider-autoplay") !== "undefined")?$this.data("slider-autoplay"):false,
                initialSlide: (typeof $this.data("slider-initial-slide") !== "undefined")?$this.data("slider-initial-slide"):0,
                pagination: {
                    el: (typeof $this.data("slider-pagination") !== "undefined")?$this.data("slider-pagination"):$this.find('.swiper-pagination')[0],
                    type: (typeof $this.data("slider-pagination-type") !== "undefined")?$this.data("slider-pagination-type"):'bullets',
                    bulletElement: (typeof $this.data("slider-pagination-element") !== "undefined") ? $this.data("slider-pagination-element") : 'span',
                    clickable: (typeof $this.data("slider-pagination-clickable") !== "undefined")?$this.data("slider-pagination-clickable"):true,
                    renderBullet: function(index, className) {
                        var $slider = $(this.$el).closest(".swiper-slider");
                        var name = $(this.slides).eq(index).attr("data-pagination-name");
                        var summary = $(this.slides).eq(index).attr("data-pagination-summary");
                        var tag = 'span';
                        if ($slider.length)
                        {
                            if ($slider.data("slider-pagination-element"))
                            {
                                tag = $slider.data("slider-pagination-element");
                            }
                        }
                        if (!name) {
                            name = "";
						}
						else {
                            name = "<div class='swiper-pagination-name'>" + name + "</div>";
						}
                        if (!summary) {
                            summary = "";
						}
						else {
                            summary = "<div class='swiper-pagination-summary'>" + summary + "</div>";
						}
                        return '<'+tag+' class="' + className + '">' + name + summary + '</'+tag+'>';
                    },
                },
                scrollbar: ($this.find('.swiper-scrollbar').length)?{ el: $this.find('.swiper-scrollbar')[0] }:{},
                scrollbarHide: (typeof $this.data("slider-scrollbar-hide") !== "undefined")?$this.data("slider-scrollbar-hide"):false,
                scrollbarDraggable: (typeof $this.data("slider-scrollbar-draggable") !== "undefined")?$this.data("slider-scrollbar-draggable"):true,
                scrollbarSnapOnRelease: (typeof $this.data("slider-scrollbar-snap-on-release") !== "undefined")?$this.data("slider-scrollbar-snap-on-release"):true,
                freeMode: (typeof $this.data("slider-free-mode") !== "undefined")?$this.data("slider-free-mode"):false,
                autoHeight: (typeof $this.data("slider-auto-height") !== "undefined")?$this.data("slider-auto-height"):false,
                centeredSlides: (typeof $this.data("slider-centered-slides") !== "undefined")?$this.data("slider-centered-slides"):false,
                slidesPerView: (typeof $this.data("slider-slides-per-view") !== "undefined")?$this.data("slider-slides-per-view"):1,
                slidesPerGroup: (typeof $this.data("slider-slides-per-group") !== "undefined")?$this.data("slider-slides-per-group"):1,
                effect: (typeof $this.data("slider-effect") !== "undefined")?$this.data("slider-effect"):"slide",
                fade: {
                    crossFade: true
                },
                simulateTouch: (typeof $this.data("slider-simulate-touch") !== "undefined")?$this.data("slider-simulate-touch"):true,
                onlyExternal: (typeof $this.data("slider-only-external") !== "undefined")?$this.data("slider-only-external"):false,
                spaceBetween: (typeof $this.data("slider-space-between") !== "undefined")?$this.data("slider-space-between"):0,
                navigation: {
                    prevEl: (typeof $this.data("slider-prev-button") !== "undefined")?$this.data("slider-prev-button"):$this.find('.swiper-button-prev')[0],
                    nextEl: (typeof $this.data("slider-next-button") !== "undefined")?$this.data("slider-next-button"):$this.find('.swiper-button-next')[0],
                },
                speed: (typeof $this.data("slider-speed") !== "undefined")?$this.data("slider-speed"):300,
                buttonDisabledClass: 'disabled',
                slidesOffsetBefore: (typeof $this.data("slider-slides-offset-before") !== "undefined")?$this.data("slider-slides-offset-before"):0,
                slidesOffsetAfter: (typeof $this.data("slider-slides-offset-after") !== "undefined")?$this.data("slider-slides-offset-after"):0,
                roundLengths: (typeof $this.data("slider-round-lengths") !== "undefined")?$this.data("slider-round-lengths"):true,
                lazy: (typeof $this.data("slider-lazy-loading") !== "undefined")?$this.data("slider-lazy-loading"):false,
                lazyLoadingInPrevNext: (typeof $this.data("slider-lazy-loading-in-prev-next") !== "undefined")?$this.data("slider-lazy-loading-in-prev-next"):true,
                lazyLoadingOnTransitionStart: true,
                nested: (typeof $this.data("slider-nested") !== "undefined")?$this.data("slider-nested"):false,
                resistanceRatio: (typeof $this.data("slider-resistance-ratio") !== "undefined")?$this.data("slider-resistance-ratio"):0.85,
                breakpoints: (typeof $this.data("slider-breakpoints") !== "undefined")?$this.data("slider-breakpoints"):null,
                slideToClickedSlide: (typeof $this.data("slider-slide-to-clicked-slide") !== "undefined")?$this.data("slider-slide-to-clicked-slide"):false,
                mousewheelControl: (typeof $this.data("slider-mousewheel-control") !== "undefined")?$this.data("slider-mousewheel-control"):false,
                mousewheelReleaseOnEdges: (typeof $this.data("slider-mousewheel-release-on-edges") !== "undefined")?$this.data("slider-mousewheel-release-on-edges"):false,
                zoom: (typeof $this.data("slider-zoom") !== "undefined")?$this.data("slider-zoom"):false
            });
            swiper.on('init', function() {
                if (typeof window[$this.data("slider-oninit")] === 'function') {
                    window[$this.data("slider-oninit")](this);
                    this.update();
                }
            });
            swiper.on('resize', function() {
                if (typeof window[$this.data("slider-onresize")] === 'function') {
                    window[$this.data("slider-onresize")](this);
                    this.update();
                }
            });
            swiper.init();
        }).addClass("slider-events-added");

        $s.each(function(){
            var $this = $(this);

            if (typeof $this.data("slider-control-thumbs") !== "undefined" && typeof $this.find('.swiper-container')[0].swiper !== "undefined")
            {
                var $thumbs_swiper = $($this.data("slider-control-thumbs"));
                var $thumbs_swiper_c = $thumbs_swiper.find('.swiper-container');
                if ($thumbs_swiper_c.length) {

                    if (typeof $thumbs_swiper_c[0].swiper !== "undefined")
                    {
                        var thumbs_swiper = $thumbs_swiper_c[0].swiper;
                        thumbs_swiper.thumbs_goal_swiper = $this.find('.swiper-container')[0].swiper;
                        thumbs_swiper.on('tap', function (e) {
                            //console.log("sdf", this.clickedIndex);
                            var clicked = this.clickedIndex;
                            //swiper.activeIndex = clicked; //don't need this
                            //swiper.updateClasses(); //don't need this
                            $(this.slides).removeClass('is-selected');
                            $(this.clickedSlide).addClass('is-selected');
                            this.slideTo(clicked, this.params.speed, false);
                            this.thumbs_goal_swiper.slideTo(clicked, this.thumbs_goal_swiper.params.speed, true);
                        });
                    };

                    $this.find('.swiper-container')[0].swiper.on('transitionStart transitionEnd', function (e) {
                        var $o = $(this.$el.closest(".swiper-slider").data("slider-control-thumbs"));
                        if (typeof $o.find('.swiper-container')[0].swiper !== "undefined")
                        {
                            var thumbs_swiper = $o.find('.swiper-container')[0].swiper;
                        }
                        var activeIndex = this.activeIndex;
                        if (typeof thumbs_swiper !== "undefined")
                        {
                            console.log("sdf", activeIndex);
                            $(thumbs_swiper.slides).removeClass('is-selected');
                            $(thumbs_swiper.slides).eq(activeIndex).addClass('is-selected');
                            thumbs_swiper.slideTo(activeIndex, thumbs_swiper.params.speed, false);
                        }
                    });
                    $this.find('.swiper-container')[0].swiper.transitionEnd();
                }
            }

            $this.find('.swiper-container')[0].swiper.on('slideChangeStart', function (swiper) {
                var $activeSlide = $(swiper.slides).eq(swiper.activeIndex);
                $activeSlide.find(".swiper-lazy-preloader").css({
                    top: swiper.height/2
                });
                $(swiper.slides).find(".video-block").each(function(){
                    var player = $(this).data("player");
                    if (player)
                    {
                        player.pauseVideo();
                    }
                });
            });

            if ($this.filter(".swiper-slider--steps").length) {
                $this.find('.swiper-container')[0].swiper.on('transitionStart', function (e) {
                    var activeIndex = this.activeIndex;
                    var $bullet = $(this.pagination.bullets[activeIndex]);
                    var $scroll = $bullet.closest(".step-pagination__inner2");
                    goto_object($bullet, $scroll, -1*$scroll.width()/2 - 15, true);
                });
            }


            $this.find('.swiper-container')[0].swiper.on('progress', function (progress) {
                progressCallback(this);
            });
            progressCallback($this.find('.swiper-container')[0].swiper);


            $this.find('.swiper-container')[0].swiper.on('onLazyImageReady', function (swiper) {
                swiper.setWrapperTransition(swiper.params.speed);
                swiper.updateAutoHeight();
            });

        });

        function progressCallback(swiper) {
            var $slider = $(swiper.$el).closest(".swiper-slider");
            $slider.removeClass("swiper-slider--start swiper-slider--end");
            if (swiper.progress <= 0) {
                $slider.addClass("swiper-slider--start");
            }
            if (swiper.progress >= 1) {
                $slider.addClass("swiper-slider--end");
            }
        }

        $(window).on("resize orientationchange", function(){
            delay_slider_resize(function(){
                $(".swiper-slider.slider-events-added").each(function(){
                    if ($(this).data("reinitialize-on-resize") && typeof $(this).find(".swiper-container")[0].swiper !== "undefined")
                    {
                        var swiper = $(this).find(".swiper-container")[0].swiper;
                        swiper.destroy(true, true);
                        slider_swiper_init($(this));
                    }
                });
            }, 300);
        });

        $(window).trigger("resize-swiper-wrapper-center-if-less");
    }
}

function swiper_container_padding_offset_update(swiper) {
    var padding = 10;
    if (window.innerWidth < 1260 && window.innerWidth >= 768) {
        padding = 25;
    }
    var ipadding = 30;
    if (window.innerWidth < 1260) {
        ipadding = 20;
    }
    if (window.innerWidth < 768) {
        ipadding = 10;
    }
    swiper.params.slidesOffsetBefore = (window.innerWidth - $('.container').first().outerWidth())/2 + padding - ipadding;
    swiper.params.slidesOffsetAfter = swiper.params.slidesOffsetBefore;
}

var delay_slider_resize = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();



/*

Image Zoom

*/


function zoom_init($o) {
    if (typeof $o === "undefined") $o = $(".zoom-container");
    if (responsive_type() > 767) {
        $o.not(".zoom-inited").each(function () {
            $o.zoom({
                duration: 0
            });
            $(this).addClass("zoom-inited");
        });
    }

    if (!$("body").hasClass("zoom-inited")) {

        $(window).on('resize', function (e) {
            if (responsive_type() <= 767) {
                $('.zoom-inited').trigger('zoom.destroy');
            }
            else {
                zoom_init();
            }
        });

        $("body").addClass("zoom-inited");
    }
}




/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("body").hasClass("fancybox-inited")) {

        if ($().fancybox) {
            $.fancybox.options_modal = {
                slideShow: false,
                hash: false,
                clickContent: false,
                clickSlide: false,
                clickOutside: false,
                keyboard: false,
                mobile: {
                    clickSlide: false
                },
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                baseClass: "fancybox-container--popup",
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                afterLoad: function (instance, current) {
                    console.log("afterLoad", current, current.$content);
                    current.$content.wrap("<div>");
                },
            };
            if ($().tooltipster) {
                $.fancybox.defaults.beforeLoad = function (instance, current) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                };
            }
            $.fancybox.defaults.hash = false;
            $.fancybox.defaults.errorTpl = '<div><div class="panel panel--compact"><div class="panel__content"><p>Произошла ошибка при загрузке. <br /> Попробуйте ещё раз.</p></div></div></div>';
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });

        $("body").addClass("fancybox-inited");
    }

    if (jQuery().fancybox) {

        var options = {
            slideShow: false,
            hash: true,
            loop: true,
            idleTime: 10,
            margin: [44, 0],
            gutter: 50,
            keyboard: true,
            animationEffect: "zoom",
            arrows: true,
            infobar: true,
            toolbar: true,
            buttons: [
                'slideShow',
                'fullScreen',
                'thumbs',
                'close'
            ],
            thumbs: {
                autoStart: false,
                hideOnClose: true
            },
        };

        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax']");
        $fancybox_links_ajax.each(function () {
            if ($(this).attr("data-type") == "ajax" || $(this).attr("data-src")) {
                options = $.fancybox.options_modal;
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}



/*

Yandex Map UI

 */

function map_init() {
    if (!$("body").data("map-global-inited")) {
        $(window).on("scroll map-load-on-scroll-init", function(e){
            $(".js-map-load-on-scroll").not(".js-map-load-on-scroll--inited").each(function(){
                if ($(window).scrollTop() + $(window).height() >= $(this).offset().top) {
                    $(this).addClass("js-map");
                    map_load();
                    $(this).addClass("js-map-load-on-scroll--inited");
				}
            });
        }).trigger("map-load-on-scroll-init");
        $("body").data("map-global-inited", true);
	}
}

function map_load() {
    if ($(".js-map").length && !$("#api-maps-yandex").length) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'api-maps-yandex';
        script.src = '//api-maps.yandex.ru/2.1/?lang=ru-RU&onload=map_load_init';
        document.head.appendChild(script);
    }
    else if ($("#api-maps-yandex").length) {
        map_load_init();
    }
}

function map_load_init() {
    ymaps.ready({
        require: ['Map'],
        successCallback: function (ym) {
            ymaps.layout.storage.add('app#markerAccuracy', ymaps.templateLayoutFactory.createClass($("#tmpl-map-marker-accuracy").html()));
            ymaps.layout.storage.add('app#marker', ymaps.templateLayoutFactory.createClass($("#tmpl-map-marker").html()));

            $(".js-map:not(.js-map--inited)").each(function () {
                var $map = $(this);

                var type = 'yandex#map';

                var point = false;
                if ($map.data("point")) {
                    point = $map.data("point").split(",");
                }

                var center = false;
                if ($map.data("center")) {
                    center = $map.data("center").split(",");
                }

                var zoom = $(this).data("zoom");

                var cookie_map_zoom = $.cookie("map_zoom");
                var cookie_map_center = $.cookie("map_center");
                var cookie_map_type = $.cookie("map_type");
                if (!center && cookie_map_center)
                {
                    var cookie_map_center_arr = cookie_map_center.split(",");
                    if ($.isNumeric(cookie_map_center_arr[0]) && $.isNumeric(cookie_map_center_arr[1])) {
                        center = cookie_map_center_arr;
                    }
                }
                if (!center) {
                    center = [0,0]
                }
                if (!zoom && cookie_map_zoom)
                {
                    zoom = cookie_map_zoom;
                }
                if (!zoom)
                {
                    zoom = 10;
                }
                if (cookie_map_type)
                {
                    type = cookie_map_type;
                }

                var map_state = {
                    center: (center) ? center : point,
                    type: type,
                    zoom: zoom,
                    controls: []
                };

                if ((!cookie_map_center && !cookie_map_zoom || $map.data("bounds-primary")) && $map.data("bounds")) {
                    map_state.bounds = $map.data("bounds");
                }

                var map_options = {
                    adjustZoomOnTypeChange: false,
                    avoidFractionalZoom: true
                };

                // Ограничение на макс. и мин. уровень зума
                var minZoom = ($map.data("zoom-min")) ? $map.data("zoom-min") : 3;
                var maxZoom = ($map.data("zoom-max")) ? $map.data("zoom-max") : 19;
                map_options.minZoom = minZoom;
                map_options.maxZoom = maxZoom;

                var map = new ymaps.Map(this, map_state, map_options);

                $map.data("map", map);

                if ($(this).data("scroll-zoom-disable")) {
                    map.behaviors.disable('scrollZoom');
                }

                var objects_collection = new ymaps.GeoObjectCollection({}, {
                    preset: "islands#redCircleIcon",
                    strokeWidth: 4,
                    geodesic: true
                });
                $map.data("objects_collection", objects_collection);

                map.geoObjects.add(objects_collection);


                var placemark = new ymaps.Placemark(point, {}, {
                    preset: 'islands#icon',
                    iconColor: '#ef8fce'
                });
                objects_collection.add(placemark);

                map_restrict_maparea($map);

                map.events.add('click', function (e) {
                    e.get('target').balloon.close();
                });

                $map.addClass("map--inited");

            });
        }
    });
}


// Ограничение области, на которые можно перемещаться до существующих, чтобы не было пустых областей без карты
function map_restrict_maparea($map) {
    var map = $map.data("map");
    map.action.setCorrection(function (tick) {
        var projection = map.options.get('projection');
        var mapSize = map.container.getSize();
        var tickCenter = projection.fromGlobalPixels(tick.globalPixelCenter, tick.zoom);
        var top = [tick.globalPixelCenter[0], tick.globalPixelCenter[1] - mapSize[1] / 2];
        var bot = [tick.globalPixelCenter[0], tick.globalPixelCenter[1] + mapSize[1] / 2];
        var tickTop = projection.fromGlobalPixels(top, tick.zoom);
        var tickBot = projection.fromGlobalPixels(bot, tick.zoom);

        if (tickTop[0] > 85) {
            tick.globalPixelCenter = projection.toGlobalPixels(
                [85, tickCenter[1]],
                tick.zoom
            );
            tick.globalPixelCenter = [tick.globalPixelCenter[0], tick.globalPixelCenter[1] + mapSize[1] / 2];
            tick.duration = 0;
        }

        if (tickBot[0] < -85) {
            tick.globalPixelCenter = projection.toGlobalPixels(
                [-85, tickCenter[1]],
                tick.zoom
            );
            tick.globalPixelCenter = [tick.globalPixelCenter[0], tick.globalPixelCenter[1] - mapSize[1] / 2];
            tick.duration = 0;
        }

        return tick;
    });
}




/*

Replace elements with link with phone

 */

function tel_replace_init() {

    $(".touchevents .replace-with-tel-link").not(".replace-with-tel-link-inited").each(function(){
        var tel = $(this).text().replace(/[^\d\+]/g, "");
        if ($(this).data("tel")) {
            tel = $(this).data("tel");
        }
        if (tel)
        {
            $(this).replaceWith("<a href='tel:"+tel+"'>"+$(this).html()+"</a>");
        }
        $(this).addClass("replace-with-tel-link-inited");
    });
}





/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    $(document).ajaxStop(function () {
        expand_it_init_prepare();
    });

    window['expand_it_window_width'] = $(window).width();
    $(window).on("load", function () {
        expand_it_init_prepare(null, true);
    });
    $(window).on("resize", function () {
        if($(this).width() != window['expand_it_window_width']){
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        e.preventDefault();
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        expand_it_trigger($(this), $o, e.type === "expand-it");
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        if ($(e.target).hasClass("expand-it-container") && !$(e.target).hasClass("expand-it-container-overflow-hidden") && $(e.target).hasClass("before-transition"))
        {
            var height_default = 0;
            if ($(this).find(".expand-it-container-height-default").length)
            {
                height_default = $(this).find(".expand-it-container-height-default").height();
            }
            if (parseInt($(this).css("max-height"), 10) > height_default)
            {
                $(this).addClass("overflow-visible");
            }
            else
            {
                $(this).removeClass("overflow-visible");
            }
        }
        if ($(e.target).hasClass("expand-it-container") && $(e.target).hasClass("expand-it-container-max-height-auto") && $(e.target).hasClass("before-transition"))
        {
            var id = $(this).attr("id");
            setTimeout(function(){
                removeCSSRule("rule-"+id);
            }, 300);
        }
        $(e.target).removeClass("before-transition");
    });

    if (location.hash)
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("notransition");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("notransition");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this).find(".expand-it-container");
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    $(".expand-it.active[href='#"+$this.attr("id")+"']").trigger("expand-it");
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    if ($wrapper.offset().top < $(window).scrollTop() + $(".header__top--fixed").outerHeight()) {
                        goto_object($wrapper);
                    }
                }, 250);
            }
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function(){
        var $o = $(this);
        var $inner = $o.find(".expand-it-inner");
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        $hide_parent = $('[href=\'#'+id+'\'], [data-expand-selector=\'#'+id+'\']').filter('[data-expand-selector-hide-parent]');
        $hide_parent.each(function(){
            $(this).closest('[data-expand-selector-parent]').toggleClass('hidden', $inner.outerHeight() <= $o.outerHeight());
        });
        height = $inner.outerHeight(true);
        rules += " #"+id+".active { max-height: "+ height+"px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}



/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var $o = $($(this).attr("href"));
        if ($o.length) {
            e.preventDefault();
            var $this = $(this);
            if ($this.closest(".goto-list").length)
            {
                $this.closest(".goto-list").find("li").removeClass("active");
                $this.closest("li").addClass("active");
                tabs_update_pointer($this.closest(".tabs"));
            }
            if ($this.closest(".goto-hash-change").length)
            {
                location_hash_update($this.attr("href"));
            }
            goto_object($o);
        }
    });
}


function goto_object($o, $container, offset, horz) {
    var container_default = false;
    if (typeof $container == "undefined" || !$container || !$container.length) {
        $container = $("html, body");
        container_default = true;
    }
    if ($o.length) {
        var scroll_key = (horz)?"scrollLeft":"scrollTop";
        var offset_key = (horz)?"left":"top";
        var scroll_val = $o.offset()[offset_key];
        if (container_default) {
            //scroll_val -= $(".header").outerHeight()
        }
        else {
            scroll_val = scroll_val - $container.offset()[offset_key] + $container[scroll_key]();
        }
        console.log(scroll_val);
        if (offset) {
            scroll_val += offset;
        }
        $container.addClass("scroll-animated");
        var options = {};
        options[scroll_key] = scroll_val;
        console.log(offset);
        $container.stop(true, true).animate(options, 500, function() {
            $container.removeClass("scroll-animated");
        });
    }
}

function activate_goto_link($obj)
{
    $obj.addClass("active").siblings().removeClass("active")
        .end().parent("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("click-tabs-fixed-center");
}


/*

Hide/Show blocks

 */

function toggle_element_init()
{
    $(document).on("click click-pseudo change", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var class_name = "hidden";
        if ($(this).data("class")) {
            class_name = $(this).data("class");
        }
        var change_label = false;
        if ($(this).hasClass("toggle-element")) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass("active");
                $o.toggleClass(class_name);
            }
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass("active")) change_label = true;
            $(this).addClass("active");
            $o.removeClass(class_name);
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass("active")) change_label = true;
            $(this).removeClass("active");
            $o.addClass(class_name);
        }
        if (change_label) {
            var label = $(this).html();
            $(this).html($(this).attr("data-label"));
            $(this).attr("data-label", label);
        }
    });
}




/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
    });

    $(document).on("click", ".touchevents .touch-focused a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", ".touchevents *", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

}

/*

Tooltips

 */

function tooltip_init($o, callback, options) {

    if (!$().tooltipster) return;

    if (!$("body").hasClass("tooltip-inited")) {

        $(document).on("mouseup", ".tooltip-closest-hide", function (e) {
            var $this = $(this);
            var $tooltip = $this.closest(".tooltipster-base");
            if ($tooltip.length)
            {
                $.each($.tooltipster.instances(), function(k, v){
                    if ($tooltip.attr("id") == v.__namespace)
                    {
                        v._$origin.tooltipster("close");
                        return false;
                    }
                });
            }
        });

        $(document).on('touchstart touchmove', '.tooltip-popover-for-mobiles.tooltipster-sidetip', function(e) {
            e.stopPropagation();
            $("html").addClass("tooltip-popover-for-mobiles-opened");
        });

        $(document).on("touchstart", ".tooltip-popover-for-mobiles-opened body", function(e){
            $("html").removeClass("tooltip-popover-for-mobiles-opened");
        });

        $(document).on("keyup", function (e) {
            if (e.keyCode == 27) {
                var instances = $.tooltipster.instances();
                $.each(instances, function(i, instance){
                    instance.close();
                });
            }
        });

        $(window).on("scroll", function (e) {
            var instances = $.tooltipster.instances();
            $.each(instances, function(i, instance){
                if (instance._$origin.tooltipster('option', 'theme')[0].indexOf("tooltipster-popover") < 0)
                {
                    instance.close();
                }
            });
        });

        $("body").addClass("tooltip-inited")
    }

    var options_default = {
        interactive: false,
        interactiveTolerance: 200,
        animationDuration: 200,
        delay: [50, 50],
        distance: 5,
        side: "top",
        maxWidth: 350,
        contentAsHTML: true,
        repositionOnScroll: false,
        updateAnimation: false,
        theme: ["tooltipster-default"],
        functionInit: function (instance, helper) {
            var $origin = $(helper.origin),
                dataOptions = $origin.attr('data-tooltipster');

            if (dataOptions) {
                dataOptions = JSON.parse(dataOptions);
                if (typeof dataOptions["theme"] !== "undefined")
                {
                    dataOptions["theme"] = dataOptions["theme"] + " tooltipster-default";
                }
                $.each(dataOptions, function (name, option) {
                    instance.option(name, option);
                });
            }
            $(this).attr("data-tooltip-text", $(this).attr("title"));
            if ($(helper.origin).data("title")) {
                $(helper.origin).tooltipster('content', $(helper.origin).data("title"));
            }
            else if ($(helper.origin).attr("data-tooltip-text")) {
                $(helper.origin).tooltipster('content', $(helper.origin).attr("data-tooltip-text"));
            }
        },
        functionBefore: function (instance, helper) {
            $(helper.origin).addClass("tooltip-active");
            if ($(helper.origin).hasClass("tooltip-popover-for-mobiles"))
            {
                $("html").addClass("tooltip-popover-for-mobiles-opened");
            }
            if ($(helper.origin).data("tooltip-url")) {
                if ($(helper.origin).data('ajax') !== 'cached') {
                    $(helper.origin).tooltipster('content', "Загрузка...");
                    $.ajax({
                        type: 'get',
                        cache: false,
                        url: $(helper.origin).data("tooltip-url"),
                        success: function (data) {
                            $(helper.origin).tooltipster('content', data);
                            $(helper.origin).data('ajax', 'cached');
                            $(instance.elementTooltip()).find(".tooltip-hide").on("click.tooltip-hide", function (e) {
                                e.preventDefault();
                                instance.close();
                            });
                        }
                    });
                }
            }
            /*else {
                if ($(helper.origin).data("title")) {
                    $(helper.origin).tooltipster('content', $(helper.origin).data("title"));
                }
                else if ($(helper.origin).attr("data-tooltip-text")) {
                    $(helper.origin).tooltipster('content', $(helper.origin).attr("data-tooltip-text"));
                }
            }*/
            var instances = $.tooltipster.instances();
            $.each(instances, function(i, instance){
                instance.close();
            });
        },
        functionReady: function (instance, helper) {
            if ($(instance.elementOrigin()).filter(".tooltip-disabled").length)
            {
                $(instance.elementTooltip()).addClass("hidden");
            }
            $(instance.elementTooltip()).find(".tooltip-hide").off("click.tooltip-hide").on("click.tooltip-hide", function (e) {
                e.preventDefault();
                instance.close();
            });
            if (typeof $(helper.origin).data("active-index") !== "undefined")
            {
                $(instance.elementTooltip()).find(".menu-list li").eq($(helper.origin).data("active-index")).addClass("active").siblings().removeClass("active");
            }
        },
        functionAfter: function (instance, helper) {
            $(helper.origin).removeClass("tooltip-active");
            if ($(helper.origin).hasClass("tooltip-popover-for-mobiles"))
            {
                $("html").removeClass("tooltip-popover-for-mobiles-opened");
            }
        },
    };

    if (typeof $o === "undefined") $o = $('.tooltip');

    $.extend(options_default, options);

    if ($(".touchevents").length) $.extend(options_default, {trigger: "click"});
    if (typeof callback !== "undefined") $.extend(options_default, {functionAfter: callback});
    $o.not('.tooltip-inited').each(function () {
        var options_current = $.extend({}, options_default);

        if ($(this).hasClass("tooltip-popover"))
        {
            options_current["functionPosition"] = function(instance, helper, position){
                var gridBcr = helper.origin.getBoundingClientRect(),
                    $clone = $(helper.tooltipClone).find('.tooltipster-box'),
                    arrowSize = parseInt($clone.css('margin-left'));
                var left_coord = gridBcr.left;
                var top_coord = position.coord.top;
                var dx = 0;
                var dy = 0;
                if ($(helper.origin).hasClass("tooltip-popover-right"))
                {
                    left_coord = gridBcr.right - $clone.outerWidth();
                }
                if ($(helper.origin).hasClass("tooltip-popover-margin"))
                {
                    dx = 15;
                    dy = -40;
                    if ($(helper.origin).hasClass("tooltip-popover-right"))
                    {
                        dx = -1*dx;
                    }
                    if (position.side == "top")
                    {
                        dy = -1*dy;
                    }
                }
                left_coord += dx;
                top_coord += dy;
                // override these
                position.coord = {
                    // move the tooltip so the arrow overflows the grid
                    left: left_coord - arrowSize,
                    top: top_coord
                };

                return position;
            };
            options_current["side"] = "bottom";
            options_current["trigger"] = "custom";
            if ($(".touchevents").length)
            {
                options_current["triggerOpen"] = {
                    tap: true
                };
                options_current["triggerClose"] = {
                    tap: true,
                    scroll: true
                };
            }
            else
            {
                options_current["triggerOpen"] = {
                    click: true
                };
                options_current["triggerClose"] = {
                    click: true,
                    scroll: true
                };
            }
            options_current["interactive"] = true;
            options_current["viewportAware"] = false;
            options_current["distance"] = 5;
            options_current["theme"] = "tooltipster-popover";
            var $ds = $(this).closest(".dropdown-selection");
            if ($ds.length && $ds.find(".dropdown-selection-content").length)
            {
                $.extend(options_current, {content: $ds.find(".dropdown-selection-content")});
            }
            $ds.on("click", function(e){
                e.stopPropagation();
            });
        }
        if ($(this).hasClass("tooltip-notouch")) {
            options_current["trigger"] = "custom";
            if (!Modernizr.touchevents) {
                options_current["triggerOpen"] = {
                    mouseenter: true,
                    touchstart: false
                };
                options_current["triggerClose"] = {
                    mouseleave: true,
                    originClick: false,
                    touchleave: false
                };
            }
        }
        if ($(this).hasClass("tooltip-popover-for-mobiles"))
        {
            options_current["theme"] = "tooltipster-popover tooltip-popover-for-mobiles";
        }
        if ($(this).data("tooltip-trigger")) $.extend(options_current, {trigger: $(this).data("tooltip-trigger")});
        if ($(this).is("input,textarea") && !$(this).data("tooltip-disable-input-behavior")) {
            $.extend(options_current, {trigger: "custom", autoClose: false});
            $(this).on("focus", function () {
                $(':input.tooltip-inited').not(this).tooltipster('hide');
                $(this).tooltipster('show');
            });
        }
        if ($(this).data("tooltip-url")) $.extend(options_current, {content: "Загрузка..."});
        if (typeof $(this).data("interactive") !== "undefined") $.extend(options_current, {interactive: $(this).data("interactive")});
        $(this).tooltipster(options_current).addClass("tooltip-inited");
    });
}



var addCSSRule = function (sheet_id, rules) {
    $("#" + sheet_id).remove();
    $("<style type='text/css' id='" + sheet_id + "'>" + rules + "</style>").appendTo("head");
};

var removeCSSRule = function (sheet_id) {
    $("#" + sheet_id).remove();
};

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();


var forceRedraw = function(element){

    if (!element) { return; }

    var n = document.createTextNode(' ');
    var disp = element.style.display;  // don't worry about previous display style

    element.appendChild(n);
    element.style.display = 'none';

    setTimeout(function(){
        element.style.display = disp;
        n.parentNode.removeChild(n);
    },20); // you can play with this timeout to make it as short as possible
}

function fix_touch_hovers()
{
    if (Modernizr.touchevents)
    {
        try
        {
            var count = 0;
            var ignore = /:hover/;
            for (var i=0; i<document.styleSheets.length; i++)
            {
                var sheet = document.styleSheets[i];
                for (var j=sheet.cssRules.length-1; j>=0; j--)
                {
                    var rule = sheet.cssRules[j];
                    if (rule.type === CSSRule.STYLE_RULE && ignore.test(rule.selectorText))
                    {
                        sheet.deleteRule(j);
                        count++;
                    }
                }
            }
        }
        catch(e){}
    }
}


/*

Time Picker

 */

function time_picker() {
    $(".time-picker").not(".time-picker-inited").each(function () {
        if (!Modernizr.touchevents) {
            $(this).timeEntry({
                show24Hours: true,
                timeSteps: [1, 1, 0],
                spinnerImage: 'empty.png',
                minTime: '09:00',
                maxTime: '21:00',
                spinnerIncDecOnly: true,
                spinnerSize: [40, 40, 0],
                spinnerRepeat: [250, 100]
            });
            $(this).on("focus", function(){
                if (!$(this).val()) {
                    $(this).val((new Date()).getHours().toString().padStart(2, "0") + ":" + (new Date()).getMinutes().toString().padStart(2, "0"));
                }
            });
        }
        /*else {
            $(this).timepicker({
                appendTo: $(this).parent(),
                timeFormat: 'H:i',
                step: 30,
                //minTime: $(this).data("min"),
                //maxTime: $(this).data("max"),
                minTime: '09:00',
                maxTime: '21:00',
                showOn: ['focus', 'click']
            });
        }*/
        $(this).addClass("time-picker-inited");
    });
}


/*

Date Picker

 */

function date_picker()
{
    $(".date-picker").not(".date-picker-events-binded").addClass("date-picker-events-binded").each(function(){
        $datepick = $(this);
        $datepick.datepick({
            renderer: $.extend(
                {}, $.datepick.defaultRenderer,
                { picker: '<div class="datepick"><div class="datepick-nav"><div class="datepick-nav-year">{link:prevJump}<span class="datepick-nav-year-name"></span>{link:nextJump}</div><div class="datepick-nav-month">{link:prev}<span class="datepick-nav-month-name"></span>{link:next}</div></div>{months}{popup:start}<div class="datepick-ctrl">{link:clear}{link:close}</div>{popup:end}<div class="datepick-clear-fix"></div></div>'}
            ),
            dateFormat: ($(this).data("datepicker-format")) ? $(this).data("datepicker-format") : 'd M yyyy',
            monthNamesShort: ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
            firstDay: 1,
            minDate: $(this).data("min-date") || "-50y",
            maxDate: $(this).data("max-date") || "T",
            prevText: '<',
            todayText: 'MM',
            nextText: '>',
            useMouseWheel: false,
            commandsAsDateFormat: true,
            showOtherMonths: false,
            selectDefaultDate: true,
            showAnim: '',
            pickerClass: ($(this).data("datepicker-class")) ? $(this).data("datepicker-class") : '',
            popupContainer: ($(this).data("datepicker-container")) ? $(this).closest($(this).data("datepicker-container")) : $(this).parent(),
            rangeSelect: $(this).data("range-select"),
            monthsToShow: ($(this).data("months-to-show")) ? $(this).data("months-to-show") : 1,
            onSelect: function(date) {
                $this = $(this);
                datepick_range_set($this, date[0]);
                if ($this.is("input")) $this.trigger("change").trigger("blur");
            },
            onShow: function(picker, inst) {
                setTimeout(function(){
                    var $picker_popup = picker.parent();
                    if ($picker_popup.length)
                    {
                        var $scrollable = $(inst.elem).closest(".scrollable");
                        if (!$scrollable.length) $scrollable = $("body");
                        $picker_popup.addClass("hidden");
                        var scrollheight = $scrollable[0].scrollHeight;
                        $picker_popup.removeClass("hidden");
                        var maxoffset = $scrollable.offset().top + $scrollable.scrollTop() + $picker_popup.offset().top + $picker_popup.outerHeight();
                        if ((maxoffset > scrollheight && $(inst.elem).data("from-bottom") == "auto") || $(inst.elem).data("from-bottom"))
                        {
                            $picker_popup.addClass("from-bottom");
                        }
                        $picker_popup.addClass("active");
                    }
                }, 10);
                picker.find('.datepick-cmd-prev,.datepick-cmd-prevJump').html('<svg class="icon"><use xlink:href="#icon-chevron-left"></use></svg>');
                picker.find('.datepick-cmd-next,.datepick-cmd-nextJump').html('<svg class="icon"><use xlink:href="#icon-chevron-right"></use></svg>');
                picker.find('.datepick-nav-month-name').text($.datepick.formatDate('MM', $.datepick.newDate(inst.drawDate.getFullYear(), inst.drawDate.getMonth()+1, 1)));
                picker.find('.datepick-nav-year-name').text($.datepick.formatDate('yyyy', $.datepick.newDate(inst.drawDate.getFullYear(), inst.drawDate.getMonth()+1, 1)));
                picker.find('.datepick-month a').removeAttr("title");
                if ($.fn.mousewheel)
                {
                    inst.div.unmousewheel();
                }
            },
            onChangeMonthYear: function(year, month) {
                _this = $(this);
                setTimeout(function(){
                    year_format = '';
                    if (new Date().getFullYear() !== year) year_format = " yyyy";
                    $('.datepick-popup .datepick-cmd-today').text($.datepick.formatDate('MM' + year_format, $.datepick.newDate(year, month, 1)));
                }, 0);
            }
        });
        $datepick.on("datepick-range-set", function(){
            var date = $this.datepick('getDate');
            datepick_range_set($(this), date[0]);
            if ($(this).is("input")) $(this).trigger("change");
        });
    });
    if (!$("body").hasClass("datepick-popup-onresize-inited"))
    {
        $(window).on("resize", function(){
            $(".datepick-popup").each(function(){
                var $picker_popup = $(this);
                if (!$picker_popup.hasClass("from-bottom"))
                {
                    if ($picker_popup.offset().top + $picker_popup.outerHeight() > $("body").height())
                    {
                        $picker_popup.addClass("from-bottom");
                    }
                    else
                    {
                        $picker_popup.removeClass("from-bottom");
                    }
                }
            });
        });
        $("body").addClass("datepick-popup-onresize-inited");
    }
}

function datepick_range_set($this, date)
{
    var group = $this.data("datepicker-range-group");
    if (typeof group !== "undefined")
    {
        if ($this.data("datepicker-range-group-index") == 0) {
            $("input[data-datepicker-range-group='"+group+"'][data-datepicker-range-group-index='1']").datepick('option', 'minDate', date || null);
        }
        else {
            $("input[data-datepicker-range-group='"+group+"'][data-datepicker-range-group-index='0']").datepick('option', 'maxDate', date || null);
        }
    }
    var $maxfor = $($this.data("datepicker-range-max-for"));
    if ($maxfor.length)
    {
        $maxfor.datepick('option', 'maxDate', date || null);
    }
    var $minfor = $($this.data("datepicker-range-min-for"));
    if ($minfor.length)
    {
        $minfor.datepick('option', 'minDate', date || null);
    }
}

function mask_init() {
    $(".mask-phone-ru").not(".mask-phone-inited").each(function () {
        $(this).mask("+7 (999) 999-99-99", { autoclear: false });
        $(this).addClass("time-picker-inited");
    });
}



/*

Selectboxes

 */

function selectbox_init($o) {

    if (!$().select2) return;

    if (!$("body").hasClass("selectbox-inited")) {

        $(document).on("focus", '.select2-search__field', function(e) { // resolve focus problem in fancybox popup
            e.stopPropagation();
        });



        $.fn.select2.amd.define('select2/data/maximumSelectionLength1',[

        ], function (){
            function MaximumSelectionLength (decorated, $e, options) {
                this.maximumSelectionLength = options.get('maximumSelectionLength1');

                decorated.call(this, $e, options);
            }

            MaximumSelectionLength.prototype.query =
                function (decorated, params, callback) {
                    var self = this;

                    this.current(function (currentData) {
                        var count = currentData != null ? currentData.length : 0;
                        if (self.maximumSelectionLength1 > 0 &&
                            count >= self.maximumSelectionLength1) {
                            self.trigger('results:message', {
                                message: 'maximumSelected',
                                args: {
                                    maximum: self.maximumSelectionLength1
                                }
                            });
                            return;
                        }
                        decorated.call(self, params, callback);
                    });
                };

            return MaximumSelectionLength;
        });

        $("body").addClass("selectbox-inited");
    }

    $.fn.select2.amd.require([
        "select2/utils",
        "select2/dropdown",
        "select2/dropdown/attachContainer",
        "select2/dropdown/search",
        "select2/selection/search",
        "select2/dropdown/search",
        "select2/selection/base",
        "select2/selection/single",
        "select2/selection/multiple",
        "select2/core",
        "select2/selection/allowClear"
    ], function (Utils, DropdownAdapter, AttachContainer, DropdownSearch, SelectionSearch, DropdownSearch, BaseSelection, SingleSelection, MultipleSelection, Select2Core, AllowClear) {

        SingleSelection.prototype.render = function () {
            var $selection = SingleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--single');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<span class="select2-selection__rendered"></span>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<svg class="icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron-down"></use></svg>' +
                '</span>'
            );

            return $selection;
        };

        MultipleSelection.prototype.render = function () {
            var $selection = MultipleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--multiple');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<ul class="select2-selection__rendered"></ul>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<b role="presentation"></b>' +
                '</span>'
            );
            $selection.prop("tabindex", this.$element.data('old-tabindex'));

            return $selection;
        };

        DropdownSearch.prototype.render = function (decorated) {
            var $rendered = decorated.call(this);

            var $search = $(
                '<span class="select2-search select2-search--dropdown">' +
                '<div class="textfield-wrapper">' +
                '<input class="select2-search__field textfield" type="search" tabindex="-1"' +
                ' autocomplete="off" autocorrect="off" autocapitalize="off"' +
                ' spellcheck="false" role="textbox" placeholder=" " />' +
                '<label class="textfield-placeholder" for="">Поиск по названию</label>' +
                '<span class="textfield-reset"></span>' +
                '</div>' +
                '</span>'
            );

            this.$searchContainer = $search;
            this.$search = $search.find('input');

            $rendered.prepend($search);

            return $rendered;
        };

        SelectionSearch.prototype._transferTabIndex = function (decorated) {
            // remove tabindex transfer
        };

        SelectionSearch.prototype.searchRemoveChoice = function (decorated, item) {

            this.trigger('unselect', {
                data: item
            });

            this.$search.val('');
            this.handleSearch();
        };

        AllowClear.prototype.update = function (decorated, data) {
            decorated.call(this, data);

            this.$selection.removeClass("select2-selection--with-clear");
            if (this.$selection.find('.select2-selection__placeholder').length > 0 ||
                data.length === 0) {
                return;
            }

            var $remove = $(
                '<span class="select2-selection__clear">' +
                '&times;' +
                '</span>'
            );
            $remove.data('data', data);

            this.$selection.addClass("select2-selection--with-clear").find('.select2-selection__rendered').prepend($remove);
        };

        if (typeof $o === "undefined") $o = $('select.selectbox');
        $o.not('.selectbox-inited').each(function () {
            var options = {
                tags: false,
                theme: "default",
                dropdownAutoWidth: true,
                dropdownParent: $('.wrapall')
            };
            if ($(this).data("selectbox-show-values"))
            {
                options["templateSelection"] = function(state) {
                    if (!state.id) {
                        return state.text;
                    }
                    var $state = state.element.value;
                    return $state;
                };
            }
            if (!$(this).data("selectbox-search"))
            {
                options["minimumResultsForSearch"] = Infinity;
            }
            if ($(this).data("selectbox-autowidth"))
            {
                options["width"] = "auto";
            }
            if ($(this).data("selectbox-tags-source"))
            {
                options["dropdownParent"] = $(this).closest(".selectbox-tags__destination").find("select").parent();
                options["theme"] += " select2-container--tags-destination";
            }
            else
            {
                if ($(this).data("selectbox-tags"))
                {
                    var $towrap = $(this);
                    if ($(this).closest(".textfield-wrapper").length)
                    {
                        $towrap = $(this).closest(".textfield-wrapper");
                    }
                    var $cloned_select_html = $towrap.clone();
                    $towrap.wrap("<div class='selectbox-tags'>");
                    $cloned_select_html.insertAfter($towrap);
                    $towrap.wrap("<div class='selectbox-tags__source'>");
                    $cloned_select_html.wrap("<div class='selectbox-tags__destination'>");
                    options["dropdownParent"] = $towrap;
                    var $cloned_select_select = $cloned_select_html;
                    if ($cloned_select_html.find("select").length)
                    {
                        $cloned_select_select = $cloned_select_html.find("select");
                    }
                    var $cloned_select_placeholder = $cloned_select_html.find(".textfield-placeholder");
                    if ($cloned_select_placeholder.length)
                    {
                        $cloned_select_placeholder.html($cloned_select_placeholder.data("tags-label")?$cloned_select_placeholder.data("tags-label"):"");
                    }
                    $cloned_select_select.attr("data-placeholder", "").removeAttr("data-selectbox-aggregate-min");
                    $cloned_select_select.find("option[value!='']").remove();
                    $(this).removeAttr("name");
                    $(this).data("selectbox-tags-destination", $cloned_select_select);
                    $cloned_select_select.data("selectbox-tags-source", $(this));
                    if ($cloned_select_select.data("selectbox-tags-source"))
                    {
                        custom_select($cloned_select_select);
                    }
                    $(this).on("change", function(){
                        var $dest = $(this).data("selectbox-tags-destination");
                        sync_selects_append($(this)[0], $dest[0]);
                    });
                    $cloned_select_select.on("change", function(){
                        var $source = $(this).data("selectbox-tags-source");
                        sync_selects($(this)[0], $source[0]);
                    });
                    options["theme"] += " select2-container--tags-source";
                    options["containerCssClass"] = "select2-selection--notags";
                    options["dropdownCssClass"] = "select2-dropdown--notags";
                }
            }
            if (!$(this).data("selectbox-tags"))
            {
                options["containerCssClass"] = "select2-selection--notags";
                options["dropdownCssClass"] = "select2-dropdown--notags";
            }
            if ($(this).data("selectbox-tags-create"))
            {
                options["tags"] = $(this).data("selectbox-tags-create");
            }
            if ($(this).data("selectbox-allow-clear"))
            {
                options["allowClear"] = $(this).data("selectbox-allow-clear");
            }
            if ($(this).data("selectbox-limit"))
            {
                options["maximumSelectionLength"] = $(this).data("selectbox-limit");
            }
            if ($(this).prop("multiple"))
            {
                options["closeOnSelect"] = false;
            }
            /*if ($(this).closest(".fancybox-slide").length)
            {
                options["dropdownParent"] = $(this).closest(".textfield-wrapper");
            }*/
            if ($(this).data("selectbox-dropdown-parent"))
            {
                options["dropdownParent"] = $($(this).data("selectbox-dropdown-parent"));
            }
            if ($(this).data("selectbox-ajax"))
            {
                options["ajax"] = $(this).data("selectbox-ajax");
            }

            if ($(this).closest(".fancybox-slide").length)
            {
                options["theme"] += " select2-container--in-fancybox";
            }
            //$(this).find("option[value='']:empty").remove();
            $(this).off("change.selectbox").on("change.selectbox", function () {
                if (!$(this).prop("multiple")) {
                    $(this).select2('close');
                }
                $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val());
                if ($(this).hasClass("selectbox-change-tip"))
                {
                    var tip = $(this).find("option:selected").data("form-tip");
                    var $tip = $(this).closest(".form__item").find(".form__tip");
                    if (tip)
                    {
                        $tip.html(tip).removeClass("hidden");
                    }
                    else
                    {
                        $tip.html("").addClass("hidden");
                    }
                }
            }).select2(options).addClass("selectbox-inited");
            $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val());

            $(this).data('select2').on("results:all", function (params) {
                var $options = this.$dropdown.find(".select2-results__options");
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                }, 10);
            });

            $(this).off("select2:open").on("select2:open", function (e) {
                var _this = this;
                var $options = $(_this).data('select2').$dropdown.find(".select2-results__options");
                $(_this).data('select2').$dropdown.addClass("select2-container--dropdown");
                if ($(this).data("selectbox-dropdown-nowrap"))
                {
                    $(_this).data('select2').$dropdown.addClass("select2-container--dropdown-nowrap");
                }
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                    if (!$(_this).data('select2').$dropdown.closest(".select2-container--tags-source").length)
                    {
                        if ($(".touchevents").length) forceRedraw($(_this).data('select2').$dropdown[0]);
                        else $(_this).data('select2').$dropdown.css("transform", "translateZ(0)");
                    }
                }, 1);
            });

            $(this).off("select2:unselecting").on("select2:unselecting", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                opts.set('disabled', true);
                setTimeout(function() {
                    opts.set('disabled', false);
                }, 1);
            });

            $(this).off("select2:select").on("select2:select", function (e) {
                var _this = this;
                setTimeout(function() { // Hotfix for correct dropdown position if more than maximumSelectionLength
                    $(window).scrollTop($(window).scrollTop()+1);
                    $(window).scrollTop($(window).scrollTop()-1);
                }, 1);
            });

            $(this).off("select2:select select2:unselect select2:selection-aggregate").on("select2:select select2:unselect select2:selection-aggregate", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                var $rendered = select2.$selection.find(".select2-selection__rendered");
                if ($rendered.filter("span").length) {
                    var current_text = $.trim($rendered.filter("span").text());
                    if (current_text) {
                        $rendered.text(current_text);
                    }
                }

                /* http://stackoverflow.com/a/39787191 */
                var values = [];
                var output = "";
                var agg_min = $(this).data("selectbox-aggregate-min");
                $(this).find("option:selected").each(function(i, selected){
                    values[i] = $(selected).text();
                });
                if (values.length >= agg_min)
                {
                    output = "<li class='select2-selection__choice'>Выбрано "+values.length+"</li>";
                }
                if (output)
                {
                    $rendered.children().not(".select2-selection__clear").remove();
                    $rendered.prepend(output);
                }
            }).trigger("select2:selection-aggregate");

        });
    }, null, true);

}

function sync_selects(el1, el2) {
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else
    {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        for (var i = 0, len = options1.length; i < len; i++)
        {
            var val = options1[i].value;
            $(options2).filter("[value='"+val+"']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

function sync_selects_append(el1, el2) {
    var change_reverse = $(el1).data("change-reverse");
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        if (change_reverse) {
            options3 = options1;
            options1 = options2;
            options2 = options3;
            el3 = el1;
            el1 = el2;
            el2 = el3;
        }
        for (var i = 0, len = options1.length; i < len; i++) {
            var val = options1[i].value;
            if (!change_reverse) {
                var exist = $(options2).filter("[value='" + val + "']").length;
                if (!exist) {
                    $(el2).append($(options1[i]).clone());
                }
            }
            $(options2).filter("[value='" + val + "']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

(function ($) {
    $.fn.refreshDataSelect2 = function (data) {
        this.select2('data', data);

        // Update options
        var $select = $(this[0]);
        var options = data.map(function(item) {
            return '<option value="' + item.id + '">' + item.text + '</option>';
        });
        $select.html('<option value=""></option>');
        $select.append(options.join('')).trigger("change");
    };
})(jQuery);

$.fn.hasScrollBar = function() {
    return this.get(0).scrollHeight > this.outerHeight();
}

$.fn.hasHorzScrollbar = function() {
    return this.get(0).scrollWidth > this.width();
}
function location_hash_update(hash, loc) {
    if (history.replaceState) {
        history.replaceState({
            time: (new Date()).getTime(),
            hash: hash
        }, null, ((typeof loc !== "undefined" && loc) ? loc : "") + ((typeof hash !== "undefined" && hash) ? hash : ""));
    }
    else {
        location.hash = hash;
    }
}

function location_hash_remove() {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history) {
        history.pushState("", document.title, loc.pathname + loc.search);
    }
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

String.prototype.padStart = String.prototype.padStart ? String.prototype.padStart : function(targetLength, padString) {
    targetLength = Math.floor(targetLength) || 0;
    if(targetLength < this.length) return String(this);

    padString = padString ? String(padString) : " ";

    var pad = "";
    var len = targetLength - this.length;
    var i = 0;
    while(pad.length < len) {
        if(!padString[i]) {
            i = 0;
        }
        pad += padString[i];
        i++;
    }

    return pad + String(this).slice(0);
};




function getBrowserInfo() {
    var t,v = undefined;
    if (window.chrome) t = 'Chrome';
    else if (window.opera) t = 'Opera';
    else if (document.all) {
        t = 'IE';
        var nv = navigator.appVersion;
        var s = nv.indexOf('MSIE')+5;
        v = nv.substring(s,s+1);
    }
    else if (navigator.appName) t = 'Netscape';
    return {type:t,version:v};
}
function bookmark(a){
    var url = window.document.location;
    var title = window.document.title;
    var b = getBrowserInfo();
    if (b.type == 'IE' && 8 >= b.version && b.version >= 4) window.external.AddFavorite(url,title);
    else if (b.type == 'Opera') {
        a.href = url;
        a.rel = "sidebar";
        a.title = url+','+title;
        return true;
    }
    else if (b.type == "Netscape") window.sidebar.addPanel(title,url,"");
    else alert("Нажмите CTRL-D, чтобы добавить страницу в закладки.");
    return false;
}
