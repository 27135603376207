//RangeSlider

(function ($, document, window, navigator) {
    "use strict";
	
	var pluginCount = 0;
	
	var isTouch = (function () {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    }());
	
	var methods = {
		init: function (options) {

            // irs = ion range slider css prefix
            var baseHTML =
                '<span class="irs-grid"></span>' +
				'<span class="irs">' +
                '<span class="irs-line"><span class="irs-line-left"></span><span class="irs-line-mid"></span><span class="irs-line-right"></span></span>' +
                '<span class="irs-min">0</span><span class="irs-max">1</span>' +
                '<span class="irs-from">0</span><span class="irs-to">0</span><span class="irs-single">0</span>' +
                '</span>';
			
			var doubleHTML =
                '<span class="irs-diapason"></span>' +
				'<span class="irs-diapason-green"></span>' +
				'<span class="irs-diapason-silver-left"></span>' +
				'<span class="irs-diapason-silver-right"></span>' +
                '<span class="irs-slider from"></span>' +
                '<span class="irs-slider to"></span>';
			
			var disableHTML =
                '<span class="irs-disable-mask"></span>';
			
			return this.each(function () {
                var settings = $.extend({
                    min: null,
                    max: null,
                    from: null,
                    to: null,
					dataFrom: null,
					dataTo: null,
                    type: "double",
                    step: 1,
                    prefix: "",
                    postfix: "",
                    hasGrid: false,
                    hideMinMax: false,
                    hideFromTo: false,
                    prettify: true,
                    disable: false,
                    onChange: null,
                    onLoad: null,
                    onFinish: null
                }, options);

				var slider = $(this),
                    self = this,
                    value_array = null;
				
				if (slider.data("isActive")) {
                    return;
                }
                slider.data("isActive", true);

                pluginCount += 1;
                this.pluginCount = pluginCount;
				
				// check default values
                if (slider.prop("value")) {
                    value_array = slider.prop("value").split(";");
                }
				
				if (value_array && value_array.length > 1) {
					if (typeof settings.min !== "number") {
						settings.min = parseFloat(value_array[0]);
					}
					else {
						if (typeof settings.from !== "number") {
							settings.from = parseFloat(value_array[0]);
						}
					}
					if (typeof settings.max !== "number") {
						settings.max = parseFloat(value_array[1]);
					}
					else {
						if (typeof settings.to !== "number") {
							settings.to = parseFloat(value_array[1]);
						}
					}
				} 
				else if (value_array && value_array.length === 1) {
					if (typeof settings.min !== "number") {
						settings.min = parseFloat(value_array[0]);
					} else {
						if (typeof settings.from !== "number") {
							settings.from = parseFloat(value_array[0]);
						}
					}
				}
				
				// Set Min and Max if no
                if (typeof settings.min !== "number") {
                    settings.min = 10;
                }
                if (typeof settings.max !== "number") {
                    settings.max = 100;
                }
                
				// Set From and To if no
                if (typeof settings.from !== "number") {
                    settings.from = settings.min;
                }
                if (typeof settings.to !== "number") {
                    settings.to = settings.max;
                }
				
				// Set DataFrom and DataTo if no
				if (typeof settings.dataFrom !== "number") {
                    settings.dataFrom = settings.min;
                }
                if (typeof settings.dataTo !== "number") {
                    settings.dataTo = settings.max;
                }

                // extend from data-*
                if (typeof slider.data("min") === "number") {
                    settings.min = parseFloat(slider.data("min"));
                }
                if (typeof slider.data("max") === "number") {
                    settings.max = parseFloat(slider.data("max"));
                }
                if (typeof slider.data("from") === "number") {
                    settings.from = parseFloat(slider.data("from"));
                }
                if (typeof slider.data("to") === "number") {
                    settings.to = parseFloat(slider.data("to"));
                }
				if (typeof slider.data("datafrom") === "number") {
                    settings.dataFrom = parseFloat(slider.data("datafrom"));
                }
                if (typeof slider.data("datato") === "number") {
                    settings.dataTo = parseFloat(slider.data("datato"));
                }
                if (slider.data("step")) {
                    settings.step = parseFloat(slider.data("step"));
                }
                if (slider.data("prefix")) {
                    settings.prefix = slider.data("prefix");
                }
                if (slider.data("postfix")) {
                    settings.postfix = slider.data("postfix");
                }
                if (slider.data("hasgrid")) {
                    settings.hasGrid = slider.data("hasgrid");
                }
                if (slider.data("hideminmax")) {
                    settings.hideMinMax = slider.data("hideminmax");
                }
                if (slider.data("hidefromto")) {
                    settings.hideFromTo = slider.data("hidefromto");
                }
                if (slider.data("prettify")) {
                    settings.prettify = slider.data("prettify");
                }
				
				if (slider.data("disable")) {
					settings.disable = slider.data("disable");
				}

                // fix diapason
                if (settings.From < settings.min) {
                    settings.From = settings.min;
                }
                if (settings.To > settings.max) {
                    settings.To = settings.max;
                }
				if (settings.From > settings.To) {
					settings.From = settings.To;
				}
				if (settings.To < settings.From) {
					settings.To = settings.From;
				}
				
				var prettify = function (num) {
                    var n = num.toString();
                    if (settings.prettify) {
                        n = n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1 ");
                    }
                    return n;
                };
				
				var containerHTML = '<span class="irs" id="irs-' + this.pluginCount + '"></span>';
                slider[0].style.display = "none";
                slider.before(containerHTML);
				
				var $container = slider.prev(),
                    $body = $(document.body),
                    $window = $(window),
                    $rangeSlider,
                    $fieldMin,
                    $fieldMax,
                    $fieldFrom,
                    $fieldTo,
                    $fieldSingle,
                    $singleSlider,
                    $fromSlider,
                    $toSlider,
                    $activeSlider,
                    $diapason,
					$diapasonGreen,
					$diapasonSilverLeft,
					$diapasonSilverRight,
                    $grid;
				
				var allowDrag = false,
                    sliderIsActive = false,
                    firstStart = true,
                    numbers = {};

                var mouseX = 0,
                    fieldMinWidth = 0,
                    fieldMaxWidth = 0,
                    normalWidth = 0,
                    fullWidth = 0,
                    sliderWidth = 0,
                    width = 0,
                    left = 0,
                    right = 0,
                    minusX = 0,
                    stepFloat = 0;
				
				if (parseInt(settings.step, 10) !== parseFloat(settings.step)) {
                    stepFloat = settings.step.toString().split(".")[1];
                    stepFloat = Math.pow(10, stepFloat.length);
                }
				
				// #############################################################
				// ## private methods
				// #############################################################
				var removeHTML = function (silent) {
                    $container.find("*").off();
                    $window.off("mouseup.irs" + self.pluginCount);
                    $body.off("mouseup.irs" + self.pluginCount);
                    $body.off("mousemove.irs" + self.pluginCount);
                    $container.html("");

                    placeHTML(silent);
                };
				var placeHTML = function (silent) {
                    $container.html(baseHTML);
                    $rangeSlider = $container.find(".irs");

                    $fieldMin = $rangeSlider.find(".irs-min");
                    $fieldMax = $rangeSlider.find(".irs-max");
                    $fieldFrom = $rangeSlider.find(".irs-from");
                    $fieldTo = $rangeSlider.find(".irs-to");
                    $grid = $container.find(".irs-grid");

                    if (settings.hideMinMax) {
                        $fieldMin[0].style.display = "none";
                        $fieldMax[0].style.display = "none";
                        fieldMinWidth = 0;
                        fieldMaxWidth = 0;
                    }
                    if (settings.hideFromTo) {
                        $fieldFrom[0].style.display = "none";
                        $fieldTo[0].style.display = "none";
                    }
                    if (!settings.hideMinMax) {
                        $fieldMin.html(settings.prefix + prettify(settings.min) + settings.postfix);
                        $fieldMax.html(settings.prefix + prettify(settings.max) + settings.postfix);
                        fieldMinWidth = $fieldMin.outerWidth();
                        fieldMaxWidth = $fieldMax.outerWidth();
                    }
					
					$rangeSlider.append(doubleHTML);
                    $fromSlider = $rangeSlider.find(".from");
                    $toSlider = $rangeSlider.find(".to");
                    $diapason = $rangeSlider.find(".irs-diapason");
					$diapasonGreen = $rangeSlider.find(".irs-diapason-green");
					$diapasonSilverLeft = $rangeSlider.find(".irs-diapason-silver-left");
					$diapasonSilverRight = $rangeSlider.find(".irs-diapason-silver-right");

					setDiapason();

					$fromSlider.on("mousedown", function (e) {
						if (e.which == 1){
							e.preventDefault();
							e.stopPropagation();

							$(this).addClass("last");
							$toSlider.removeClass("last");
							calcDimensions(e, $(this), "from");
							allowDrag = true;
							sliderIsActive = true;
						}
					});
					$toSlider.on("mousedown", function (e) {
						if (e.which == 1){
							e.preventDefault();
							e.stopPropagation();

							$(this).addClass("last");
							$fromSlider.removeClass("last");
							calcDimensions(e, $(this), "to");
							allowDrag = true;
							sliderIsActive = true;
						}
					});

					if (isTouch) {
						$fromSlider.on("touchstart", function (e) {
							e.preventDefault();
							e.stopPropagation();

							$(this).addClass("last");
							$toSlider.removeClass("last");
							calcDimensions(e.originalEvent.touches[0], $(this), "from");
							allowDrag = true;
							sliderIsActive = true;
						});
						$toSlider.on("touchstart", function (e) {
							e.preventDefault();
							e.stopPropagation();

							$(this).addClass("last");
							$fromSlider.removeClass("last");
							calcDimensions(e.originalEvent.touches[0], $(this), "to");
							allowDrag = true;
							sliderIsActive = true;
						});
					}

					if (settings.to === settings.max) {
						$fromSlider.addClass("last");
					}
					
					var mouseup = function () {
                        if (allowDrag) {
                            sliderIsActive = false;
                            allowDrag = false;
                            $activeSlider.removeAttr("id");
                            $activeSlider = null;
                            setDiapason();
                            
                            getNumbers();
                        }
                    };
					$body.on("mouseup.irs" + self.pluginCount, function () {
                        mouseup();
                    });
					$window.on("mouseup.irs" + self.pluginCount, function () {
                        mouseup();
                    });
					$body.on("mousemove.irs" + self.pluginCount, function (e) {
                        if (allowDrag) {
                            mouseX = e.pageX;
                            dragSlider();
                        }
                    });
					$container.on("mouseup", function (e) {
                        if (allowDrag || settings.disable || e.which != 1) {
                            return;
                        }
                        moveByClick(e.pageX);
                    });
					if (isTouch) {
                        $window.on("touchend", function () {
                            if (allowDrag) {
                                sliderIsActive = false;
                                allowDrag = false;
                                $activeSlider.removeAttr("id");
                                $activeSlider = null;
                                setDiapason();
                                getNumbers();
                            }
                        });
                        $window.on("touchmove", function (e) {
                            if (allowDrag) {
                                mouseX = e.originalEvent.touches[0].pageX;
                                dragSlider();
                            }
                        });
                    }
					getSize();
					setNumbers(silent);
                    if (settings.hasGrid) {
                        setGrid();
                    }
                    if (settings.disable) {
                        setMask();
                    }
				};
				var getSize = function () {
                    normalWidth = $rangeSlider.width();
					if (normalWidth == 0)
						normalWidth = $rangeSlider.closest('div.form-group').width();
                    sliderWidth = $fromSlider.width();
                    fullWidth = normalWidth;// - sliderWidth;
                };
				var calcDimensions = function (e, currentSlider, whichSlider) {
                    getSize();

                    firstStart = false;
                    $activeSlider = currentSlider;
                    $activeSlider.attr("id", "irs-active-slider");

                    var _x1 = $activeSlider.offset().left,
                        _x2 = e.pageX - _x1;
                    minusX = _x1 + _x2 - $activeSlider.position().left;

                    if (whichSlider === "from") {
						left = 0;
						right = parseInt($toSlider.css("left"), 10);
					} else {
						left = parseInt($fromSlider.css("left"), 10);
						right = $rangeSlider.width();// - sliderWidth;
					}
                };
				var setDiapason = function () {
                    /*var _w = $fromSlider.width(),
                        _x = $.data($fromSlider[0], "x") || parseInt($fromSlider[0].style.left, 10) || $fromSlider.position().left,
                        _width = $.data($toSlider[0], "x") || parseInt($toSlider[0].style.left, 10) || $toSlider.position().left,
                        x = _x + (_w / 2),
                        w = _width - _x;
					if (w<0)
						w=0;*/
                    //$diapason[0].style.left = x + "px";
                    //$diapason[0].style.width = w + "px";
                };
				var dragSlider = function (manual_x) {
                    var x_pure = mouseX - minusX,
                        x;

                    if (manual_x) {
                        x_pure = manual_x;
                    } else {
                        x_pure = mouseX - minusX;
                    }
					
					if (x_pure < left) {
						x_pure = left;
					}
					if (x_pure > right) {
						x_pure = right;
					}
					setDiapason();

                    $.data($activeSlider[0], "x", x_pure);
                    getNumbers();

                    x = Math.round(x_pure);
                    $activeSlider[0].style.left = x + "px";
                };
				var getNumbers = function () {
                    var nums = {
                        input: slider,
                        slider: $container,
                        fromNumber: 0,
                        toNumber: 0,
                        fromPers: 0,
                        toPers: 0,
                        fromX: 0,
                        toX: 0,
						//added2
						fromDiapasonGreenNumber: 0,
						toDiapasonGreenNumber: 0,
						fromDiapasonGreenPers: 0,
                        toDiapasonGreenPers: 0,
                        fromDiapasonGreenX: 0,
                        fromDiapasonGreenX_pure: 0,
                        toDiapasonGreenX: 0,
                        toDiapasonGreenX_pure: 0,
						//added3
						fromDiapasonSLNumber: 0,
						toDiapasonSLNumber: 0,
						fromDiapasonSLPers: 0,
                        toDiapasonSLPers: 0,
                        fromDiapasonSLX: 0,
                        fromDiapasonSLX_pure: 0,
                        toDiapasonSLX: 0,
                        toDiapasonSLX_pure: 0,
						fromDiapasonSRNumber: 0,
						toDiapasonSRNumber: 0,
						fromDiapasonSRPers: 0,
                        toDiapasonSRPers: 0,
                        fromDiapasonSRX: 0,
                        fromDiapasonSRX_pure: 0,
                        toDiapasonSRX: 0,
                        toDiapasonSRX_pure: 0
                    };
					
                    var diapason = settings.max - settings.min, _from, _to;
					
					//Calc slider data
					nums.fromX = $.data($fromSlider[0], "x") || parseInt($fromSlider[0].style.left, 10) || $fromSlider.position().left;
					nums.fromPers = nums.fromX / fullWidth * 100;
					_from = (diapason / 100 * nums.fromPers) + settings.min;
					nums.fromNumber = Math.round(_from / settings.step) * settings.step;
					if (nums.fromNumber < settings.min) {
						nums.fromNumber = settings.min;
					}

					nums.toX = $.data($toSlider[0], "x") || parseInt($toSlider[0].style.left, 10) || $toSlider.position().left;
					nums.toPers = nums.toX / fullWidth * 100;
					_to = (diapason / 100 * nums.toPers) + settings.min;
					nums.toNumber = Math.round(_to / settings.step) * settings.step;
					if (nums.toNumber > settings.max) {
						nums.toNumber = settings.max;
					}
					
					//calc diapason2
					nums.fromDiapasonGreenNumber = settings.dataFrom > nums.fromNumber ? settings.dataFrom : nums.fromNumber;
					nums.toDiapasonGreenNumber = settings.dataTo < nums.toNumber ? settings.dataTo : nums.toNumber;
					nums.fromDiapasonGreenPers = (nums.fromDiapasonGreenNumber - settings.min) / diapason * 100;
					nums.fromDiapasonGreenX_pure = normalWidth / 100 * nums.fromDiapasonGreenPers;
					nums.fromDiapasonGreenX = Math.round(nums.fromDiapasonGreenX_pure);
					
					nums.toDiapasonGreenPers = (nums.toDiapasonGreenNumber - settings.min) / diapason * 100;
					nums.toDiapasonGreenX_pure = normalWidth / 100 * nums.toDiapasonGreenPers;
					nums.toDiapasonGreenX = Math.round(nums.toDiapasonGreenX_pure);
					
					if (nums.toDiapasonGreenX - nums.fromDiapasonGreenX < 0.1 && nums.fromDiapasonGreenX>0)
					{
						nums.fromDiapasonGreenX--;
						nums.toDiapasonGreenX++;
					}
					
					$diapasonGreen[0].style.left = nums.fromDiapasonGreenX + "px";
					$diapasonGreen[0].style.width = (nums.toDiapasonGreenX-nums.fromDiapasonGreenX) + "px";
					
					//calc diapason silver left
					nums.fromDiapasonSLNumber = settings.min;
					nums.toDiapasonSLNumber = settings.dataFrom < nums.fromNumber ? settings.dataFrom : nums.fromNumber;
					nums.fromDiapasonSLPers = (nums.fromDiapasonSLNumber - settings.min) / diapason * 100;
					nums.fromDiapasonSLX_pure = normalWidth / 100 * nums.fromDiapasonSLPers;
					nums.fromDiapasonSLX = Math.round(nums.fromDiapasonSLX_pure);
					
					nums.toDiapasonSLPers = (nums.toDiapasonSLNumber - settings.min) / diapason * 100;
					nums.toDiapasonSLX_pure = normalWidth / 100 * nums.toDiapasonSLPers;
					nums.toDiapasonSLX = Math.round(nums.toDiapasonSLX_pure);
					
					$diapasonSilverLeft[0].style.left = nums.fromDiapasonSLX + "px";
					$diapasonSilverLeft[0].style.width = (nums.toDiapasonSLX-nums.fromDiapasonSLX) + "px";
					
					//calc diapason silver right
					nums.fromDiapasonSRNumber = settings.dataTo > nums.toNumber ? settings.dataTo : nums.toNumber;
					nums.toDiapasonSRNumber = settings.max;
					nums.fromDiapasonSRPers = (nums.fromDiapasonSRNumber - settings.min) / diapason * 100;
					nums.fromDiapasonSRX_pure = normalWidth / 100 * nums.fromDiapasonSRPers;
					nums.fromDiapasonSRX = Math.round(nums.fromDiapasonSRX_pure);
					
					nums.toDiapasonSRPers = (nums.toDiapasonSRNumber - settings.min) / diapason * 100;
					nums.toDiapasonSRX_pure = normalWidth / 100 * nums.toDiapasonSRPers;
					nums.toDiapasonSRX = Math.round(nums.toDiapasonSRX_pure);
					
					$diapasonSilverRight[0].style.left = nums.fromDiapasonSRX + "px";
					$diapasonSilverRight[0].style.width = (nums.toDiapasonSRX-nums.fromDiapasonSRX) + "px";

					if (stepFloat) {
						nums.fromNumber = parseInt(nums.fromNumber * stepFloat, 10) / stepFloat;
						nums.toNumber = parseInt(nums.toNumber * stepFloat, 10) / stepFloat;
					}

                    numbers = nums;
                    setFields();
                };
				var setNumbers = function (silent) {
                    var nums = {
                        input: slider,
                        slider: $container,
                        fromNumber: settings.from,
                        toNumber: settings.to,
						fromPers: 0,
                        toPers: 0,
                        fromX: 0,
                        fromX_pure: 0,
                        toX: 0,
                        toX_pure: 0,
						//added
						fromDiapasonNumber: settings.dataFrom,
						toDiapasonNumber: settings.dataTo,
						fromDiapasonPers: 0,
                        toDiapasonPers: 0,
                        fromDiapasonX: 0,
                        fromDiapasonX_pure: 0,
                        toDiapasonX: 0,
                        toDiapasonX_pure: 0,
						//added2
						fromDiapasonGreenNumber: 0,
						toDiapasonGreenNumber: 0,
						fromDiapasonGreenPers: 0,
                        toDiapasonGreenPers: 0,
                        fromDiapasonGreenX: 0,
                        fromDiapasonGreenX_pure: 0,
                        toDiapasonGreenX: 0,
                        toDiapasonGreenX_pure: 0,
						//added3
						fromDiapasonSLNumber: 0,
						toDiapasonSLNumber: 0,
						fromDiapasonSLPers: 0,
                        toDiapasonSLPers: 0,
                        fromDiapasonSLX: 0,
                        fromDiapasonSLX_pure: 0,
                        toDiapasonSLX: 0,
                        toDiapasonSLX_pure: 0,
						fromDiapasonSRNumber: 0,
						toDiapasonSRNumber: 0,
						fromDiapasonSRPers: 0,
                        toDiapasonSRPers: 0,
                        fromDiapasonSRX: 0,
                        fromDiapasonSRX_pure: 0,
                        toDiapasonSRX: 0,
                        toDiapasonSRX_pure: 0
                    };
                    var diapason = settings.max - settings.min;
					
					//calc slider
					if (diapason > 0)
						nums.fromPers = (nums.fromNumber - settings.min) / diapason * 100;
					else
						nums.fromPers = fullWidth/4;
					nums.fromX_pure = fullWidth / 100 * nums.fromPers;
					nums.fromX = Math.round(nums.fromX_pure);
					$fromSlider[0].style.left = nums.fromX + "px";
					$.data($fromSlider[0], "x", nums.fromX_pure);

					if (diapason > 0)
						nums.toPers = (nums.toNumber - settings.min) / diapason * 100;
					else
						nums.toPers = fullWidth/4;
					nums.toX_pure = fullWidth / 100 * nums.toPers;
					nums.toX = Math.round(nums.toX_pure);
					$toSlider[0].style.left = nums.toX + "px";
					$.data($toSlider[0], "x", nums.toX_pure);
					
					//calc diapason
					nums.fromDiapasonPers = (nums.fromDiapasonNumber - settings.min) / diapason * 100;
					nums.fromDiapasonX_pure = normalWidth / 100 * nums.fromDiapasonPers;
					nums.fromDiapasonX = Math.round(nums.fromDiapasonX_pure);
					
					nums.toDiapasonPers = (nums.toDiapasonNumber - settings.min) / diapason * 100;
					nums.toDiapasonX_pure = normalWidth / 100 * nums.toDiapasonPers;
					nums.toDiapasonX = Math.round(nums.toDiapasonX_pure);
					
					if (nums.toDiapasonX - nums.fromDiapasonX < 0.1 && nums.fromDiapasonX>0)
					{
						nums.fromDiapasonX--;
						nums.toDiapasonX++;
					}
										
					//calc diapason2
					nums.fromDiapasonGreenNumber = settings.dataFrom > settings.from ? settings.dataFrom : settings.from;
					nums.toDiapasonGreenNumber = settings.dataTo < settings.to ? settings.dataTo : settings.to;
					nums.fromDiapasonGreenPers = (nums.fromDiapasonGreenNumber - settings.min) / diapason * 100;
					nums.fromDiapasonGreenX_pure = normalWidth / 100 * nums.fromDiapasonGreenPers;
					nums.fromDiapasonGreenX = Math.round(nums.fromDiapasonGreenX_pure);
					
					nums.toDiapasonGreenPers = (nums.toDiapasonGreenNumber - settings.min) / diapason * 100;
					nums.toDiapasonGreenX_pure = normalWidth / 100 * nums.toDiapasonGreenPers;
					nums.toDiapasonGreenX = Math.round(nums.toDiapasonGreenX_pure);
					
					if (nums.toDiapasonGreenX - nums.fromDiapasonGreenX < 0.1 && nums.fromDiapasonGreenX>0)
					{
						nums.fromDiapasonGreenX--;
						nums.toDiapasonGreenX++;
					}

					//calc diapason silver left
					nums.fromDiapasonSLNumber = settings.min;
					nums.toDiapasonSLNumber = settings.dataFrom < settings.from ? settings.dataFrom : settings.from;
					nums.fromDiapasonSLPers = (nums.fromDiapasonSLNumber - settings.min) / diapason * 100;
					nums.fromDiapasonSLX_pure = normalWidth / 100 * nums.fromDiapasonSLPers;
					nums.fromDiapasonSLX = Math.round(nums.fromDiapasonSLX_pure);
					
					nums.toDiapasonSLPers = (nums.toDiapasonSLNumber - settings.min) / diapason * 100;
					nums.toDiapasonSLX_pure = normalWidth / 100 * nums.toDiapasonSLPers;
					nums.toDiapasonSLX = Math.round(nums.toDiapasonSLX_pure);
					
					//calc diapason silver right
					nums.fromDiapasonSRNumber = settings.dataTo > settings.to ? settings.dataTo : settings.to;
					nums.toDiapasonSRNumber = settings.max;
					nums.fromDiapasonSRPers = (nums.fromDiapasonSRNumber - settings.min) / diapason * 100;
					nums.fromDiapasonSRX_pure = normalWidth / 100 * nums.fromDiapasonSRPers;
					nums.fromDiapasonSRX = Math.round(nums.fromDiapasonSRX_pure);
					
					nums.toDiapasonSRPers = (nums.toDiapasonSRNumber - settings.min) / diapason * 100;
					nums.toDiapasonSRX_pure = normalWidth / 100 * nums.toDiapasonSRPers;
					nums.toDiapasonSRX = Math.round(nums.toDiapasonSRX_pure);
					
					setDiapason();

                    numbers = nums;
					setFields(silent);
					
					$diapason[0].style.left = nums.fromDiapasonX + "px";
                    $diapason[0].style.width = (nums.toDiapasonX-nums.fromDiapasonX) + "px";
					
					$diapasonGreen[0].style.left = nums.fromDiapasonGreenX + "px";
					$diapasonGreen[0].style.width = (nums.toDiapasonGreenX-nums.fromDiapasonGreenX) + "px";
					
					$diapasonSilverLeft[0].style.left = nums.fromDiapasonSLX + "px";
					$diapasonSilverLeft[0].style.width = (nums.toDiapasonSLX-nums.fromDiapasonSLX) + "px";
					
					$diapasonSilverRight[0].style.left = nums.fromDiapasonSRX + "px";
					$diapasonSilverRight[0].style.width = (nums.toDiapasonSRX-nums.fromDiapasonSRX) + "px";
                };
				var moveByClick = function (page_x) {
                    var x = page_x - $container.offset().left,
                        d = numbers.toX - numbers.fromX,
                        zero_point = numbers.fromX + (d / 2);

                    left = 0;
                    width = $rangeSlider.width() - sliderWidth;
                    right = $rangeSlider.width() - sliderWidth;

					if (x <= zero_point) {
						$activeSlider = $fromSlider;
					} else {
						$activeSlider = $toSlider;
					}
					$activeSlider.attr("id", "irs-active-slider");
					dragSlider(x);
					setDiapason();

                    $activeSlider.removeAttr("id");
                    $activeSlider = null;
                };
				var setFields = function (silent) {
                    var _from, _fromW, _fromX,
                        _to, _toW, _toX,
                        _single, _singleW, _singleX,
                        _slW = (sliderWidth / 2);

					if (!settings.hideText) {
						_from = settings.prefix + prettify(numbers.fromNumber) + settings.postfix;
						_to = settings.prefix + prettify(numbers.toNumber) + settings.postfix;

						if (numbers.fromNumber !== numbers.toNumber) {
							_single = settings.prefix + prettify(numbers.fromNumber) + " — " + settings.prefix + prettify(numbers.toNumber) + settings.postfix;
						}
						else {
							_single = settings.prefix + prettify(numbers.fromNumber) + settings.postfix;
						}

						$fieldFrom.html(_from);
						$fieldTo.html(_to);

						_fromW = $fieldFrom.outerWidth();
						_fromX = numbers.fromX - (_fromW / 2) + _slW;
						if (_fromX < 0) {
							_fromX = 0;
						}
						if (_fromX > normalWidth - _fromW) {
							_fromX = normalWidth - _fromW;
						}
						$fieldFrom[0].style.left = _fromX + "px";

						_toW = $fieldTo.outerWidth();
						_toX = numbers.toX - (_toW / 2) + _slW;
						if (_toX < 0) {
							_toX = 0;
						}
						if (_toX > normalWidth - _toW) {
							_toX = normalWidth - _toW;
						}
						$fieldTo[0].style.left = _toX + "px";

						_singleX = numbers.fromX + ((numbers.toX - numbers.fromX) / 2) + _slW;
						if (_singleX < 0) {
							_singleX = 0;
						}
						if (_singleX > normalWidth) {
							_singleX = normalWidth;
						}

						if (_fromX + _fromW < _toX) {
							$fieldFrom[0].style.display = "block";
							$fieldTo[0].style.display = "block";
						} else {
							$fieldFrom[0].style.display = "none";
							$fieldTo[0].style.display = "none";
						}

						if (!settings.hideMinMax && !settings.hideFromTo) {
							if (_singleX < fieldMinWidth || _fromX < fieldMinWidth) {
								$fieldMin[0].style.display = "none";
							} else {
								$fieldMin[0].style.display = "block";
							}

							if (_singleX > normalWidth - fieldMaxWidth || _toX + _toW > normalWidth - fieldMaxWidth) {
								$fieldMax[0].style.display = "none";
							} else {
								$fieldMax[0].style.display = "block";
							}
						}
					}

					slider.attr("value", parseFloat(numbers.fromNumber) + ";" + parseFloat(numbers.toNumber));

                    // trigger onChange function
                    if (typeof settings.onChange === "function" && silent == undefined) {
                        settings.onChange.call(this, numbers);
                    }

                    // trigger onFinish function
                    if (typeof settings.onFinish === "function" && !sliderIsActive && !firstStart) {
                        settings.onFinish.call(this, numbers);
                    }

                    // trigger onLoad function
                    if (typeof settings.onLoad === "function" && !sliderIsActive && firstStart) {
                        settings.onLoad.call(this, numbers);
                    }
                };
				var setGrid = function () {
                    $container.addClass("irs-with-grid");

                    var i,
                        text = '',
                        step = 0,
                        tStep = 0,
                        gridHTML = '',
                        smNum = 20,
                        bigNum = 4;

                    for (i = 0; i <= smNum; i += 1) {
                        step = Math.floor(normalWidth / smNum * i);

                        if (step >= normalWidth) {
                            step = normalWidth - 1;
                        }
                        gridHTML += '<span class="irs-grid-pol small" style="left: ' + step + 'px;"></span>';
                    }
                    //for (i = 0; i <= bigNum; i += 1) {
					for (i = 1; i < bigNum; i += 1) {
                        step = Math.floor(normalWidth / bigNum * i);

                        if (step >= normalWidth) {
                            step = normalWidth - 1;
                        }
                        gridHTML += '<span class="irs-grid-pol" style="left: ' + step + 'px;"></span>';

                        if (stepFloat) {
                            text = (settings.min + ((settings.max - settings.min) / bigNum * i));
                            text = (text / settings.step) * settings.step;
                            text = parseInt(text * stepFloat, 10) / stepFloat;
                        } else {
                            text = Math.round(settings.min + ((settings.max - settings.min) / bigNum * i));
                            text = Math.round(text / settings.step) * settings.step;
                            text = prettify(text);
                        }

                        if (i === 0) {
                            tStep = step;
                            gridHTML += '<span class="irs-grid-text" style="left: ' + tStep + 'px; text-align: left;">' + text + '</span>';
                        } else if (i === bigNum) {
                            tStep = step - 100;
                            gridHTML += '<span class="irs-grid-text" style="left: ' + tStep + 'px; text-align: right;">' + text + '</span>';
                        } else {
                            tStep = step - 50;
                            gridHTML += '<span class="irs-grid-text" style="left: ' + tStep + 'px;">' + text + '</span>';
                        }
                    }

                    $grid.html(gridHTML);
                };
				var setMask = function () {
                    $container.addClass("irs-disabled");
                    $container.append(disableHTML);
                };

                placeHTML();
				
				// #############################################################
				// ## public methods
				// #############################################################
                this.updateData = function (options, silent) {
                    firstStart = true;
                    settings = $.extend(settings, options);
                    removeHTML(silent);
                };
                this.removeSlider = function () {
                    $container.find("*").off();
                    $window.off("mouseup.irs" + self.pluginCount);
                    $body.off("mouseup.irs" + self.pluginCount);
                    $body.off("mousemove.irs" + self.pluginCount);
                    $container.html("").remove();
                    slider.data("isActive", false);
                    slider.show();
                };
			});
		},
		update: function (options) {
            return this.each(function () {
                this.updateData(options);
            });
        },
        remove: function () {
            return this.each(function () {
                this.removeSlider();
            });
        },
		updateSilent: function(options) {
			return this.each(function () {
				this.updateData(options, true);
			});
		}
	};
	
	$.fn.rangeSlider = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist for jQuery.RangeSlider');
        }
    };
	
}(jQuery, document, window, navigator));