$(function(){
	$("#buy1click input[name=phone_number], #contact-center input[name=phone_number]").mask("+7 (999) 999-99-99");

    autosize($('textarea[name=comment]'));
});

$('body').on('click', '#make-order-one-click-button', function(){
	if ($('#buy1click input[name=phone_number]').mask().length == 0)
	{
		$('#buy1click input[name=phone_number]').focus();
		$('#buy1click-phone-required').show();
		return false;
	}
	$('#make-order-one-click-button').addClass('disabled');
	$('#save_order_1click_spinner').removeClass('hidden');
	$('#order-one-click-form').submit();
	return false;
});

$('#order-one-click-form').submit(function(){
	if ($('#buy1click input[name=phone_number]').mask().length == 0)
	{
		$('#buy1click input[name=phone_number]').focus();
		$('#buy1click-phone-required').show();
		return false;
	}
});

$('#buy1click input[name=phone_number]').keypress(function(){
	$('#buy1click-phone-required').hide();
});

$('#send-callback-free-taste').click(function(){
	if ($('#free-taste input[name=phone]').mask().length == 0)
	{
		$('#free-taste input[name=phone]').focus();
		return false;
	}
	$('#free-taste-form').submit();
	return false;
});

$('#free-taste-form').submit(function(){
	if ($('#free-taste input[name=phone]').mask().length == 0)
	{
		$('#free-taste input[name=phone]').focus();
		return false;
	}
	
	var phone_field = encodeURIComponent($('#free-taste input[name=phone]').val());
	var user_name = encodeURIComponent($('#free-taste input[name=name]').val());
	var call_time_field = encodeURIComponent($('#free-taste input[name=time]').val());
	var message_field = encodeURIComponent($('#free-taste input[name=address]').val());
	var delivery_date_field = encodeURIComponent($('#free-taste input[name=date]').val());
	var category_field = encodeURIComponent($('h1:first').html());
	
	var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + "&delivery_date=" + delivery_date_field + "&category=" + category_field;
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#callback-part-free-taste').hide();
			$('#send-callback-free-taste').hide();
			//$('#callback-close').show();
			if (data)
				$('#callback-success-part-free-taste').show();
			else
				$('#callback-error-part-free-taste').show();
		}
	});
	return false;
});

$('#send-callback').click(function(){
	if ($('#contact-center input[name=phone_number]').mask().length == 0)
	{
		$('#contact-center input[name=phone_number]').focus();
		$('#contact-center-phone-required').show();
		return false;
	}
	$('#callback-form').submit();
	return false;
});

$('#callback-form').submit(function(){
	if ($('#contact-center input[name=phone_number]').mask().length == 0)
	{
		$('#contact-center input[name=phone_number]').focus();
		$('#contact-center-phone-required').show();
		return false;
	}
	
	var phone_field = encodeURIComponent($('#contact-center input[name=phone_number]').val());
	var user_name = encodeURIComponent($('#contact-center input[name=user_name]').val());
	var call_time_field = encodeURIComponent($('#contact-center input[name=call_time]').val());
	var message_field = encodeURIComponent($('#contact-center textarea[name=message]').val());
	
	var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name;
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#callback-part').hide();
			$('#send-callback').hide();
			$('#callback-close').show();
			if (data)
				$('#callback-success-part').show();
			else
				$('#callback-error-part').show();
		}
	});
	return false;
});

$('#complain-form .modal-body input[type=radio]').change(function(){
	var v = $(this).val();
	if (v == 'other')
		$('#complain-form .modal-body textarea').prop('disabled', false);
	else
		$('#complain-form .modal-body textarea').prop('disabled', true);
});

$('#make-claim').click(function(){
	if ($('#complain-form .modal-body input[type=radio]:checked').val() != 'other')
		$('#complain-form .modal-body textarea').val('');
	$.ajax({
		url: mc_product_module_url,
		type: 'POST',
		data: {
			review_id: $('#complain-form input[name=claim_review_id]').val(),
			claim_type: $('#complain-form .modal-body input[type=radio]:checked').val(),
			claim_text: $('#complain-form .modal-body textarea').val()
		},
		dataType: 'json',
		success: function(data){
			$('#claim-part').hide();
			$('#make-claim').hide();
			$('#claim-close').show();
			if (data.success)
				$('#claim-success-part').show();
			else
				$('#claim-error-part').show();
		}
	});
	return false;
});

$('#product-modal-amount').change(function(){
	var val = parseFloat(this.value);
	var tiers_count = 0;
	$('#product-modal-tiers option').each(function(index, opt){
		if (parseInt(opt.getAttribute('data-weight')) <= parseInt(val)) {
			if (opt.hasAttribute('disabled')) { opt.removeAttribute('disabled'); }
			this.text = opt.getAttribute('data-normal-text');
			tiers_count++;
		} else {
			if (!opt.hasAttribute('disabled')) { opt.setAttribute('disabled', 'disabled'); }
			this.text = opt.getAttribute('data-warning-text');
		}
	});
	for(var i=1; i<=tiers_count; i++) {
		$('#product-modal-filling'+i+'-group').show();
	}
	for(var i=tiers_count+1;i<=5;i++)
	{
		$('#product-modal-filling'+i+'-group').hide();
	}
	$('#product-modal-tiers').val(tiers_count);
	$('#product-modal-tiers').trigger('change');
	$('#product-modal-tiers').select2('destroy');
	$('#product-modal-tiers').removeClass("selectbox-inited");
	selectbox_init($('#product-modal-tiers'));
});

$('#product-modal-tiers').change(function(){
	var tiers_count = parseInt(this.value);
	for(var i=1; i<=tiers_count; i++) {
		$('#product-modal-filling'+i+'-group').show();
	}
	for(var i=tiers_count+1;i<=5;i++)
		$('#product-modal-filling'+i+'-group').hide();
});

$('.js-filling-select').click(function(){
	var id = $(this).attr('data-id');
	var tier_num = $('#filling-selector input[name=tier_num]').val();
	$('#filling-selector input[name=filling_id]').val(id);
	$('#product-modal-filling' + tier_num).attr('data-filling-id', id);
	$('#product-modal-filling' + tier_num + '-group .filling-image-preview').html('<img src="'+$(this).attr('data-small-image')+'"/>');
	$('#product-modal-filling' + tier_num + '-group .filling-name-selected').html($(this).attr('data-name'));
	$('#product-modal-filling' + tier_num + '-group').addClass("active");
	$('#filling-selector').modal('hide');
	$('#product-modal-filling'+tier_num).tooltipster('content', $('#product-modal-filling'+tier_num).data('title-alt'));
});

$('#filling-selector-plitka-buy .js-main-filling-select').click(function(){
	var id = $(this).attr('data-id');
	var tier_num = $('#filling-selector-plitka-buy input[name=tier_num]').val();
	$('#filling-selector-plitka-buy input[name=filling_id]').val(id);
	$('#product-modal-filling' + tier_num).attr('data-filling-id', id);
	$('#product-modal-filling' + tier_num + '-group .filling-image-preview').html('<img src="'+$(this).attr('data-small-image')+'"/>');
	$('#product-modal-filling' + tier_num + '-group .filling-name-selected').html($(this).attr('data-name'));
	$('#product-modal-filling' + tier_num + '-group').addClass("active");
	$('#filling-selector-plitka-buy').modal('hide');
	$('#product-modal-filling'+tier_num).tooltipster('content', $('#product-modal-filling'+tier_num).data('title-alt'));
});

$('div.calc .js-filling-add-modal').click(function(){
    var $group = $(this).closest('.filling-group');
    $('#filling-selector input[name=tier_num]').val($group.find(".btn-select-filling").attr('data-tier-num'));
    $('#filling-selector input[name=filling_id]').val($group.find(".btn-select-filling").attr('data-filling-id'));
    $('#filling-selector').modal();
});

$('#tort-plitka-buy .js-filling-add-modal').click(function(){
    var $group = $(this).closest('.filling-group');
    $('#filling-selector-plitka-buy input[name=tier_num]').val($group.find(".btn-select-filling").attr('data-tier-num'));
    $('#filling-selector-plitka-buy input[name=filling_id]').val($group.find(".btn-select-filling").attr('data-filling-id'));
    $('#filling-selector-plitka-buy').modal();
});

$('#product-modal-filling1-remove, #product-modal-filling2-remove, #product-modal-filling3-remove, #product-modal-filling4-remove, #product-modal-filling5-remove').click(function(){
	var $group = $(this).closest('.filling-group');
	$group.find('div.filling-image-preview').html('');
	$group.find('div.filling-name-selected').html('');
	$group.find('button.btn-select-filling').attr('data-filling-id', '0');
	$group.removeClass("active");
});

$('#product-modal-buy-button').click(function(){
	$('#product-modal-fillings-error').hide();
	var is_error = false;
	$(this).closest('div.modal').find('.btn-select-filling:visible').each(function(i, elem) {
		if ($(this).attr('data-filling-id') == '0') {
			$('#product-modal-fillings-error').show();
			is_error = true;
			return false;
		}
	});
	if (is_error) {
		return false;
	}
	
	var variant_id = $('#tort-plitka-buy input[name=modal_variant_id]').val();
	var modificators = [];
	var modificators_count = [];
	var fillings = [];
	var amount = 1;
	
	if ($('#product-modal-amount').length > 0) {
		var amount_input = $('#product-modal-amount');
		if (amount_input.length > 0)
			amount = amount_input.val();
	}
	
	var tiers_count = $('#product-modal-tiers option:selected').val();
	for(var i=1 ; i <= tiers_count; i++) {
		fillings.push($('#product-modal-filling'+i).attr('data-filling-id'));
	}

	var text_on_cake = $('#tort-plitka-buy input[name=modal_text_on_cake]').val();
	
	var href = mc_cart_module_url + "?action=add_variant&variant_id="+variant_id+"&modificators="+modificators.join(',')+"&modificators_count="+modificators_count.join(',')+"&amount=1&var_amount="+amount+"&fillings="+fillings.join(',')+'&text_on_cake='+encodeURIComponent(text_on_cake);
	
	//$.get(mc_cart_empty_cart_module_url, function(data){
	//	if (data.success){
			$.get(href, function(data){
				if (data.success)
				{
					$('#tort-plitka-buy').modal('hide');
					$('#cart .cart-counter').html(data.total_products);
					if (data.additional_result != undefined && data.additional_result.message == "no_stock")
					{
						$('#no_stock').html('Вы можете купить товар в количестве не более ' + data.additional_result.max_stock + ' штук');
						$('#no_stock').show();
					}
					else
						if (data.additional_result != undefined && data.additional_result.added_form !=undefined)
						{
							$('#after-buy-form .modal-header span.modal-title').html(data.additional_result.modal_header);
							$('#after-buy-form .modal-body').html(data.additional_result.added_form);
							$('#after-buy-form').modal();
						}
				}
			});
	//	}
	//});
	return false;
});

/* MODAL TORT-BY-PHOTO UPLOAD IMAGES*/

var imgListCustomCallback = $('#img-list-custom-callback');
var fileInputCustomCallback = $('#uploadImageField-custom-callback');

fileInputCustomCallback.damnUploader({
	url: mc_callback_custom_upload_images_module_url,
	fieldName:  'uploaded-images-custom',
	object_id: $('input[name=temp_id]:first').val(),
	limit: 3,
	onSelect: function(file) {
		$('.upload-anim-custom').removeClass('hidden');
		var addedItem = addFileToQueueCustomCallback(file);
		fileInputCustomCallback.damnUploader('startUploadItem', addedItem.queueId);
		return false;
	},
	onAllComplete: function(){
		$('.upload-anim-custom').addClass('hidden');

		fileInputCustomCallback.damnUploader('cancelAll');
		imgListCustomCallback.html('');

		$.get(mc_callback_custom_get_temp_images_module_url + '&temp_id=' + $('input[name=temp_id]:first').val(), function(data) {
			if (data.success) {
				$('div.product-custom-add-images div.object-images').html(data.data);

				if ($('.list1-custom li').length >= 3) {
					$('.btn-add-custom-image').hide();
				} else {
					$('.btn-add-custom-image').show();
				}
			}
		});
		return false;
	},
});

function updateProgressCallback(bar, value) {
	bar.css('width', value+'%');
}

function addFileToQueueCustomCallback(file) {

	// Создаем элемент li и помещаем в него название, миниатюру и progress bar
	var li = $('<li/>').appendTo(imgListCustomCallback);
	var title = $('<div/>').text(file.name+' ').appendTo(li);
	var cancelButton = $('<a/>').attr({
		href: '#cancel',
		title: 'отменить'
	}).text('X').appendTo(title);

	// Если браузер поддерживает выбор файлов (иначе передается специальный параметр fake,
	// обозначающий, что переданный параметр на самом деле лишь имитация настоящего File)
	if(!file.fake)
	{
		// Отсеиваем не картинки
		var imageType = /image.*/;
		if (!file.type.match(imageType)) {
			return true;
		}

		// Добавляем картинку и прогрессбар в текущий элемент списка
		var img = $('<img/>').appendTo(li);

		var pBar = $('<div class="progress progress-striped active"></div>').appendTo(li);
		var ppBar = $('<div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>').appendTo(pBar);

		// Создаем объект FileReader и по завершении чтения файла, отображаем миниатюру и обновляем
		// инфу обо всех файлах (только в браузерах, поддерживающих FileReader)
		if($.support.fileReading) {
			var reader = new FileReader();
			reader.onload = (function(aImg) {
				return function(e) {
					aImg.attr('src', e.target.result);
					aImg.attr('width', 150);
				};
			})(img);
			reader.readAsDataURL(file);
		}
	}

	// Создаем объект загрузки
	var uploadItemCallback = {
		file: file,
		queueId: 0,
		onProgress: function(percents) {
			updateProgressCallback(ppBar, percents);
		},
		onComplete: function(successfully, data, errorCode) {
			pBar.removeClass('active');
		},
	};

	// помещаем его в очередь
	var queueId = fileInputCustomCallback.damnUploader('addItem', uploadItemCallback);
	uploadItemCallback.queueId = queueId;

	// обработчик нажатия ссылки "отмена"
	cancelButton.click(function() {
		fileInputCustomCallback.damnUploader('cancel', queueId);
		li.remove();
		return false;
	});

	return uploadItemCallback;
}

$('div.object-images').on("click", "a.delete_image", function(){
	var item = $(this).closest('li');
	var href = $(this).attr('href');
	$.get(href, function(data) {
		if (data)
		{
			item.fadeOut('fast').remove();
			if ($('.list1-custom li').length >= 3) {
				$('.btn-add-custom-image').hide();
			} else {
				$('.btn-add-custom-image').show();
			}
		}
	});
	return false;
});

/* MODAL TORT-PLITKA-BUY UPLOAD IMAGES */
var imgListCustomPlitka = $('#img-list-custom-plitka');
var fileInputCustomPlitka = $('#uploadImageField-custom-plitka');

fileInputCustomPlitka.damnUploader({
	url: mc_product_custom_upload_images_module_url,
	fieldName:  'uploaded-images-custom',
	object_id: $('input[name=temp_id]:first').val(),
	limit: 3,
	onSelect: function(file) {
		$('.upload-anim-custom').removeClass('hidden');
		var addedItem = addFileToQueueCustomPlitka(file);
		fileInputCustomPlitka.damnUploader('startUploadItem', addedItem.queueId);
		return false;
	},
	onAllComplete: function(){
		$('.upload-anim-custom').addClass('hidden');

		fileInputCustomPlitka.damnUploader('cancelAll');
		imgListCustomPlitka.html('');

		$.get(mc_product_custom_get_temp_images_module_url + '&temp_id=' + $('input[name=temp_id]:first').val(), function(data) {
			if (data.success) {
				$('div.product-custom-add-images div.object-images').html(data.data);

				if ($('.list1-custom li').length >= 3) {
					$('.btn-add-custom-image').hide();
				} else {
					$('.btn-add-custom-image').show();
				}
			}
		});
		return false;
	},
});

function updateProgressPlitka(bar, value) {
	bar.css('width', value+'%');
}

function addFileToQueueCustomPlitka(file) {

	// Создаем элемент li и помещаем в него название, миниатюру и progress bar
	var li = $('<li/>').appendTo(imgListCustomPlitka);
	var title = $('<div/>').text(file.name+' ').appendTo(li);
	var cancelButton = $('<a/>').attr({
		href: '#cancel',
		title: 'отменить'
	}).text('X').appendTo(title);

	// Если браузер поддерживает выбор файлов (иначе передается специальный параметр fake,
	// обозначающий, что переданный параметр на самом деле лишь имитация настоящего File)
	if(!file.fake)
	{
		// Отсеиваем не картинки
		var imageType = /image.*/;
		if (!file.type.match(imageType)) {
			return true;
		}

		// Добавляем картинку и прогрессбар в текущий элемент списка
		var img = $('<img/>').appendTo(li);

		var pBar = $('<div class="progress progress-striped active"></div>').appendTo(li);
		var ppBar = $('<div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>').appendTo(pBar);

		// Создаем объект FileReader и по завершении чтения файла, отображаем миниатюру и обновляем
		// инфу обо всех файлах (только в браузерах, поддерживающих FileReader)
		if($.support.fileReading) {
			var reader = new FileReader();
			reader.onload = (function(aImg) {
				return function(e) {
					aImg.attr('src', e.target.result);
					aImg.attr('width', 150);
				};
			})(img);
			reader.readAsDataURL(file);
		}
	}

	// Создаем объект загрузки
	var uploadItemPlitka = {
		file: file,
		queueId: 0,
		onProgress: function(percents) {
			updateProgressPlitka(ppBar, percents);
		},
		onComplete: function(successfully, data, errorCode) {
			pBar.removeClass('active');
		},
	};

	// помещаем его в очередь
	var queueId = fileInputCustomPlitka.damnUploader('addItem', uploadItemPlitka);
	uploadItemPlitka.queueId = queueId;

	// обработчик нажатия ссылки "отмена"
	cancelButton.click(function() {
		fileInputCustomPlitka.damnUploader('cancel', queueId);
		li.remove();
		return false;
	});

	return uploadItemPlitka;
}

$('.modal').on('show.bs.modal', function (e) {
	var $lazy_images = $(this).find('[data-lazy-src]').not(".lazy-load-inited");
	if ($lazy_images.length) {
		$lazy_images.each(function(){
			lazy_load_change($(this));
		});
	}
});

$('div.object-images').on("click", "a.delete_image", function(){
	var item = $(this).closest('li');
	var href = $(this).attr('href');
	$.get(href, function(data) {
		if (data)
		{
			item.fadeOut('fast').remove();
			if ($('.list1-custom li').length >= 3) {
				$('.btn-add-custom-image').hide();
			} else {
				$('.btn-add-custom-image').show();
			}
		}
	});
	return false;
});

/* FAVORITE */
$('.add_to_favorite_modal').click(function(){
	var a = $(this);
	var href = a.attr('href');
	$.get(href, function(data){
		if (data.success)
		{
			$('#make-favorite').attr('href', data.favorite_href);
			$('#make-favorite .htip__sticker').html(data.favorite_items_count);
			$('#make-favorite .htip__sticker').show();
			$('a.add_to_favorite_modal').hide();
			$('a.remove_from_favorite_modal').show();
		}
	});
	return false;
});

$('.remove_from_favorite_modal').click(function(){
	var a = $(this);
	var href = a.attr('href');
	$.get(href, function(data){
		if (data.success)
		{
			$('#make-favorite').attr('href', data.favorite_href);
			$('#make-favorite .htip__sticker').html(data.favorite_items_count);
			if (data.favorite_items_count)
				$('#make-favorite .htip__sticker').show();
			else
				$('#make-favorite .htip__sticker').hide();
			$('a.add_to_favorite_modal').show();
			$('a.remove_from_favorite_modal').hide();
		}
	});
	return false;
});

$('#tort-by-photo form').submit(function(){
	if ($('#tort-by-photo input[name=phone]').mask().length == 0)
	{
		$('#tort-by-photo input[name=phone]').focus();
		return false;
	}
	
	var phone_field = encodeURIComponent($('#tort-by-photo input[name=phone]').val());
	var user_name = encodeURIComponent($('#tort-by-photo input[name=name]').val());
	var call_time_field = encodeURIComponent($('#tort-by-photo input[name=time]').val());
	var message_field = encodeURIComponent($('#tort-by-photo textarea[name=message]').val());
	var category_field = $('h1:first').length > 0 ? encodeURIComponent($('h1:first').html()) : '';
	var temp_id_field = encodeURIComponent($('#tort-by-photo input[name=temp_id]').val());
	
	var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + "&category=" + category_field + "&temp_id=" + temp_id_field;
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#tort-by-photo-callback-success-part').hide();
			$('#tort-by-photo-callback-part').hide();
			//$('#callback-close').show();
			if (data)
				$('#tort-by-photo-callback-success-part').show();
			else
				$('#tort-by-photo-callback-error-part').show();
		}
	});
	return false;
});

$('#tort-by-photo').on('show.bs.modal', function (e) {
	$('#tort-by-photo-callback-part').show();
	$('#tort-by-photo-callback-success-part').hide();
	$('#tort-by-photo-callback-error-part').hide();
});

$('#tort-by-calc form').submit(function(){
	if ($('#tort-by-calc input[name=phone]').mask().length == 0)
	{
		$('#tort-by-calc input[name=phone]').focus();
		return false;
	}
	
	$('#tort-by-calc-fillings-error').hide();
	var is_error = false;
	$(this).closest('div.modal').find('.btn-select-filling:visible').each(function(i, elem) {
		if ($(this).attr('data-filling-id') == '0') {
			$('#tort-by-calc-fillings-error').show();
			is_error = true;
			return false;
		}
	});
	if (is_error) {
		return false;
	}
	
	var phone_field = encodeURIComponent($('#tort-by-calc input[name=phone]').val());
	var user_name = encodeURIComponent($('#tort-by-calc input[name=name]').val());
	var call_time_field = encodeURIComponent($('#tort-by-calc input[name=time]').val());
	var message_field = encodeURIComponent($('#tort-by-calc textarea[name=message]').val());
	var temp_id_field = encodeURIComponent($('#tort-by-calc input[name=temp_id]').val());
	
	
	var fillings = [];
	var amount = 1;
	
	if ($('#weight').length > 0) {
		var amount_input = $('#weight');
		if (amount_input.length > 0)
			amount = amount_input.val();
	}
	
	var tiers_count = $('#main_tiers option:selected').val();
	for(var i=1 ; i <= tiers_count; i++) {
		fillings.push($('#calc-filling'+i).attr('data-filling-id'));
	}
	
	var holiday_field = encodeURIComponent($('.calc select[name=prazdnik] option:selected').val());
	var pokrytie_field = encodeURIComponent($('.calc select[name=pokrytie] option:selected').val());
	var forma_field = encodeURIComponent($('.calc select[name=form] option:selected').val());
	var oformlenie_field = encodeURIComponent($('.calc select[name=oform] option:selected').val());
	var comment_field = encodeURIComponent($('.calc textarea[name=comment]').val());
	var flowers_count_field = 0;
	var figures_count_field = 0;
	if ($('.calc select[name=flowers]').is(":visible")) {
		flowers_count_field = $('.calc select[name=flowers] option:selected').val();
	}
	if ($('.calc select[name=figures]').is(":visible")) {
		figures_count_field = $('.calc select[name=figures] option:selected').val();
	}
	
	var url = mc_calc_order_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + "&temp_id=" + temp_id_field + "&holiday=" + holiday_field + "&pokrytie=" + pokrytie_field + "&forma=" + forma_field + "&oformlenie=" + oformlenie_field + "&comment=" + comment_field + "&flowers_count=" + flowers_count_field + "&figures_count=" + figures_count_field + "&weight=" + amount + "&fillings=" + fillings.join(',');
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#tort-by-calc-callback-success-part').hide();
			$('#tort-by-calc-callback-part').hide();
			//$('#callback-close').show();
			if (data)
				$('#tort-by-calc-callback-success-part').show();
			else
				$('#tort-by-calc-callback-error-part').show();
		}
	});
	return false;
});

$('#tort-by-calc').on('show.bs.modal', function (e) {
	$('#tort-by-calc-callback-part').show();
	$('#tort-by-calc-callback-success-part').hide();
	$('#tort-by-calc-callback-error-part').hide();
});


$('#tort-order form').submit(function(){
	if ($('#tort-order input[name=phone]').mask().length == 0)
	{
		$('#tort-order input[name=phone]').focus();
		return false;
	}
	
	var phone_field = encodeURIComponent($('#tort-order input[name=phone]').val());
	var user_name = encodeURIComponent($('#tort-order input[name=name]').val());
	var call_time_field = encodeURIComponent($('#tort-order input[name=time]').val());
	var message_field = encodeURIComponent($('#tort-order textarea[name=message]').val());
	var category_field = $('h1:first').length > 0 ? encodeURIComponent($('h1:first').html()) : '';
	var temp_id_field = encodeURIComponent($('#tort-order input[name=temp_id]').val());
	
	var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + "&category=" + category_field + "&temp_id=" + temp_id_field;
	$.ajax({
		type: 'GET',
		url: url,
		success: function(data) {
			$('#tort-order-callback-success-part').hide();
			$('#tort-order-callback-part').hide();
			if (data)
				$('#tort-order-callback-success-part').show();
			else
				$('#tort-order-callback-error-part').show();
		}
	});
	return false;
});

$('#tort-order').on('show.bs.modal', function (e) {
    $('#tort-order-callback-part').show();
    $('#tort-order-callback-success-part').hide();
    $('#tort-order-callback-error-part').hide();
});


$('#contact-center form').submit(function(){
    if ($('#contact-center input[name=phone]').mask().length == 0)
    {
        $('#contact-center input[name=phone]').focus();
        return false;
    }

    var phone_field = encodeURIComponent($('#contact-center input[name=phone]').val());
    var user_name = encodeURIComponent($('#contact-center input[name=name]').val());
    var call_time_field = encodeURIComponent($('#contact-center input[name=time]').val());
    var message_field = encodeURIComponent($('#contact-center textarea[name=message]').val());
    var category_field = $('h1:first').length > 0 ? encodeURIComponent($('h1:first').html()) : '';
    var temp_id_field = encodeURIComponent($('#contact-center input[name=temp_id]').val());

    var url = mc_callback_module_url + "&phone_number=" + phone_field + "&call_time=" + call_time_field + "&message=" + message_field + "&user_name=" + user_name + "&category=" + category_field + "&temp_id=" + temp_id_field;
    $.ajax({
        type: 'GET',
        url: url,
        success: function(data) {
            $('#contact-center-success-part').hide();
            $('#contact-center-error-part').hide();
            $('#contact-center-part').hide();
            if (data)
                $('#contact-center-success-part').show();
            else
                $('#contact-center-error-part').show();
        }
    });
    return false;
});

$('#contact-center').on('show.bs.modal', function (e) {
    $('#contact-center-part').show();
    $('#contact-center-success-part').hide();
    $('#contact-center-error-part').hide();
});