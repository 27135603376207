$('#login-form').submit(function(){
	var result = true;
	$('#login-form input.required').each(function(){
		var type = $(this).attr('data-type');
		if (type.length == 0)
			type = "text";
		var show_error = false;
		switch(type){
			case "text":
				$(this).closest('div.form-group').find('input.required').each(function(){
					if ($(this).val().length == 0)
						show_error = true;
				});
				if (show_error)
				{
					$('#login-phone-required').show();
					$(this).closest('div.form-group').find('.help-block.red').show();
					$(this).focus();
					result = false;
					return false;
				}
				break;
		}
	});
	
	if (!result)
		return false;
});