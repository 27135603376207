$(document).ready(function(){
	if (!mc_payment_methods_exists) {
		$('#delivery-section .delivery-select input:checked').trigger('click');
	}
	
	$('form#cart_form input[data-value-default], form#cart_form textarea[data-value-default]').each(function(){
		if ($(this).val().length == 0)
			$(this).val($(this).attr('data-value-default'));
	});
	
	$("#phone").mask("+7 (999) 999-99-99", { autoclear: false });
	$("#phone2").mask("+7 (999) 999-99-99", { autoclear: false });
	
	check_user_info_data(true, false);
	update_price();

    autosize($('textarea[name=comment],textarea[name=address]'));
	
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect	: 'none',
		nextEffect	: 'none',
		helpers	: {
			title	: {
				type: 'outside'
			},
			thumbs	: {
				width	: 50,
				height	: 50
			},
			overlay : {
			   locked     : false
			}
		}
	});
	
	$('input[name=delivery_id]:checked').trigger('change');
});

$('input[name=delivery_id]').change(function(){
	if ($(this).val() == 4) {
		$('#delivery-block-address').hide();
	} else {
		$('#delivery-block-address').show();
	}
});

$('input[name=buyer_type]').change(function(){
	var value = $(this).val();
	if (value == 'fiz')
		$('#yur-block').hide();
	else
		$('#yur-block').show();
	return false;
});

$("#phone_code,#phone2_code,#phone,#phone2").keydown(function(event) {
	if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 ||
		(event.keyCode == 65 && event.ctrlKey === true) ||
		(event.keyCode >= 35 && event.keyCode <= 39)) {
			 return;
	}
	else {
		if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
			event.preventDefault();
		}  
	}
});
$("#phone_code").keyup(function(){
	if ($(this).val().length == 3)
		$("#phone").focus();
});
$("#phone2_code").keyup(function(){
	if ($(this).val().length == 3)
		$("#phone2").focus();
});

$('#notify-order-on').click(function(){
	$('#notify-order-on').addClass('on');
	$('#notify-order-off').removeClass('off');
	$('#notify-order').val(1);
	return false;
});

$('#notify-order-off').click(function(){
	$('#notify-order-on').removeClass('on');
	$('#notify-order-off').addClass('off');
	$('#notify-order').val(0);
	return false;
});

$('#notify-news-on').click(function(){
	$('#notify-news-on').addClass('on');
	$('#notify-news-off').removeClass('off');
	$('#notify-news').val(1);
	return false;
});

$('#notify-news-off').click(function(){
	$('#notify-news-on').removeClass('on');
	$('#notify-news-off').addClass('off');
	$('#notify-news').val(0);
	return false;
});

$('#content a.incart').click(function(){
	var href = $(this).attr('href');
	var tr = $(this).closest('tr');
	var a_href = tr.find('.carttable-product .carttable-product-name');
	$.get(href, function(data){
		if (data.success)
		{
			if ($('table.carttable tr[data-variant-id]').length == 1)
			{
				//$('table.carttable').closest('div').html('Корзина пуста');
				$('table.carttable').closest('form').html('Корзина пуста');
			}
			else
			{
				tr.html('<td class="carttable-info" colspan="9">Товар '+a_href[0].outerHTML+' был удален из корзины.</td>');
				update_price();
			}
			update_cart_info();
		}
	});
	return false;
});

$('#delivery-section .delivery-select input').click(function(){
	$('#delivery-section .radio.active').removeClass('active');
	$(this).closest('.radio').addClass('active');
	
	$('#extModuleMkadKilometersMinus').prop('disabled', true);
	$('#extModuleMkadKilometers').prop('disabled', true);
	$('#extModuleMkadKilometersPlus').prop('disabled', true);
	
	if  ($(this).closest('.radio').find('#extModuleMkadKilometers').length > 0)
	{
		$('#extModuleMkadKilometersMinus').prop('disabled', false);
		$('#extModuleMkadKilometers').prop('disabled', false);
		$('#extModuleMkadKilometersPlus').prop('disabled', false);
	}
	
	if ($(this).data('pickup')) {
		$('.userdata textarea[name=address]')./*val('').*/closest('.form-group').hide();
		$('#map').hide();
	}else {
		$('.userdata textarea[name=address]')./*val('').*/closest('.form-group').show();
		$('#map').show();
	}
	
	if ($(this).data('deliverysettings'))
		$('#delivery-settings').show();
	else
		$('#delivery-settings').hide();

	var delivery_id = $('#delivery-section .delivery-select input:checked').val();
	$.get(mc_config_root_url + mc_module_url + '?action=get_payment_methods&delivery_id='+delivery_id, function(data){
		var new_items = $('<div class="payment_select"></div>');
		for(var index in data.data)
		{
			var d = data.data[index];
			if (d.operation_type == 'plus_fix_sum')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'minus_fix_sum')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'plus_percent')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			if (d.operation_type == 'minus_percent')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			var obj = $('<div class="payment-method cradio '+(index==0?"active":"")+'" data-id="'+d.id+'"><div class="radio" data-type="'+d.operation_type+'" data-value="'+d.operation_value+'"><label><div class="cradio__icon"></div><input type="radio" class="cradio__input" name="payment_method_id" '+(index==0?"checked":"")+' value="'+d.id+'"/><!--<div class="payment-image">'+(d.image.length>0?'<img src="'+d.image+'" />':'')+'</div>--><div class="payment-header">'+d.name+'</div></label></div></div>');
			new_items.append(obj);
		}
	
		$('#payment-section .payment-method.active').removeClass('active');
	
		$('#payment-section .payment-select').quicksand( new_items.find('div.payment-method'), { adjustHeight: 'dynamic', duration: 500 }, function(){
			$('#payment-section .payment-select input:first').prop('checked', true);
			$('#payment-section .payment-method:first').addClass('active');
			/*update_price();*/
		});
		
		update_price();
	});
});

$('#payment-section').on('click', '.payment-method input', function(){
	$('#payment-section .payment-method.active').removeClass('active');
	$(this).closest('.payment-method').addClass('active');
	
	update_price();
});

$('#delivery-settings').on('change', 'select[name=delivery_time]', function(){
	update_price();
});

//	Variable-amount
$('table.carttable td.carttable-weight input').bind('change keyup input click', function(){
	
	if ($(this).hasClass('readonly'))
		return;
	
	if (this.value.match(/[^0-9]/g)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	var min_amount = Math.round($(this).attr('data-min-amount') * 10) / 10;
	var max_amount = Math.round($(this).attr('data-max-amount') * 10) / 10;
	//if (this.value <= 0)
	//	this.value = 1;
	if (this.value < min_amount)
		this.value = min_amount;
	if (this.value > max_amount)
		this.value = max_amount;
	set_var_amount($(this).attr('data-id'), this.value);
	update_price();
});

$('table.carttable td.carttable-weight button[data-type=plus]').click(function(){
	var input = $(this).closest('td').find('input');
	var max_stock = input.data('stock');
	var min_amount = Math.round(input.attr('data-min-amount') * 10) / 10;
	var max_amount = Math.round(input.attr('data-max-amount') * 10) / 10;
	if (input.length > 0)
	{
		var value = parseFloat(input.val()) + 0.5;
		//if (value > max_stock)
		if (value > max_amount)
			value = max_amount;
		input.val(value);
		set_var_amount(input.attr('data-id'), value);
		update_price();
	}
});

$('table.carttable td.carttable-weight button[data-type=minus]').click(function(){
	var input = $(this).closest('td').find('input');
	var min_amount = Math.round(input.attr('data-min-amount') * 10) / 10;
	var max_amount = Math.round(input.attr('data-max-amount') * 10) / 10;
	if (input.length > 0)
	{
		var value = parseFloat(input.val()) - 0.5;
		if (value <= min_amount)
			value = min_amount;
		input.val(value);
		set_var_amount(input.attr('data-id'), value);
		update_price();
	}
});
//	Variable-amount (END)

//	Amount
$('table.carttable td.carttable-count input').bind('change keyup input click', function(){
	
	if ($(this).hasClass('readonly'))
		return;
	
	if (this.value.match(/[^0-9]/g)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	var min_amount = Math.round($(this).attr('data-min-amount') * 10) / 10;
	var max_amount = 999;
	//if (this.value <= 0)
	//	this.value = 1;
	if (this.value < min_amount)
		this.value = min_amount;
	if (this.value > max_amount)
		this.value = max_amount;
	set_amount($(this).attr('data-id'), this.value);
	update_price();
});

$('table.carttable td.carttable-count button[data-type=plus]').click(function(){
	var input = $(this).closest('td').find('input');
	var max_stock = input.data('stock');
	var max_amount = 999;
	if (input.length > 0)
	{
		var value = parseFloat(input.val()) + 1;
		//if (value > max_stock)
		if (value > max_amount)
			value = max_amount;
		input.val(value);
		set_amount(input.attr('data-id'), value);
		update_price();
	}
});

$('table.carttable td.carttable-count button[data-type=minus]').click(function(){
	var input = $(this).closest('td').find('input');
	var min_amount = Math.round(input.attr('data-min-amount') * 10) / 10;
	if (input.length > 0)
	{
		var value = parseFloat(input.val()) - 1;
		if (value <= min_amount)
			value = min_amount;
		input.val(value);
		set_amount(input.attr('data-id'), value);
		update_price();
	}
});
//	Variable-amount (END)

function check_user_info_data(show_checks, show_errors){
	var result = true;
	if (show_checks == undefined)
		show_checks = false;
	if (show_errors == undefined)
		show_errors = false;

	$('.userdata input.required:visible, .userdata textarea.required:visible').each(function(){
		var type = $(this).attr('data-type');
		if (type.length == 0)
			type = "text";
		var show_error = false;
		var $field = $(this).closest('.form__item-field');
		switch(type){
			case "text":
                $field.find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
                    $field.find('.help-block-error').show();
                    $field.find('.fa-check').hide();
				}
				else
					if (!show_error && show_checks)
					{
                        $field.find('.help-block-error').hide();
                        $field.find('.fa-check').show();
					}
				break;
			case "phone":
                $field.find('input.required, textarea.required').each(function(){
					if ($(this).mask().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$('#cart-phone-required').show();
                    $field.find('.help-block-error').show();
                    $field.find('.fa-check').hide();
				}
				else
					if (!show_error && show_checks)
					{
                        $field.find('.help-block-error').hide();
                        $field.find('.fa-check').show();
					}
				break;
			case "number":
                $field.find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					{
						show_error = true;
						result = false;
					}
				});
				
				if (show_error && show_errors)
				{
                    $field.find('.help-block-error').show();
                    $field.find('.fa-check').hide();
				}
				else
					if (!show_error && show_checks)
					{
                        $field.find('.help-block-error').hide();
                        $field.find('.fa-check').show();
					}
				break;
			case "email":
				$field.find('input.required, textarea.required').each(function(){
					if (!isValidEmailAddress($(this).val()))
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
                    $field.find('.help-block-error').show();
                    $field.find('.fa-check').hide();
				}
				else
					if (!show_error && show_checks)
					{
                        $field.find('.help-block-error').hide();
                        $field.find('.fa-check').show();
					}
				break;
		}
	});
	
	return result;
}

$('#save_order').click(function(){
	var result = check_user_info_data(true, true);

	if (result){
		$('#save_order').addClass('disabled');
		$('#save_order_spinner').removeClass('hidden');
		
		var modificators = [];
		var modificators_count = [];
		$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
			if (parseInt($(this).val()) > 0)
			{
				modificators.push($(this).val());
				var ic = $(this).closest('div').find('input[name^=modificators_count]');
				if (ic.length > 0)
					modificators_count.push(ic.val());
				else
					modificators_count.push(1);
			}
		});
		
		$('input[name=order_modificators]').val(modificators.join(','));
		$('input[name=order_modificators_count]').val(modificators_count.join(','));
		
		$(this).closest('form').submit();
	}
	else
		return false;
});

$('.userdata input.required:visible, .userdata textarea.required:visible').focusout(function(){
	var type = $(this).attr('data-type');
	if (type.length == 0)
		type = "text";
	var show_error = false;
    var $field = $(this).closest('.form__item-field');
	switch(type){
		case "text":
			$field.find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$field.find('.help-block-error').show();
				$field.find('.fa-check').hide();
			}
			else
			{
				$field.find('.help-block-error').hide();
				$field.find('.fa-check').show();
			}
			break;
		case "phone":
			$field.find('input.required, textarea.required').each(function(){
				if ($(this).mask().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$field.find('.help-block-error').show();
				$field.find('.fa-check').hide();
			}
			else
			{
				$field.find('.help-block-error').hide();
				$field.find('.fa-check').show();
			}
			break;
		case "number":
			$field.find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					show_error = true;
			});
			
			if (show_error)
			{
				$field.find('.help-block-error').show();
				$field.find('.fa-check').hide();
			}
			else
			{
				$field.find('.help-block-error').hide();
				$field.find('.fa-check').show();
			}
			break;
		case "email":
			$field.find('input.required, textarea.required').each(function(){
				if (!isValidEmailAddress($(this).val()))
					show_error = true;
			});
			if (show_error)
			{
				$field.find('.help-block-error').show();
				$field.find('.fa-check').hide();
			}
			else
			{
				$field.find('.help-block-error').hide();
				$field.find('.fa-check').show();
			}
			break;
	}
});

$('#add-additional-phone').click(function(){
	$('#additional-phone').slideToggle(400);
	return false;
});

var amount_ajax_context;
function set_amount(variant_id, amount){
	if (amount_ajax_context != null)
		amount_ajax_context.abort();
	
	amount_ajax_context = $.get(mc_config_root_url + mc_module_url + '?action=set_amount&variant_id=' + variant_id + '&amount=' + amount, function(data){
		if (data.success)
		{
            $('#cart .cart-counter').html(data.total_products);
		}
		amount_ajax_context = null;
	});
}

function set_var_amount(variant_id, var_amount){
	if (amount_ajax_context != null)
		amount_ajax_context.abort();
	
	amount_ajax_context = $.get(mc_config_root_url + mc_module_url + '?action=set_var_amount&variant_id=' + variant_id + '&var_amount=' + var_amount, function(data){
		if (data.success)
		{
            $('#cart .cart-counter').html(data.total_products);
		}
		amount_ajax_context = null;
	});
}

function update_price(){
	
	$('table.carttable tbody tr').each(function(){
		var td_count = $(this).find('td.carttable-weight input');
		var td_amount = $(this).find('td.carttable-count input');
		var td_price = $(this).attr('data-price');
		var td_field4 = $(this).attr('data-field4');
		
		if ((td_count.length > 0 || td_amount.length > 0) && td_price.length > 0)
		{
			var input_count = parseFloat(td_count.val().length > 0 ? td_count.val() : 1);
			var input_amount = parseFloat(td_amount.val().length > 0 ? td_amount.val() : 1);
			var input_price = parseFloat(td_price.replace(/ /g,''));
			var priceall = input_count * input_amount * input_price + parseFloat(td_field4);
			priceall = Math.round(priceall);
			var priceall_str = priceall.toString();
			priceall_str = priceall_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
			var td_priceall = $(this).find('td.carttable-priceone');
			td_priceall.html('<div class="carttable-label">Цена</div>' + priceall_str + ' ' + mc_main_currency_sign);
		}
	});
	
	return;
	var input = $('.delivery-select input:checked');
	var total_cart_price = 0;
	var discount = parseFloat($('#user_discount').attr('data-value'));
	
	$('table.carttable tbody tr').each(function(){
		var td_count = $(this).find('td.carttable-weight input');
		var td_amount = $(this).find('td.carttable-count input');
		var td_price = $(this).find('td.carttable-priceone').attr('data-price-for-one-pcs');
		var td_price_additional = $(this).find('td.carttable-priceone').attr('data-price-additional');
		
		if ((td_count.length > 0 || td_amount.length > 0) && td_price.length > 0)
		{
			var input_count = parseFloat(td_count.val().length > 0 ? td_count.val() : 1);
			var input_amount = parseFloat(td_amount.val().length > 0 ? td_amount.val() : 1);
			var input_price = parseFloat(td_price.replace(/ /g,''));
			var input_price_additional = parseFloat(td_price_additional.replace(/ /g,''));
			var priceall = input_count * input_amount * input_price + input_price_additional;
			priceall = Math.round(priceall);
			var priceall_str = priceall.toString();
			priceall_str = priceall_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
			var td_priceall = $(this).find('td.carttable-priceall');
			td_priceall.html(priceall_str + ' ' + mc_main_currency_sign);
			total_cart_price += priceall;
		}
	});
	
	var total_cart_price_w_discount = total_cart_price - total_cart_price * discount / 100;
	
	total_cart_price = Math.round(total_cart_price);
	var cart_total_price_str = total_cart_price.toString();
	cart_total_price_str = cart_total_price_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price').html(cart_total_price_str);
	
	total_cart_price_w_discount = Math.round(total_cart_price_w_discount);
	var cart_total_price_w_discount_str = total_cart_price_w_discount.toString();
	cart_total_price_w_discount_str = cart_total_price_w_discount_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price_w_discount').html(cart_total_price_w_discount_str);
	
	var cart_total_discount = Math.round(total_cart_price - total_cart_price_w_discount);
	var cart_total_discount_str = cart_total_discount.toString();
	cart_total_discount_str = cart_total_discount_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_discount').html(cart_total_discount_str);
	
	if (total_cart_price >= mc_cart_order_min_price){
		$('#not-enough-block').hide();
		$('#cart-block').show();
	}
	else{
		$('#not-enough-block').show();
		$('#cart-block').hide();
	}
	
	var total_price_w_modificators = total_cart_price_w_discount;
	
	$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
		var obj = $(this);
		var obj_type = obj.data('type');
		var obj_value = parseFloat(obj.data('value'));
		var obj_multi_buy = obj.data('multi-buy');
		var obj_multi_buy_value = 1;
		if (obj_multi_buy == 1)
			obj_multi_buy_value = parseInt($('input[name=modificators_count_'+obj.val()+']').val());
		
		if (obj_type == 'plus_fix_sum')
			total_price_w_modificators += obj_value * obj_multi_buy_value;
		if (obj_type == 'minus_fix_sum')
			total_price_w_modificators -= obj_value * obj_multi_buy_value;
		if (obj_type == 'plus_percent')
			total_price_w_modificators += total_cart_price_w_discount * obj_value * obj_multi_buy_value / 100;
		if (obj_type == 'minus_percent')
			total_price_w_modificators -= total_cart_price_w_discount * obj_value * obj_multi_buy_value / 100;
	});
	
	total_price_w_modificators = Math.round(total_price_w_modificators);
	var cart_total_price_w_modificators_str = total_price_w_modificators.toString();
	cart_total_price_w_modificators_str = cart_total_price_w_modificators_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price_w_modificators').html(cart_total_price_w_modificators_str);
	
	$('#delivery-section input[type=radio]').each(function(){
		var data_free = Math.round(parseFloat($(this).attr('data-free')));
		var data_price = Math.round(parseFloat($(this).attr('data-price')));
		var data_price2 = Math.round(parseFloat($(this).attr('data-price2')));
		var data_price_always = Math.round(parseFloat($(this).attr('data-price-always')));
		var data_separate = parseInt($(this).attr('data-separate'));
		if (data_separate == 1)
		{
			$(this).closest('div.radio').find('span.delivery-price').html('(Оплачивается отдельно)');
			$(this).attr('data-value', 0);
		}
		else
			if (data_price_always == 0)
			{
				if ((data_free == 0 && data_price > 0) || (total_price_w_modificators < data_free && data_free > 0))
				{
					if (total_price_w_modificators < 5000 || data_price2 == 0)
						var data_price_str = data_price.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					else
						var data_price_str = data_price2.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					$(this).closest('div.radio').find('span.delivery-price').html('(' + data_price_str + ' ' + mc_main_currency_sign + ')');
					if (total_price_w_modificators < 5000 || data_price2 == 0)
						$(this).attr('data-value', data_price);
					else
						$(this).attr('data-value', data_price2);
				}
				else
				{
					$(this).closest('div.radio').find('span.delivery-price').html('(Бесплатно)');
					$(this).attr('data-value', 0);
				}
			}
			else
			{
				data_price += data_price_always;
				data_price2 += data_price_always;
				if ((data_free == 0 && data_price > 0) || (total_price_w_modificators < data_free && data_free > 0))
				{
					if (total_price_w_modificators < 5000)
						var data_price_str = data_price.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					else
						var data_price_str = data_price2.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					
					$(this).closest('div.radio').find('span.delivery-price').html('(' + data_price_str + ' ' + mc_main_currency_sign + ')');
					if (total_price_w_modificators < 5000)
						$(this).attr('data-value', data_price);
					else
						$(this).attr('data-value', data_price2);
				}
				else
				{
					var data_price_str = data_price_always.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					$(this).closest('div.radio').find('span.delivery-price').html('(' + data_price_str + ' ' + mc_main_currency_sign + ')');
					$(this).attr('data-value', data_price_always);
				}
			}
	});
	
	var delivery_price = parseFloat(input.attr('data-value'));
	if (input.data('deliverysettings'))
	{
		var dt_opt = $('#delivery-settings select[name=delivery_time] option:selected');
		if (dt_opt.length > 0)
			delivery_price += parseFloat(dt_opt.attr('data-price'));
	}
	var total_price = total_price_w_modificators + delivery_price;
	total_price = Math.round(total_price);
	
	var total_price_with_payment = total_price;
	var payment_type = $('#payment-section .payment-method.active div.radio').attr('data-type');
	var payment_val = parseFloat($('#payment-section .payment-method.active div.radio').attr('data-value'));
	if (payment_type == "plus_fix_sum"){
		total_price_with_payment += payment_val;
	}
	if (payment_type == "minus_fix_sum"){
		total_price_with_payment -= payment_val;
	}
	if (payment_type == "plus_percent"){
		total_price_with_payment *= 100/(100-payment_val);
	}
	if (payment_type == "minus_percent"){
		total_price_with_payment *= 100/(100+payment_val);
	}
	total_price_with_payment = Math.round(total_price_with_payment);
	
	var total_price_with_payment_str = total_price_with_payment.toString();
	total_price_with_payment_str = total_price_with_payment_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#total_price_with_payment').html(total_price_with_payment_str);
}

$('.product-modifier input, .product-modifier select').change(function(){
	var checked = $(this).prop('checked');
	var d = $(this).closest('div');
	if (checked){
		d.find('input[name^=modificators_count]').prop('disabled', false);
		d.find('button[data-type=minus]').prop('disabled', false);
		d.find('button[data-type=plus]').prop('disabled', false);
	}
	else{
		d.find('input[name^=modificators_count]').prop('disabled', true);
		d.find('button[data-type=minus]').prop('disabled', true);
		d.find('button[data-type=plus]').prop('disabled', true);
	}
	
	update_price();
});

$('.product-modifier button[data-type=minus]').click(function(){
	var i = $(this).closest('.input-group').find('input');
	if (i.val() > i.data('min'))
		i.val(parseInt(i.val()) - 1);
	update_price();
});

$('.product-modifier button[data-type=plus]').click(function(){
	var i = $(this).closest('.input-group').find('input');
	if (i.val() < i.data('max'))
		i.val(parseInt(i.val()) + 1);
	update_price();
});

$('.product-modifier input[name^=modificators_count]').bind('change keyup input click', function(){
	if (this.value.match(/[^0-9]/g)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	var min = parseInt($(this).data('min'));
	var max = parseInt($(this).data('max'));
	if (this.value < min)
		this.value = min;
	if (this.value > max)
		this.value = max;
	update_price();
});

function isValidEmailAddress(emailAddress) {
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	return pattern.test(emailAddress);
}

/*
$('.filling1-group, .filling2-group, .filling3-group, .filling4-group, .filling5-group').click(function(){
	$('#filling-cart-selector input[name=variant_id]').val($(this).closest('.tr').attr('data-variant-id'));
	$('#filling-cart-selector input[name=tier_num]').val($(this).attr('data-tier-num'));
	$('#filling-cart-selector input[name=filling_id]').val($(this).attr('data-filling-id'));
	$('#filling-cart-selector').modal();
});
*/