$(document).ready(function(){
	$('#view a').click(function(){
		var href = $(this).attr('href');
		
		$('a[data-toggle="tooltip"]').each(function(){
			$(this).tooltip('hide');
		});

		$.get(href, function(data){
			$('#related').html(data);
			$('a[data-toggle="tooltip"]').tooltip({
				container: 'body'
			});
		});
		return false;
	});
});