$('.expand-more-tags').click(function(){
	$(this).prev('div.items-collapse.popular-tags').hide();
	$(this).next('div.items-collapse.all-tags').show();
	$(this).hide();
	return false;
});
$('#main-left-container').on('click', '.expand-more-tags2', function(){
	$(this).prev('div.items-collapse').find('div.hidden').toggleClass('hidden');
	$(this).hide();
	return false;
});

$(document).mouseup(function(e) 
{
    var container = $("#filter-float-panel");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        container.hide();
    }
});